import styled from 'styled-components';

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  padding-right: 20px;
  flex: 1 0 160px;
  @media (max-width: 467px) {
    justify-content: flex-start;
    flex: 1 1 160px;
    display: flex;
  }
`;

export const Value = styled.div`
  display: flex;
  flex: 1 0 220px;
  .checkboxButton {
    align-items: center!important;
  }
  @media (max-width: 467px) {
    flex: 1 1 220px;
    display: flex;
  }
`;

export const Addon = styled.div`
  border-radius: 5px 0 0 5px;
  z-index: 10;
`;

export const Header = styled.h1`
  background: ${props => props.background ? props.background : 'rgb(64, 64, 64)'};
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 1rem;
  text-align: center;
  color: white;
  font-size: 24px;
  line-height: 35px;
`;
