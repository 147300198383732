import { Cart } from '../services/Cart';

const defaultState = {
  id: '',
  type: '',
  store: {},
  items: [],
  itemsPreview: [],
  totals: {},
  coupon: {},
  payment: [],
  mode: 'browse',
  discount: {},
  employee: {},
  customer: {},
  giftCard: {},
  station: false,
  loading: false,
  certificate: {},
  shipping: {},
  paymentType: {},
  certificates: [],
  productSearch: '',
  productList: {}, // groupped by category
  categoriesList: [],
  pressedEnter: false,
  creditCardDue: 0.00,
  productCategory: '',

  productSuggestions: [],
  notes: {giftMessage: ''},

  discountPreference: '',
  multiDiscountsAllowed: '',
  volumeDiscountsAllowed: '',
  supportTransactionDetail: {},
  specialDiscounts: ['individualDiscount', 'freeItem']
};

export default (state = defaultState, action) => {
  if (action.type === 'ORDER_RESET') {
    return Object.assign({}, state, {
      ...defaultState,
      customer: {},
      type: state.type,
      store: state.store,
      station: state.station,
      categoriesList: state.categoriesList,
      discountPreference: state.discountPreference,
      discountsWithCoupons: state.discountsWithCoupons,
      multiDiscountsAllowed: state.multiDiscountsAllowed,
      volumeDiscountsAllowed: state.volumeDiscountsAllowed,
      discountsWithoutCoupons: state.discountsWithoutCoupons
    });
  }

  if (action.type === 'STATION_SET') return Object.assign({}, state, { station: action.payload, mode: 'cart' });
  if (action.type === 'STORE_SET') {
    const newState = Object.assign({}, state, { store: action.payload });
    const cartProperties = Cart(newState).get();
    return Object.assign({}, state, { ...cartProperties });
  }
  if (action.type === 'ORDER_ITEMS_SET') {
    return { ...state, items: action.payload };
  }
  if (action.type === 'ORDER_SHIPPING_SET') {
    const newState = Object.assign({}, state, { shipping: action.payload });
    const cartProperties = Cart(newState).get();
    return Object.assign({}, state, { ...cartProperties });
  }
  if (action.type === 'ORDER_TOTALS_SET') {
    return { ...state, totals: action.payload };
  }
  if (action.type === 'ORDER_SET_CUSTOMER') {
    const newState = Object.assign({}, state, { customer: action.payload });
    const cartProperties = Cart(newState).get();
    return Object.assign({}, state, { ...cartProperties });
  }
  if (action.type === 'ORDER_RELEASE') {
    return Object.assign({}, state, action.payload, { mode: 'cart' });
  }
  if (action.type === 'ORDER_SUBMIT_START') {
    return Object.assign({}, state, { loading: true });
  }
  if (action.type === 'ORDER_CLEAN') {
    console.log({ORDER_CLEAN: true });
    return Object.assign({}, { ...defaultState, categoriesList: state.categoriesList, forceCleanForm: action.payload });
  }
  if (action.type === 'ORDER_SUBMIT_DONE') {
    const response = action.payload;
    return Object.assign({}, state,
      { id: response.id, mode: 'thankyou', loading: false, certificates: action.certificates });
  }
  if (action.type === 'ORDER_ON_HOLD_START') {
    return Object.assign({}, state, { loading: true });
  }
  if (action.type === 'ORDER_ON_HOLD_DONE') {
    const response = action.payload;
    return Object.assign({}, defaultState, { id: response.id, mode: 'holding' });
  }
  if (action.type === 'ORDER_PRODUCT_SEARCH') {
    return Object.assign({}, state, { mode: 'cart', productSearch: action.payload });
  }
  if (action.type === 'ORDER_ADD_INDIVIDUAL_DISCOUNT') {
    const discount = action.payload;
    const cartProperties = Cart(state).addIndividualDiscount(discount).get();
    return Object.assign({}, state, { ...cartProperties, mode: 'cart' });
  }
  if (action.type === 'ORDER_PRODUCT_SEARCH_RESULTS') {
    let newState = {};
    if (state.pressedEnter && action.payload[0]) {
      const doc = action.payload[0].doc;
      const cartProperties = Cart(state).addItem(doc).get();
      newState = Object.assign({}, state,
        {
          ...cartProperties,
          mode: 'cart',
          productCategory: '',
          productSearch: '',
          productSuggestions: [],
          pressedEnter: false
        });
    } else {
      newState = Object.assign({}, state, { mode: 'cart', productSuggestions: action.payload });
    }
    return newState;
  }
  if (action.type === 'ORDER_PRODUCT_SEARCH_CANCEL') {
    return Object.assign({}, state, { mode: 'cart', productSearch: '', productSuggestions: [] });
  }
  if (action.type === 'ORDER_BROWSE_CANCEL') {
    return Object.assign({}, state, { mode: 'cart', productSuggestions: [], productCategory: '', productSearch: '' });
  }
  if (action.type === 'ORDER_BROWSE_CATEGORIES') {
    return Object.assign({}, state, { mode: 'browse', productCategory: '', productSearch: '' });
  }
  if (action.type === 'ORDER_BROWSE_CUSTOMERS') {
    return Object.assign({}, state, { mode: 'customers', customer: {} });
  }
  if (action.type === 'PRODUCTS_SEARCH_PRESSED_ENTER') {
    return Object.assign({}, state, { pressedEnter: action.payload });
  }
  if (action.type === 'ORDER_BROWSE_CATEGORY') {
    return Object.assign({}, state, {
      mode: 'browse', loading: true, productCategory: action.payload, productSearch: ''
    });
  }
  if (action.type === 'ORDER_CHANGE_NOTES') {
    const field = action.payload[0];
    const value = action.payload[1];
    const notes = Object.assign({}, state.notes);
    notes[field] = value;
    return Object.assign({}, state, { notes });
  }
  if (action.type === 'ORDER_CHANGE_COUPON') {
    const coupon = action.payload;
    return Object.assign({}, state, { coupon });
  }
  if (action.type === 'ORDER_GIFT_CARD_ADD') {
    const giftCard = action.payload;
    return Object.assign({}, state, { giftCard });
  }
  if (action.type === 'ORDER_GIFT_CARD_REMOVE') {
    return Object.assign({}, state, { giftCard: {} });
  }
  if (action.type === 'ORDER_COUPON_ADD') {
    const coupon = action.payload;
    const cartProperties = Cart(state).addCoupon(coupon).get();
    return Object.assign({}, state, { ...cartProperties, mode: 'cart', productCategory: '', productSearch: '' });
  }
  if (action.type === 'ORDER_COUPON_REMOVE') {
    const cartProperties = Cart(state).removeCoupon().get();
    return Object.assign({}, state, { ...cartProperties, mode: 'cart', productCategory: '', productSearch: '' });
  }
  if (action.type === 'ORDER_COPY') {
    return Object.assign({}, state, { orderCopy: action.payload });
  }
  if (action.type === 'ORDER_PRODUCT_PREVIEW_ADD') {
    const doc = action.payload;
    if (doc && Object.keys(doc).length) {
      const cartProperties = Cart(state).addPreviewItem(doc).get();
      return Object.assign({}, state,
        {
          ...cartProperties,
          mode: 'cart',
          productCategory: '',
          productSearch: '',
          productSuggestions: []
        });
    }
    return Object.assign({}, state,
      {
        mode: 'cart',
        productCategory: '',
        productSearch: '',
        productSuggestions: []
      });
  }
  if (action.type === 'ORDER_PRODUCT_ADD') {
    const doc = action.payload;
    if (doc && Object.keys(doc).length) {
      const cartProperties = Cart(state).addItem(doc).get();
      return Object.assign({}, state,
        {
          ...cartProperties,
          mode: 'cart',
          productCategory: '',
          productSearch: '',
          itemsPreview: [],
          productSuggestions: []
        });
    }
    return Object.assign({}, state,
      {
        mode: 'cart',
        productCategory: '',
        productSearch: '',
        itemsPreview: [],
        productSuggestions: []
      });
  }
  if (action.type === 'ORDER_PRODUCT_PASTE') {
    const doc = action.payload;
    if (doc && Object.keys(doc).length) {
      const cartProperties = Cart(state).addItem(doc, doc.quantity).get();
      return Object.assign({}, state,
        {
          ...cartProperties,
          mode: 'cart',
          itemsPreview: [],
          productSearch: '',
          productCategory: '',
          productSuggestions: []
        });
    }
    return Object.assign({}, state,
      {
        mode: 'cart',
        itemsPreview: [],
        productSearch: '',
        productCategory: '',
        productSuggestions: []
      });
  }
  if (action.type === 'ORDER_PRODUCT_CHANGE_QTY') {
    let obj = action.payload;
    obj = (obj.length > 0) ? obj.split(',') : obj;
    const hash = obj[0];
    const qty = obj[1];
    const cartProperties = Cart(state).updateQty(hash, qty).get();
    return Object.assign({}, state, { ...cartProperties, mode: 'cart', productCategory: '', productSearch: '' });
  }
  if (action.type === 'ORDER_PRODUCT_INCREASE') {
    const hash = action.payload;
    const cartProperties = Cart(state).updateQtyIncrease(hash).get();
    return Object.assign({}, state, { ...cartProperties, mode: 'cart', productCategory: '', productSearch: '' });
  }
  if (action.type === 'ORDER_PRODUCT_DECREASE') {
    const hash = action.payload;
    const cartProperties = Cart(state).updateQtyDecrease(hash).get();
    return Object.assign({}, state, { ...cartProperties, mode: 'cart', productCategory: '', productSearch: '' });
  }
  if (action.type === 'ORDER_PRODUCT_REMOVE') {
    const hash = action.payload;
    const cartProperties = Cart(state).updateQty(hash, 0).get();
    return Object.assign({}, state, { ...cartProperties, mode: 'cart', productCategory: '', productSearch: '' });
  }
  if (action.type === 'ORDER_PRODUCT_SET_MODIFIER') {
    const hash = action.payload[0];
    const selected = action.payload[1];
    const cartProperties = Cart(state).updateModifiers(hash, selected).get();
    return Object.assign({}, state, { ...cartProperties, mode: 'cart', productCategory: '', productSearch: '' });
  }
  if (action.type === 'ORDER_PRODUCT_SET_OPTION') {
    const hash = action.payload[0];
    const options = action.payload[1];
    const cartProperties = Cart(state).updateSelectedOptions(hash, options).get();

    return Object.assign({}, state, { ...cartProperties, mode: 'cart', productCategory: '', productSearch: '' });
  }
  if (action.type === 'ORDER_PRODUCT_SET_SERIAL') {
    const hash = action.payload[0];
    const serials = action.payload[1];
    const cartProperties = Cart(state).updateSelectedSerials(hash, serials).get();

    return Object.assign({}, state, { ...cartProperties, mode: 'cart', productCategory: '', productSearch: '' });
  }
  if (action.type === 'PAYMENT_MODE_ENTER') {
    return Object.assign({}, state, { mode: 'payment' });
  }
  if (action.type === 'PAYMENT_MODE_EXIT') {
    return Object.assign({}, state, { mode: 'cart' });
  }
  if (action.type === 'PAYMENT_REMOVE') {
    const json = JSON.stringify(action.payload);
    const payment = state.payment.filter(item => (JSON.stringify(item) !== json));
    return Object.assign({}, state, { payment });
  }
  if (action.type === 'PAYMENT_ADD') {
    const type = action.payload[0];
    const amount = action.payload[1];
    if (!amount || isNaN(amount)) return state;
    if (type === 'creditCard') {
      return Object.assign({}, state, { mode: 'creditCard', creditCardDue: amount });
    }
    const payment = state.payment.slice();
    // if cash - search for cash and replace it. other methods: add new
    if (type === 'cash') {
      const cashPayment = payment.find(p => (p.type === 'cash'));
      if (cashPayment) {
        cashPayment.amount = parseFloat(cashPayment.amount) + parseFloat(amount);
        return Object.assign({}, state, { payment });
      }
    }
    payment.push({ type, amount }); // add random number, otherwise it will not
    return Object.assign({}, state, { payment });
  }
  if (action.type === 'CREDIT_CARD_CANCEL') {
    return Object.assign({}, state, { mode: 'payment', creditCardDue: 0 });
  }
  if (action.type === 'CREDIT_CARD_SUBMIT') {
    const payment = state.payment.slice();
    payment.push(action.payload);
    return Object.assign({}, state, { mode: 'payment', payment, creditCardDue: 0 });
  }
  if (action.type === 'INIT_PRODUCTS') {
    const category = action.payload[0];
    const list = action.payload[1];
    const pl = Object.assign({}, state.productList);
    pl[category] = list;
    return Object.assign({}, state, { loading: false, productCategory: category, productList: pl });
  }
  if (action.type === 'INIT_CATEGORIES') {
    return Object.assign({}, state, { categoriesList: action.payload });
  }
  if (action.type === 'INIT_DISCOUNTS_WITHOUT_COUPONS') {
    return Object.assign({}, state, { discountsWithoutCoupons: action.payload });
  }
  if (action.type === 'INIT_DISCOUNTS_WITH_COUPONS') {
    return Object.assign({}, state, { discountsWithCoupons: action.payload });
  }
  if (action.type === 'LEAVE_ORDER') {
    return Object.assign({}, state, { mode: 'cart' });
  }

  return state;
};
