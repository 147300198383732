/* eslint-disable */
import { getWritableValue } from 'ec-react15-lib';

import * as pdfElements from '../../services/pdf';
import {isArray, isEmptyArray, isEmptyObject, isObject} from '../../services/VariableType';
import { getLine, getSpace, getTextElement } from '../../services/pdf/PdfElements';
import { checkItems, checkPaymentDetails, checkPayments, getCustomFields } from '../../services/OrderHelpers';

const PdfDetails = ({ gen, props, context }) => {
  const defaultTpl = getWritableValue('g:ecOptions.merchantOptions.receipt.preview', context) || false;
  const options = getWritableValue('g:ecOptions.merchantOptions.options', context) || false;

  const isTplLarge = defaultTpl === 'A4' || !defaultTpl;
  const { showDonation = false } = props; // default show Totals
  const { width } = context;

  const stylesContext = gen.getStyling(props, context);
  if (!stylesContext) return false;
  const styles = { ...stylesContext, maxWidth: width };
  const {
    _id = false,
    store = false,
    status = '',
    totals = false,
    validId = false,
    patients = false,
    invoice = false,
    items: products = false,
    payment: payments = false,
    coupon: discount = false,
    certificates = false,
    customFields = false,
    customer = false
  } = context.docs.doc;

  if (customFields && pdfElements['CustomFields']) {
    const customFields = getCustomFields({ ...context.docs.doc });
    if (isArray(customFields) && !isEmptyArray(customFields)) {
      if (!isTplLarge) {
        gen.addElement(getSpace());
        gen.addElement(getLine(styles, context));
        gen.addElement(getSpace());
      }
      const customFieldsTable = pdfElements['CustomFields'].getElement({customFields, styles, isTplLarge});
      gen.addElement(customFieldsTable);
    }
  }

  if (pdfElements['InvoiceDetail'] && isObject(invoice) && !isEmptyObject(invoice)) {
    if (!isTplLarge) {
      gen.addElement(getSpace());
      gen.addElement(getLine(styles, context));
      gen.addElement(getSpace());
    }
    const invoiceElements = pdfElements['InvoiceDetail'].getElement({ ...invoice, styles, isTplLarge });
    gen.addElement(invoiceElements);
  }

  if (patients && pdfElements['Patients']) {
    if (isArray(patients) && !isEmptyArray(patients)) {
      if (!isTplLarge) {
        gen.addElement(getSpace());
        gen.addElement(getLine(styles, context));
        gen.addElement(getSpace());
      }
      const patientsTable = pdfElements['Patients'].getElement({ patients, styles, isTplLarge});
      gen.addElement(patientsTable);
    }
  }

  if (pdfElements['Products']) {
    if(!isTplLarge && checkItems(products)) {
      gen.addElement(getSpace());
      gen.addElement(getLine(styles, context));
      gen.addElement(getSpace());
    }
    const hideNotesOnReceipt = options ? !!options.hideDescriptionOnReceipt : false;
    const list = hideNotesOnReceipt ? products.map(o => ({...o, description: ''})) : products;
    const productsTable = pdfElements['Products'].getElement({ products: list, certificates, styles, isTplLarge });
    gen.addElement(productsTable);
  }

  if (pdfElements['Totals'] && totals) {
    gen.addElement(getSpace('0.5'));
    const totalsElement = pdfElements['Totals'].getElement({ totals, showDonation, styles, isTplLarge });
    gen.addElement(totalsElement);
  }

  if (pdfElements['TransactionPaymentType']) {
    const { paymentTypes = [] } = store || {};
    if (checkPayments(payments) && payments) {
      gen.addElement(getSpace());
      gen.addElement(getLine(styles, context));
      gen.addElement(getSpace());
    }
    checkPayments(payments) && payments.forEach((payment) => {
      payment.storePaymentTypes = paymentTypes;
      const paymentTable = pdfElements['TransactionPaymentType'].getElement(payment, styles, isTplLarge, defaultTpl);
      gen.addElement(paymentTable);

      if (checkPaymentDetails(payment)) {
        if (pdfElements['TransactionDetail']) {
          const transactions = pdfElements['TransactionDetail'].getElement(payment, isTplLarge, styles, defaultTpl);
          gen.addElement(transactions);
        }
      }
    });
  }

  if (pdfElements['TransactionDiscount']) {
    const couponTable = pdfElements['TransactionDiscount'].getElement(discount, styles);
    gen.addElement(couponTable);
  }

  if (pdfElements['PointDetails'] && customer) {
    const { rewardsHistory = [] } = customer;
    const pointObj = rewardsHistory.find(item => item.orderId === _id);
    if (pointObj) {
      gen.addElement(getSpace());
      gen.addElement(getLine(styles, context));
      gen.addElement(getSpace());
      const pointsTable = pdfElements['PointDetails'].getElement(pointObj, isTplLarge, styles);
      gen.addElement(pointsTable);
    }
  }

  if (pdfElements['AmountDue']) {
    gen.addElement(getSpace());
    gen.addElement(getLine(styles, context));
    gen.addElement(getSpace());

    const totalsElement = pdfElements['AmountDue'].getElement({ showDonation, status, ...totals, styles, isTplLarge });
    gen.addElement(totalsElement);
    gen.addElement(getSpace(1));
  }

  if (pdfElements['QR']) {
    const size = 40;
    const unit = 'mm';
    const element = pdfElements['QR'].getElement({ data: _id, isTplLarge, size, unit });
    gen.addElement(element);
    gen.addElement(getSpace(1));
  }

  if (isArray(certificates) && !isEmptyArray(certificates)) {
    gen.addElement(
      getTextElement('Gift Cards are not redeemable for cash', { ...styles, alignment: 'center' })
    );
    gen.addElement(getSpace());
  }

  if (validId) {
    gen.addElement(getTextElement('Valid ID', { ...styles, alignment: 'center' }));
    gen.addElement(getSpace());
  }

  return false;
};

export default PdfDetails;
/* eslint-enable */
