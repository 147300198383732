import React, {Component} from 'react';
import ec from 'ec-react15-lib';

import * as api from '../../../../services/ApiData';
import {ChargeComponent, MessageBox} from './index';
import {getErrorString} from '../../../../services/HandlerError';
import * as variableType from '../../../../services/VariableType';
import shallowEqualsObj from '../../../../services/ShallowEqualsObj';
import {onTriggerAction} from '../../../../services/ContextHelpers';
import checkStation from '../../../../services/validations/checkStation';
import {capitalizeFirstLetter} from '../../../../services/GeneralHelpers';
import {getDepartmentDetails} from '../../../../services/DepartmentHelpers';
import * as creditCardHelpers from '../../../../services/CreditCardHelpers';
import {getTransactionHistoryRequest} from '../../../../services/TransactionProperties';
import {getUsaepayResponseStatus} from '../../../../services/processorService/usaepayHelpers';

const {setValue, getValue, triggerAction, getWritableValue, Debounced} = ec;
const {
  isValidStation, getMaskedCardNumber, usaepayStatus, getCardType,
  ERROR_DATACAP_AFTER_TRAN, ERROR_DATACAP_BEFORE_TRAN, ERROR_DATACAP_BUSY, ERROR_TRAN_STATUS
} = creditCardHelpers;

const {isArray, isEmptyArray, isEmptyString, isEmptyObject, isObject, isString, isValue} = variableType;
const {DataCap, Dejavoo, PaymentEngine, TransactionsHistory, WorldPay, POSLink} = api;

const defaultState = {
  amount: 0,
  status: '',
  payment: {},
  message: {},
  watching: '',
  requestId: '',
  deviceData: {},
  transaction: {},
  sendingToDevice: false
};

class ChargeContainer extends Component {

  state = {...defaultState};

  componentWillMount() {
    const {props, context} = this.props;
    if (!props || !context) return null;
    this.onInitDeviceData(context);

    const object = getValue(props, 'value', context);
    const {amount: val = 0} = object || {};
    const amount = parseFloat(val) || 0;
    this.setState({amount});
  }

  componentWillReceiveProps(nextProps) {
    const {props, context} = nextProps;
    if (!props || !context) return null;
    const {payment: {amount: nextAmount = '', cardNumber = ''} = {}} = context.globals.object || {};
    if (!shallowEqualsObj(context.docs, this.props.context.docs, shallowEqualsObj)
      || !shallowEqualsObj(context.globals.department, this.props.context.globals.department, shallowEqualsObj)
      || !shallowEqualsObj(context.globals.store, this.props.context.globals.store, shallowEqualsObj)
      || !shallowEqualsObj(context.globals.station, this.props.context.globals.station, shallowEqualsObj)) {
      this.onInitDeviceData(context);
    }
    if (!shallowEqualsObj(nextAmount, this.state.amount, shallowEqualsObj)) {
      const object = getValue(props, 'value', context);
      const {amount: val = 0} = object || {};
      const amount = parseFloat(val) || 0;
      if (!(amount > 0 && +cardNumber === +amount)) {
        this.setState({amount});
      }
    }
  }

  onInitDeviceData = (context) => {
    const department = getWritableValue('g:department', context, '') || '';
    const store = getWritableValue('g:store', context, '') || '';
    const stationG = getWritableValue('g:station', context, '') || '';
    const selected = [department, store, stationG].filter(v => v).join(' - ');
    const publicList = getWritableValue('docs:publicList', context, []) || [];
    const secureList = getWritableValue('docs:secureList', context, []) || [];
    const list = isArray(secureList) && !isEmptyArray(secureList) ? secureList : publicList;
    const object = list.find((o) => {
      const {department: d, store: s, stationName} = getDepartmentDetails(o);
      const current = [d, s, stationName].filter(v => v).join(' - ');
      return selected === current;
    });
    if (object && isObject(object) && !isEmptyObject(object)) {
      const {station, paymentEngine} = object;
      if (station && isObject(station) && !isEmptyObject(station)) {
        const {stationPaymentEngine} = station || {};
        const validationMessage = checkStation(station, paymentEngine);
        const isValid = isValidStation(paymentEngine, station);
        if (isString(validationMessage) && !isEmptyString(validationMessage)) {
          this.onMessage({type: 'danger', content: validationMessage});
        } else {
          this.onMessage({type: '', content: ''});
        }
        const deviceData = {...station, isValid};
        const processor = stationPaymentEngine || paymentEngine;
        this.setState({deviceData, processor});
      } else {
        this.onMessage({type: 'danger', content: 'No charges without station.'});
      }
    }
  };

  /* START DEVICE */

  getDeviceApi = () => {
    const {processor} = this.state;
    if (processor === 'worldpay') return WorldPay;
    if (processor === 'datacap') return DataCap;
    if (processor === 'dejavoo') return Dejavoo;
    return PaymentEngine;
  };

  canStart = () => {
    const {deviceData} = this.state;
    const {stationName, isActive, isValid} = deviceData || {};
    const hasStation = isObject(deviceData) && !isEmptyObject(deviceData);
    const hasStationName = isString(stationName) && !isEmptyString(stationName);
    const isAvailable = isActive && isValid;
    return hasStation && hasStationName && isAvailable;
  };

  onWatch = () => {
    const {context} = this.props;
    const {processor, requestId, deviceData, amount} = this.state;
    if (requestId && processor) {
      const wp = processor === 'worldpay';
      const payload = wp ? requestId : {...deviceData, requestId};
      onTriggerAction(this.getDeviceApi()
        .checkTransactionStatus(payload), context)
        .then(res => {
          const response = res[0] || res || '';
          const {status: statusCode, errors, statusMessage} = response;
          if (!(statusCode >= 200 && statusCode < 300) && isString(errors) && !isEmptyString(errors)) {
            this.stopWatch(false);
            if (isString(statusMessage) && !isEmptyString(statusMessage)) {
              this.onMessage({type: 'danger', content: statusMessage});
            } else if (statusCode >= 300 && statusCode < 400) {
              this.onMessage({type: 'danger', content: 'Redirection. Please contact admin for more details'});
            } else if (statusCode >= 400 && statusCode < 500) {
              this.onMessage({type: 'danger', content: 'Client Error. Please contact admin for more details'});
            } else if (statusCode >= 500) {
              this.onMessage({type: 'danger', content: 'Server Error. Please contact admin for more details'});
            }
          }
          if (wp) {
            const request = getTransactionHistoryRequest(payload, processor, response.transaction || response);
            this.createTransactionHistory(request, context);
            const clearMessage = {type: '', content: ''};
            this.setState({message: clearMessage});
            if (response.success && response.transaction) {
              this.stopWatch(false);
              this.onMessage({type: 'info', content: response.success});
              this.setState({transaction: response.transaction});

              this.onSaveTransaction({
                amount,
                source: 'device',
                type: 'creditCard',
                cardNumber: response.transaction.cardNumber,
                cardHolder:
                  `${response.transaction.cardholder_firstName} ${response.transaction.cardholder_lastName}`,
                transactions: [{...response.transaction, type: 'Sale'}]
              });
            } else if (response.message) {
              this.stopWatch(false);
              this.onMessage({type: 'danger', content: response.message});
            }
            if (response.error) {
              this.onMessage({type: 'danger', content: response.error});
            } else {
              this.onMessage({type: 'info', content: 'Waiting for device response...'});
            }
          } else if (processor === 'usaepay') {
            const {status, transaction /* expiration */} = response;
            const [error, success] = getUsaepayResponseStatus(response);
            if (isString(error) && !isEmptyString(error)) {
              this.stopWatch(false);
              this.onMessage({type: 'danger', content: capitalizeFirstLetter(error)});
              const request = getTransactionHistoryRequest(payload, 'usaepay', response);
              this.createTransactionHistory(request, context);
            } else if (isString(success) && !isEmptyString(success)) {
              if (isObject(transaction) && !isEmptyObject(transaction)) {
                this.stopWatch(false);
                this.onMessage({type: 'info', content: capitalizeFirstLetter(success)});
                if (usaepayStatus.success.includes(status)) {
                  const {creditcard: {number, type, cardholder} = {}, authcode, refnum} = transaction;
                  const generalTransInfo = {
                    amount,
                    refnum,
                    authcode,
                    processor,
                    cardType: type,
                    cardNumber: number,
                    cardHolder: cardholder,
                    date: new Date().toISOString()
                  };
                  this.onSaveTransaction({
                    type: 'creditCard',
                    ...generalTransInfo,
                    source: 'device',
                    transactions: [{...generalTransInfo, type: 'Sale', origin: response}]
                  });
                }
              }
            }
          } else {
            this.stopWatch(false);
            this.onMessage({type: 'danger', content: 'Lost Value of Payment Engine'});
          }
        }).catch(e => {
        this.stopWatch(false);
        const err = getErrorString(e);
        this.onMessage({type: 'danger', content: err});
      });
    }
  };

  stopWatch = (stopTransaction = true) => {
    const {context} = this.props;
    if (this.timerId) clearInterval(this.timerId);
    if (stopTransaction) {
      const clearMessage = {type: '', content: ''};
      this.setState({message: clearMessage});
      const {processor} = this.state;
      if (processor) {
        const wp = processor === 'worldpay';
        const {transaction, deviceData} = this.state;
        const requestKey = transaction && transaction.key ? transaction.key : '';
        const data = wp ? this.state.requestId : {...deviceData, requestKey};
        if (typeof this.getDeviceApi().stopTransaction === 'function') {
          onTriggerAction(this.getDeviceApi().stopTransaction(data), context);
        }
      }
    }
    this.setState({sendingToDevice: false});
  };

  getPayload = (requestId) => {
    const {deviceData, processor, amount} = this.state;
    const params = {...deviceData, amount};
    delete params.canDelete;
    delete params.isValid;

    const object = getWritableValue('g:object', this.props.context, {}) || {};
    const store = getWritableValue('g:store', this.props.context, '') || '';
    const station = getWritableValue('g:station', this.props.context, '') || '';
    const user = getWritableValue('g:user', this.props.context, '') || '';
    if (isObject(object) && !isEmptyObject(object)) {
      params.order = object;
      params.order.store = store;
      params.order.station = station;
      params.order.user = user;
    }
    if (processor === 'datacap') {
      params.sequenceNo = requestId;
    } else if (processor === 'dejavoo') {
      const {requestId: refId} = this.state;
      params.paymentType = 'Credit';
      params.refId = refId;
    } else {
      params.requestId = requestId;
    }
    return Object.keys(params).reduce((result, curr) => {
      if (isString(params[curr]) && !isEmptyString(params[curr])) {
        result[curr] = params[curr].trim();
      } else {
        result[curr] = params[curr];
      }
      return result;
    }, {});
  };

  onStartDatacap = () => {
    const {context} = this.props;
    const payload = this.getPayload();
    onTriggerAction(DataCap.resetPinPad(payload), context)
      .then((resp) => {
        const response = isArray(resp) ? resp[0] : resp;
        const {textResponse, DSIXReturnCode, sequenceNo} = response;
        const conditions = [
          {
            condition: !DSIXReturnCode,
            composeValue: () => {
              this.stopWatch(false);
              throw new Error();
            }
          },
          {
            condition: DSIXReturnCode === '003002',
            composeValue: () => {
              this.stopWatch(false);
              this.onMessage({type: 'danger', content: `${textResponse}: ${ERROR_DATACAP_BUSY}`});
            }
          },
          {
            condition: DSIXReturnCode !== '000000',
            composeValue: () => {
              this.stopWatch(false);
              this.onMessage({type: 'danger', content: textResponse});
            }
          },
          {
            condition: DSIXReturnCode === '000000',
            composeValue: () => {
              this.setState({requestId: sequenceNo, watching: true});
              this.onWatchDatacap();
            }
          }
        ];
        const {composeValue} = conditions.find(({condition}) => condition) || {};
        if (composeValue && typeof composeValue === 'function') composeValue();
      })
      .catch(e => {
        this.stopWatch(false);
        this.onMessage({type: 'danger', content: ERROR_DATACAP_BEFORE_TRAN});
      });
  };

  onWatchDatacap = () => {
    const {context} = this.props;
    const payload = this.getPayload();
    const {sendingToDevice} = this.state;
    if (!sendingToDevice) return null;

    onTriggerAction(DataCap.createTransaction(payload), context)
      .then((resp) => {
        const responseTran = isArray(resp) ? resp[0] : resp;
        const {textResponse, DSIXReturnCode} = responseTran;

        const request = getTransactionHistoryRequest(payload, 'datacap', responseTran);
        this.createTransactionHistory(request, context);

        const conditions = [
          {
            condition: !DSIXReturnCode,
            composeValue: () => {
              this.stopWatch(false);
              throw new Error();
            }
          },
          {
            condition: DSIXReturnCode === '003002',
            composeValue: () => {
              this.stopWatch(false);
              this.onMessage({type: 'danger', content: `${textResponse}: ${ERROR_DATACAP_BUSY}`});
            }
          },
          {
            condition: DSIXReturnCode !== '000000',
            composeValue: () => {
              this.stopWatch(false);
              this.onMessage({type: 'danger', content: textResponse});
            }
          },
          {
            condition: DSIXReturnCode === '000000',
            composeValue: () => {
              this.setState({transaction: responseTran}, () => {
                setTimeout(() => {
                  this.onFinishDatacap();
                }, 3000);
              });
            }
          }
        ];
        const {composeValue} = conditions.find(({condition}) => condition) || {};
        if (composeValue && typeof composeValue === 'function') composeValue();
      })
      .catch(e => {
        this.stopWatch(false);
        this.onMessage({type: 'danger', content: ERROR_TRAN_STATUS});
      });
  };

  onFinishDatacap = () => {
    const {context} = this.props;
    const payload = this.getPayload();
    const {transaction} = this.state;
    const {purchase, cardholderName, acctNo, postProcess = ''} = transaction;

    onTriggerAction(DataCap.resetPinPad(payload), context)
      .then((resp) => {
        const responseFinish = isArray(resp) ? resp[0] : resp;
        const {textResponse, DSIXReturnCode} = responseFinish;
        this.stopWatch(false);
        const conditions = [
          {
            condition: !DSIXReturnCode,
            composeValue: () => {
              throw new Error();
            }
          },
          {
            condition: DSIXReturnCode === '000000',
            composeValue: () => {
              if (postProcess === 'EMVParamDownloadRequired') {
                const mes = {type: 'info', content: postProcess};
                this.setState({message: mes});
              }
              const mes = {type: 'success', content: textResponse};
              this.setState({message: mes});

              this.onSaveTransaction({
                amount: purchase,
                source: 'device',
                type: 'creditCard',
                processor: 'datacap',
                cardNumber: acctNo,
                cardHolder: cardholderName,
                transactions: [
                  {...transaction, type: 'Sale'}
                ]
              });
            }
          },
          {
            condition: DSIXReturnCode !== '000000',
            composeValue: () => {
              if (postProcess === 'EMVParamDownloadRequired') {
                const mes = {type: 'info', content: postProcess};
                this.setState({message: mes});
              }
              const mes = {type: 'danger', content: textResponse};
              this.setState({message: mes});
            }
          }
        ];
        const {composeValue} = conditions.find(({condition}) => condition) || {};
        if (composeValue && typeof composeValue === 'function') composeValue();
      })
      .catch(e => {
        this.stopWatch(false);
        this.onMessage({type: 'danger', content: ERROR_DATACAP_AFTER_TRAN});
      });
  };

  stopWatchDejavoo = () => {
    const {context} = this.props;
    if (this.timerId) clearInterval(this.timerId);

    if (this.element) {
      this.setState({sendingToDevice: false});
    }
    const {requestId} = this.state;
    if (requestId) {
      this.setState({requestId: ''});
      onTriggerAction(Dejavoo.stop(requestId), context)
        .then((resp) => {
          const response = isArray(resp) ? resp[0] : resp;
          console.log('STOP DEJAVOO STATION JOBS', response);
        })
        .catch((e) => {
          const error = getErrorString(e);
          console.log('STOP DEJAVOO STATION JOBS', error);
        });
    }
  };

  onWatchDejavoo = () => {
    const {requestId} = this.state;
    const {context} = this.props;
    if (requestId && this.state.status !== 'Processing terminated') {
      onTriggerAction(Dejavoo.check(requestId), context)
        .then(resp => {
          const response = isArray(resp) ? resp[0] : resp;
          const error = getErrorString(response);
          const {requestId: refId, status, message, result} = response;
          console.log('Dejavoo status response', response);
          if (error || !refId) {
            this.stopWatchDejavoo();
            this.onMessage({type: 'danger', content: error || 'Missing Reference ID from original transaction'});
          } else if (refId === requestId) {
            this.setState({status});
            if (this.state.requestId && status === 'Processing terminated') {
              this.stopWatchDejavoo();
              if (isString(result) && !isEmptyString(result)) {
                try {
                  const resultObject = JSON.parse(result);
                  if (isObject(resultObject) && !isEmptyObject(resultObject)) {
                    this.processDejavooResult(resultObject);
                  } else {
                    this.onMessage({type: 'danger', content: 'Unexpected result'});
                  }
                } catch (e) {
                  const error = getErrorString(e);
                  this.onMessage({type: 'danger', content: error});
                }
              } else {
                this.onMessage({type: 'danger', content: message});
              }
            }
          }
        })
        .catch(e => {
          const error = getErrorString(e);
          this.stopWatchDejavoo();
          this.onMessage({type: 'danger', content: error});
        });
    }
  };

  processDejavooResult = (result) => {
    const {resultCode, respMSG, message} = result;
    if (+resultCode === 0 && message && ['approved', 'success'].includes(message.toLowerCase())) {
      const {cardType, totalAmt, name, acntLast4, acntFirst4, SVC, fee, SHFee} = result;
      const cardNumber = getMaskedCardNumber(acntFirst4, acntLast4);
      const cartTypeByNumber = getCardType(cardNumber);
      this.onMessage({type: 'success', content: message});

      this.onSaveTransaction({
        processor: 'dejavoo',
        cardType: cartTypeByNumber || cardType,
        type: 'creditCard',
        source: 'device',
        amount: totalAmt,
        cardHolder: name,
        surcharge: SVC,
        cardNumber,
        SHFee,
        fee,
        transactions: [{...result, type: 'Sale', cardType: cartTypeByNumber || cardType}]
      });
    } else {
      this.onMessage({type: 'danger', content: respMSG || message});
    }
  };


  onStopWatchPOSLink = () => {
    const {context} = this.props;
    if (this.timerId) clearInterval(this.timerId);
    if (this.element) {
      this.setState({sendingToDevice: false});
    }
    const {requestId} = this.state;
    if (requestId) {
      this.setState({requestId: '', status: ''});
      onTriggerAction(POSLink.stop(requestId), context)
        .then(resp => {
          const response = isArray(resp) ? resp[0] : resp;
          console.log('STOP POSLink STATION JOBS', response);
        })
        .catch(e => {
          const error = getErrorString(e);
          console.log('STOP POSLink STATION JOBS', error);
        });
    }
  };

  onWatchPOSLink = () => {
    const {context} = this.props;
    const {requestId, status} = this.state;
    if (requestId && status !== 'Processing terminated') {
      onTriggerAction(POSLink.check(requestId), context)
        .then(resp => {
          const response = isArray(resp) ? resp[0] : resp;
          console.log('CHECK POSLink STATION JOBS', response);
          const error = getErrorString(response);
          const {requestId: refId, status, message, result} = response;
          if (error) {
            this.onStopWatchPOSLink();
            this.onMessage({type: 'danger', content: error});
          } else if (refId === requestId) {
            if (this.state.status !== status) {
              this.setState({status});
              // this.onMessage({type: 'info', content: status});
            }
            if (status === 'Processing terminated') {
              this.onStopWatchPOSLink();
              if (isString(result) && !isEmptyString(result)) {
                try {
                  const resultObject = JSON.parse(result);
                  if (isObject(resultObject) && !isEmptyObject(resultObject)) {
                    const {cardType, amount, convenienceFee, cardHolder = '', cardNumber = ''} = resultObject;

                    this.onSaveTransaction({
                      amount,
                      cardType,
                      cardNumber,
                      cardHolder,
                      convenienceFee,
                      source: 'device',
                      processor: 'poslink',
                      type: 'creditCard',
                      transactions: [{...resultObject, type: 'Sale'}]
                    });
                  } else {
                    this.onMessage({type: 'danger', content: 'Unexpected result'});
                  }
                } catch (e) {
                  this.onMessage({type: 'danger', content: e});
                }
              } else {
                this.onMessage({type: 'danger', content: message});
              }
            }
          }
        })
        .catch(e => {
          const error = getErrorString(e);
          this.onStopWatchPOSLink();
          this.onMessage({type: 'danger', content: error || ERROR_TRAN_STATUS});
        });
    }
  };

  onStartPOSLink = () => {
    const {context} = this.props;
    this.timerId = setInterval(this.onWatchPOSLink, 2000);
    const payload = this.getPayload();
    this.setState({status: '', requestId: ''});

    onTriggerAction(POSLink.start(payload), context)
      .then(resp => {
        const res = isArray(resp) ? resp[0] : resp;
        const err = getErrorString(res);
        if (err) {
          this.onMessage({type: 'danger', content: err});
          setTimeout(this.onStopWatchPOSLink, 1000);
        } else {
          const {requestId} = res;
          if (requestId) {
            this.setState({requestId});
          } else {
            this.onStopWatchPOSLink();
            this.onMessage({type: 'danger', content: 'Missing Reference ID from original transaction'});
          }
        }
      })
      .catch(e => {
        const error = getErrorString(e);
        this.onStopWatchPOSLink();
        this.onMessage({type: 'danger', content: error || ERROR_TRAN_STATUS});
      });
  };

  createTransactionHistory = (request, context) => {
    if (isObject(request) && !isEmptyObject(request)) {
      const order = getWritableValue('g:object', context, {}) || {};
      const department = getWritableValue('g:department', context, '') || '';
      const store = getWritableValue('g:store', context, '') || '';
      const stationName = getWritableValue('g:stationName', context, '') || '';
      const user = getWritableValue('g:user', context, '') || '';
      order.department = department;
      order.store = store;
      order.station = stationName;
      order.user = user;
      if (isObject(order) && !isEmptyObject(order)) {
        request.order = order;
      }

      onTriggerAction(TransactionsHistory.createOne(request), context)
        .catch(e => {
          const err = getErrorString(e);
          console.log('Error Transaction History', err);
        });
    }
  }

  onStartDejavoo = () => {
    Debounced.start('onStartDejavoo', () => {
      const {context} = this.props;
      const data = this.getPayload();
      if (!this.state.requestId) {
        this.timerId = setInterval(this.onWatchDejavoo, 2000);
        onTriggerAction(Dejavoo.start(data), context)
          .then((res) => {
            const response = isArray(res) ? res[0] : res;
            const err = getErrorString(response);
            if (err) {
              this.onMessage({type: 'danger', content: err});
              setTimeout(this.stopWatchDejavoo, 1000);
            } else {
              const {requestId} = response;
              if (requestId) {
                this.setState({requestId});
              } else {
                this.stopWatchDejavoo();
                this.onMessage({type: 'danger', content: 'Missing Reference ID from original transaction'});
              }
            }
          })
          .catch(e => {
            this.stopWatchDejavoo();
            const error = getErrorString(e);
            if (error) {
              this.onMessage({type: 'danger', content: error});
            }
          });
      } else {
        this.onMessage({
          type: 'danger',
          content: `Looks like previous transaction #${this.state.requestId} is in the process`
        });
      }
    })
  };

  onMessage = ({type, content}) => {
    if (content) {
      console.log('Message:', content);
    }
    const ctn = isObject(content) && !isEmptyObject(content) ? getErrorString(content) : content;
    const {context} = this.props;
    if (type === 'danger') {
      setValue('g:errorMessage', ctn, context);
    } else {
      const message = {type, content: ctn};
      this.setState({message});
      setTimeout(() => {
        if (this.element) this.setState({message: {type: '', content: ''}});
      }, 1000);
    }
  };

  onStart = () => {
    const {context} = this.props;
    const {processor, sendingToDevice} = this.state;

    if (!sendingToDevice) {
      this.setState({status: 'Sending card data to device', sendingToDevice: true});
      setValue('g:errorMessage', '', context);

      if (processor === 'poslink') {
        this.onStartPOSLink();
        return null;
      }

      if (processor === 'datacap') {
        this.onStartDatacap();
        return null;
      }

      if (processor === 'dejavoo') {
        this.onStartDejavoo();
        return null;
      } else {
        this.timerId = setInterval(this.onWatch, 2000);

        const data = this.getPayload();
        onTriggerAction(this.getDeviceApi().createTransaction(data), context)
          .then((resp) => {
            const response = isArray(resp) ? resp[0] : resp;

            if (processor === 'usaepay') {
              if (isObject(response) && !isEmptyObject(response)) {
                const [error, success] = getUsaepayResponseStatus(response);
                const {key} = response;

                if (isString(error) && !isEmptyString(error)) {
                  this.stopWatch(false);
                  this.onMessage({type: 'danger', content: error});
                } else if ((isString(success) && !isEmptyString(success)) || (isString(key) && !isEmptyString(key))) {
                  if (isString(success) && !isEmptyString(success)) {
                    this.onMessage({type: 'info', content: success});
                  }
                  if (isString(key) && !isEmptyString(key)) {
                    this.setState({requestId: key});
                  } else {
                    this.stopWatch(false);
                    this.onMessage({type: 'danger', content: 'Missing Request Id from response. Try again'});
                  }
                } else {
                  this.stopWatch(false);
                  this.onMessage({type: 'danger', content: 'Please try again'});
                }
              } else {
                this.stopWatch(false);
                this.onMessage({type: 'danger', content: 'Sorry no recognized response from server'});
              }
            } else {
              const {errors, error, requestId} = response;
              if (errors) {
                this.stopWatch(false);
                this.onMessage({type: 'danger', content: JSON.stringify(errors)});
              } else if (error) {
                this.stopWatch(false);
                this.onMessage({type: 'danger', content: JSON.stringify(error)});
              } else if (requestId) {
                this.setState({requestId});
              } else {
                this.stopWatch(false);
                this.onMessage({type: 'danger', content: 'Sorry no recognized response from server'});
              }
            }
          })
          .catch(e => {
            this.stopWatch(false);
            this.onMessage({type: 'danger', content: 'Unexpected error. Please contact admin'});
          });
      }
    }
  };

  onSaveTransaction = (transaction) => {
    const {props, context} = this.props;
    if (!props || !context) return null;

    const target = props.value || props['@value'];
    const streamName = `${target}.transaction`;
    setValue(streamName, transaction, context);
    this.setState({payment: transaction});

    const {amount = 0, SVC = 0, fee = 0, SHFee = 0, convenienceFee = 0} = transaction;
    setValue('newOrder:totals.grandTotal', amount, context);
    if (parseFloat(SVC) > 0) {
      setValue('newOrder:totals.surcharge', SVC, context);
    }
    if (parseFloat(convenienceFee) > 0) {
      setValue('newOrder:totals.convenienceFee', convenienceFee, context);
    }
    if (parseFloat(fee) > 0) {
      setValue('newOrder:totals.fee', fee, context);
    }
    if (parseFloat(SHFee) > 0) {
      setValue('newOrder:totals.SHFee', SHFee, context);
    }

    const {onSubmit} = props;
    if (onSubmit) {
      triggerAction(onSubmit, context)
        .catch(e => {
          const error = getErrorString(e);
          if (error) {
            this.onMessage({type: 'danger', content: error});
          }
        });
    }
  };

  /* END DEVICE */

  render() {
    const {props, context, styles: {disabled: isDisabled} = {}} = this.props;
    const {status} = this.state;
    if (!this.canStart()) return null;
    const {sendingToDevice, deviceData, payment, amount, message} = this.state;
    const {stationName: station} = deviceData || {};

    const {transactions} = payment || {};
    const hasTransaction = isArray(transactions) && !isEmptyArray(transactions);
    const canSubmit = getWritableValue('g:validation.canSubmit', context, '') || '';
    const isAgree = getWritableValue('g:object.agree', context, '') || '';
    const disabled = !canSubmit || !isAgree || (isValue(isDisabled) && isDisabled) || sendingToDevice ||
      isEmptyString(amount) || parseFloat(amount) <= 0 || hasTransaction;

    return (
      <div className={`id-${props._id} ${props.classes}`} style={this.props.styles} ref={el => this.element = el}>
        <div style={{textAlign: 'center', padding: '20px', maxWidth: '760px', borderRadius: '4px', margin: '0 auto'}}>
          <ChargeComponent
            {...{
              status,
              station,
              payment,
              disabled,
              sendingToDevice,
              device: deviceData,
              onStart: this.onStart
            }}
          />
          {message.content ? (<MessageBox {...message} />) : null}
        </div>
      </div>
    );
  }
}

export default ChargeContainer;
