import { getStyles } from '../PaymentTypeHelpers';
import * as types from '../../services/VariableType';
import { generateTableElement, getObjectOfTextElements } from './PdfElements';

const { isArray, isEmptyArray, isValue, isEmptyString, isString, isObject, isEmptyObject } = types;

const getCustomFieldsObject = (customFields) => {
  if (isArray(customFields) && !isEmptyArray(customFields)) {
    return customFields
      .reduce((result, item) => {
        const { labels = {}, values = {} } = result;
        if (isObject(item) && !isEmptyObject(item)) {
          const { id, label, field } = item;
          if (isValue(field) && isString(field) && !isEmptyString(field)) {
            labels[id] = label;
            values[id] = field;
          }
        }
        return { labels, values };
      }, { labels: {}, values: {} });
  }
};

export const getElement = ({ customFields, styles, isTplLarge }) => {
  const stylesCommon = getStyles(styles, isTplLarge).header;
  const { labels = {}, values = {} } = getCustomFieldsObject(customFields) || {};

  const stylesLabels = Object.keys(labels).reduce((prev, curr) => {
    return { ...prev, [curr]: stylesCommon.label };
  }, {});

  const stylesValues = Object.keys(values).reduce((prev, curr) => {
    return { ...prev, [curr]: stylesCommon.value };
  }, {});

  const objectLabels = getObjectOfTextElements(labels, stylesLabels);
  const objectValues = getObjectOfTextElements(values, stylesValues);

  const params = {
    headers: objectLabels,
    values: objectValues,
    ...stylesCommon.table
  };

  return generateTableElement(params);
};
