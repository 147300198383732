export const getErrorString = (data) => {
  let res = '';
  if (data) {
    if (data.errors && data.errors.stack) {
      return 'Unexpected response. Please contact admin';
    };
    const level1 = ['statusText', 'statusMessage', 'errors', 'error', 'reason'];
    level1.forEach((field1) => {
      if (typeof data[field1] === 'string') {
        res = data[field1];
      } else if (data[field1] && typeof data[field1] === 'object') {
        if (data[field1].message) {
          res = data[field1].message;
        } else if (data[field1].reason) {
          res = data[field1].reason;
        }
      }
    });
  }
  return res;
};
