import moment from 'moment';
import * as pdfElem from './PdfElements';
import { checkItems, getQTYCoef } from '../OrderHelpers';
import { hardRequirementError } from '../ValidatorHelpers';
import { getMoneyString, getStyles } from '../PaymentTypeHelpers';
import { getRentalName, getTotalsRentalDays, isRental } from '../RentalHelpers';
import { isArray, isEmptyArray, isEmptyString, isString, isValue } from '../VariableType';
import { fixedTo2, getJoinArr, getJoinObject, getLocalShortDateArr, isDigit } from '../GeneralHelpers';

const { generateColumnElement, getArrOfTextElements, getObjectOfTextElements, getSpace, getTextElement } = pdfElem;

const getRentalRangeString = (dates) => {
  const datesLocal = getLocalShortDateArr(dates);
  const suffix = datesLocal.length > 1 ? 'days rental:' : 'day rental:';
  const fixedStart = datesLocal[0];
  const fixedEnd = datesLocal[datesLocal.length - 1];
  const range = (moment(fixedStart).isSame(moment(fixedEnd))) ? fixedStart : `${fixedStart} - ${fixedEnd}`;
  const object = { count: datesLocal.length, suffix, range: `\n${range}\n` };
  return getJoinObject(object);
};

const getRentalDetails = (rentalSerials = []) => {
  if (hardRequirementError(rentalSerials)) return '';
  return rentalSerials.reduce((prev, curr) => {
    const { dates = [], serial } = curr;
    if (!serial) return prev;
    const rentalDatesString = getRentalRangeString(dates);
    const object = { field: 'Serial:', serial };
    const serialString = getJoinObject(object);

    const resultObject = { serialString, rentalDatesString };
    prev.push(getJoinObject(resultObject, '\n'));
    return prev;
  }, []);
};

const getCertificatesColumnElement = (certificates, styles) => {
  if (isArray(certificates) && !isEmptyArray(certificates)) {
    const result = certificates.reduce((res, curr, i) => {
      const { headers, values } = res;
      const { code, balance } = curr;
      if (isString(code) && !isEmptyString(code)) {
        headers[`code${i}`] = 'Code:';
        values[`code${i}`] = code;
        headers[`balance${i}`] = 'Balance:';
        values[`balance${i}`] = getMoneyString(balance);
      }
      return res;
    }, { headers: {}, values: {} });

    const { headers, values } = result;

    const styleCommon = { ...styles, width: 'auto', bold: false };
    const stylesValues = Object.keys(values).reduce((prev, curr) => {
      return ({ ...prev, [curr]: { ...styleCommon } });
    }, {});
    const stylesColumns = Object.keys(headers).reduce((prev, curr) => ({ ...prev, [curr]: { columnGap: 5 } }), {});

    const params = {
      headers: getObjectOfTextElements(headers, stylesValues),
      values: getObjectOfTextElements(values, stylesValues),
      styles: stylesColumns
    };

    const element = [];
    element.push(generateColumnElement(params));
    return element;
  }
  return '';
};

const getProductColumnElement = (product, styles, certifications) => {
  const {
    upc = false,
    sku = false,
    name = false,
    rental = false,
    selected = false,
    category = '',
    isModifier = false,
    description = false,
    rentalSerials = false,
    selectedSerials = false,
    selectedSubcategories = false
  } = product;

  const values = {
    name: isString(name) && !isEmptyString(name) ? `${isModifier ? `${category}: ` : ''}${name}` : '',
    sku: isString(sku) && !isEmptyString(sku) ? sku : '',
    upc: isString(upc) && !isEmptyString(upc) ? upc : '',
    description: isString(description) && !isEmptyString(description) ? description : '',
    rental: isRental(rental) && !hardRequirementError(rental) ? getRentalName(rental) : '',
    rentalSerials: isRental(rental) && !hardRequirementError(rentalSerials) ? getRentalDetails(rentalSerials) : '',
    selectedSerials: !isRental(rental) && !hardRequirementError(selectedSerials)
      ? getJoinArr(selectedSerials, 'Serial:')
      : '',
    selected: !hardRequirementError(selected) ? getJoinObject(selected) : '',
    selectedSubCategories: isArray(selectedSubcategories) && !isEmptyArray(selectedSubcategories)
      ? selectedSubcategories.map(o => o.name + ' ' + (+o.price ? getMoneyString(o.price) : '')).join(', ')
      : ''
  };

  const headers = {
    sku: 'SKU:',
    upc: 'UPC:'
  };

  const elBold = ['rental', 'selectedSerials'];
  const styleCommon = { ...styles, width: 'auto', bold: false };
  const stylesValues = Object.keys(values).reduce((prev, curr) => {
    const bold = elBold.includes(curr) ? { bold: true } : {};
    return ({ ...prev, [curr]: { ...styleCommon, ...bold } });
  }, {});
  const stylesColumns = Object.keys(headers).reduce((prev, curr) => ({ ...prev, [curr]: { columnGap: 5 } }), {});

  const params = {
    headers: getObjectOfTextElements(headers, stylesValues),
    values: getObjectOfTextElements(values, stylesValues),
    styles: stylesColumns
  };

  const element = [];
  element.push(generateColumnElement(params));
  const certifCol = getCertificatesColumnElement(certifications, styles);
  if (certifCol) {
    element.push(certifCol);
  }
  return element;
};

export const getItemQuantity = (item) => {
  const { soldByWeight, rentalSerials, rental } = item;
  const isRent = isRental(rental);
  const totalDays = getTotalsRentalDays({ rentalSerials });
  const conditions = [
    {
      condition: isRent,
      composeValue: () => `${parseInt(totalDays, 0)} ${totalDays > 1 ? 'days' : 'day'}`
    },
    {
      condition: !isRent && soldByWeight,
      composeValue: () => `${fixedTo2(item.quantity)} lbs`
    },
    {
      condition: !isRent && !soldByWeight,
      composeValue: () => +fixedTo2(item.quantity)
    }
  ];
  const { composeValue } = conditions.find(({ condition }) => condition) || {};
  return typeof composeValue !== 'undefined' ? composeValue() : +fixedTo2(item.quantity);
};

const getPrice = ({ price = 0, discountedPrice = 0 }) => (
  (isValue(discountedPrice) && isDigit(discountedPrice) && (+discountedPrice > 0))
    ? getMoneyString(discountedPrice)
    : getMoneyString(price)
);

const getTotals = ({ price = 0, discountedPrice = 0, qty = 1 }) => (
  isValue(discountedPrice) && isDigit(discountedPrice) && (+discountedPrice > 0)
    ? getMoneyString(discountedPrice * qty)
    : getMoneyString(price * qty)
);

// const getRentalDays = (totalDays) => {
//   const suffix = totalDays > 1 ? 'days' : 'day';
//   const object = { totalDays: parseInt(totalDays, 0), suffix };
//   return getJoinObject(object);
// };

const styleAligmentRight = (elRight, curr) => (elRight.includes(curr) ? { alignment: 'right' } : {});

export const getElement = ({ products, certificates, styles, isTplLarge }) => {
  if (!checkItems(products)) return '';
  const header = ['#', 'Name', 'Price', 'Qty','Amount'];
  const elRight = ['Price', 'Amount'];

  const stylesHeader = getStyles(styles, isTplLarge).items.header;
  const stylesTable = getStyles(styles, isTplLarge).items.table;
  const stylesHeaderRow = header.reduce((prev, curr) => {
    const right = styleAligmentRight(elRight, curr);
    return ({ ...prev, [curr]: { ...stylesHeader, ...right } });
  }, {});
  const stylesValues = header.reduce((prev, curr) => {
    const right = styleAligmentRight(elRight, curr);
    return ({ ...prev, [curr]: { ...stylesTable, ...right } });
  }, {});

  const body = [];

  if (isTplLarge) {
    const headerRow = getArrOfTextElements(header, stylesHeaderRow);
    body.push(headerRow);
  }

  const setProducts = (body, list, printNum = true) => list.forEach((product, i) => {
    const qty = getQTYCoef(product);
    const {rental = false, price = 0, modifiers = []} = product;
    const qtyString = getItemQuantity(product);
    const priceString = getPrice({ ...product });
    const totalsString = getTotals({ ...product, qty });
    const suffix = isRental(rental) ? '\n * price per day' : '';
    const certif = product.category === 'Gift Certificates' ? certificates : [];

    const columns = [];
    if (isTplLarge) {
      columns.push(printNum ? getTextElement(`${i + 1}`, stylesValues['#']) : '+');
      columns.push(getProductColumnElement(product, styles, certif));
      columns.push(+price > 0 ? getTextElement(getJoinObject({ priceString, suffix }), stylesValues['Price']) : '');
      columns.push(+price > 0 ? getTextElement(qtyString, stylesValues['Qty']) : '');
      columns.push(+price > 0 ? getTextElement(totalsString, stylesValues['Amount']) : '');
    } else {
      columns.push(getProductColumnElement(product, styles, certif));
      if (+price > 0) {
        const priceStr = suffix ? `${priceString}*` : priceString;
        const amountString = `${priceStr} x ${qtyString} = ${totalsString}`;
        columns.push(getTextElement(getJoinObject({amountString, suffix}), stylesValues['Amount']));
        columns.push(getSpace('0.5'));
      }
    }

    if (modifiers && modifiers.length) {
      const mod = []
      const modifiersList = modifiers.map(o => ({...o, isModifier: true}))
      setProducts(mod, modifiersList, false);
      if (isTplLarge) {
        body.push(columns);
        body.push(...mod);
      } else {
        columns.push(mod);
        body.push(columns);
      }
    } else {
      body.push(columns);
    }
  });
  setProducts(body, products, true)

  const widths = ['auto', 'auto', '*', '*', '*'];
  const margin = [0, 5, 0, 5];
  const headerRows = 1;

  if (isTplLarge) {
    return {
      table: { body, headerRows, widths },
      margin,
      layout: {
        defaultBorder: false
      }
    };
  }
  return { ol: body, separator: ') ', fontSize: '8' };

};
