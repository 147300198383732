import { usaepayErrorcode } from '../CreditCardHelpers';
import { capitalizeFirstLetter } from '../GeneralHelpers';
import { isValue, isString, isEmptyString, isObject, isEmptyObject } from '../VariableType';

export const getUsaepayResponseStatus = (response) => {
  if (isObject(response) && !isEmptyObject(response)) {
    const { error, errorcode, /* key, expiration ,*/ status, transaction } = response;
    const hasRequestError = isString(error) && !isEmptyString(error);
    const hasRequestErrorCode = isValue(errorcode) && usaepayErrorcode[+errorcode];
    if (hasRequestError || hasRequestErrorCode) {
      if (hasRequestError) {
        return [error, null];
      } else if (isValue(errorcode) && usaepayErrorcode[+errorcode]) {
        const message = usaepayErrorcode[+errorcode];
        return [message, null];
      }
    } else {
      if (isObject(transaction) && !isEmptyObject(transaction)) {
        const { refnum, result_code, error, result /* type, result, error_code, */ } = transaction;
        if (refnum && result_code === 'A') {
          return [null, result];
        } else {
          return [error, null];
        }
      } else {
        if (isString(status) && !isEmptyString(status)) {
          const statusMessage = capitalizeFirstLetter(status);
          return [null, statusMessage];
        }
      }
    }
  }
  return [null, null];
};
