import React, { Component } from 'react';
import styled from 'styled-components';
import ec from 'ec-react15-lib';

import { SwipeForm, MessageBox, FancyCreditCard, FormCreditCard } from './index';
import { doCardValidator } from '../../../../services/validations/form';
import * as cardHelpers from '../../../../services/CreditCardHelpers';
import shallowEqualsObj from '../../../../services/ShallowEqualsObj';
import { isArray, isEmptyArray, isEmptyObject, isEmptyString, isObject, isString
} from '../../../../services/VariableType';

const { setValue, getValue, Debounced, renderChildren, getWritableValue } = ec;

const { getDecodeSwipe, validateCard, formatCardNumber } = cardHelpers;

const Card = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    margin-left: 0px;
    justify-content: flex-start;
  }
`;

const stylesList = {
  textCenter: {
    textAlign: 'center'
  }
};

const defaultSwipe = {
  active: false,
  data: '',
  card: {}
};

/*
* props:
* value
* swipeCard
* appearanceCard
* */

class PaymentCreditCardContainer extends Component {

  state = {
    location: '',
    encryptedManifest: '',
    store: {},
    payment: {},
    message: {},
    validation: {},
    swipe: {
      active: false,
      data: '',
      card: {}
    },
    card: {
      cardHolder: '',
      cardNumber: '',
      trackData: '',
      cardType: '',
      expMonth: '',
      expYear: '',
      amount: '',
      cvv: ''
    }
  };

  componentWillReceiveProps(nextProps) {
    const { props, context } = nextProps;
    if (!props || !context) return null;

    const object = getValue(props, 'value', context);
    if (object && (!shallowEqualsObj(object, this.state.card, shallowEqualsObj))) {
      const card = { ...this.state.card, ...object };
      const { amount: val = 0 } = object;
      const amount = parseFloat(val) || 0;
      this.setState({ card: { ...card, amount } });
    }

    const onlineDepartment = getWritableValue('docs:onlineDepartment', context, {}) || {};
    const location = getWritableValue('g:department', context, '') || '';
    const publicList = getWritableValue('docs:publicList', this.props.context, []) || [];
    const secureList = getWritableValue('docs:secureList', this.props.context, []) || [];
    const locationList = isArray(secureList) && !isEmptyArray(secureList) ? secureList : publicList;
    if (!!location && (location !== this.state.location)) {
      const tsepSelected = locationList.find(o => o.department === location && o.paymentEngine === 'tsep');
      if (!!tsepSelected && !tsepSelected.error &&
        isString(tsepSelected.encryptedManifest) && !isEmptyString(tsepSelected.encryptedManifest)) {
        if (tsepSelected.encryptedManifest !== this.state.encryptedManifes) {
          const {encryptedManifest = '', deviceId = '', testMode} = tsepSelected;
          this.setState({encryptedManifest: ''}, () => {
            this.initTsep(deviceId, encryptedManifest, testMode);
          });
        }
      } else {
        const processorError = getWritableValue('g:validation.processor', context, '') || '';
        const { encryptedManifest = '', error = ''} = tsepSelected || {};
        if (processorError !== error) {
          this.resetPostMessage('', error);
        }
        if (encryptedManifest !== this.state.encryptedManifest) {
          this.setState({encryptedManifest: encryptedManifest});
        }
      }
    } else if (!location && isObject(onlineDepartment) && !isEmptyObject(onlineDepartment)) {
      const {payments: {paymentEngine = {}}} = onlineDepartment;
      const {encryptedManifest = '', deviceId = '', testMode, error = ''} = paymentEngine;
      if (!error && isString(encryptedManifest) && !isEmptyString(encryptedManifest)) {
        if (encryptedManifest !== this.state.encryptedManifes) {
          this.setState({encryptedManifest: ''}, () => {
            this.initTsep(deviceId, encryptedManifest, testMode);
          });
        }
      } else {
        const processorError = getWritableValue('g:validation.processor', context, '') || '';
        if (processorError !== error) {
          this.resetPostMessage('', error);
        }
        if (encryptedManifest !== this.state.encryptedManifest) {
          console.log({ componentWillReceiveProps: 'encryptedManifest3' });

          this.setState({encryptedManifest: encryptedManifest});
        }
      }
    }
    this.setState({ location })
  }

  initTsep = (deviceId, encryptedManifest, testMode) => {
    Debounced.start('initTsep', () => {
      const prevScript = document.getElementById("tsepScript");
      const scriptList = document.querySelectorAll("script")
      const convertedNodeList = Array.from(scriptList);
      for (let i = 0; i < convertedNodeList.length; i++) {
        const script = convertedNodeList[i];
        if (script.src.includes("transit-tsep-web")) {
          script.parentNode.removeChild(script);
        }
      }
      const script = document.createElement("script");
      script.src = !!testMode
        ? "https://stagegw.transnox.com/transit-tsep-web/jsView/" + deviceId + "?" + encryptedManifest
        : "https://gateway.transit-pass.com/transit-tsep-web/jsView/" + deviceId + "?" + encryptedManifest;
      script.id = "tsepScript";
      script.async = true;
      document.head.appendChild(script);

      if (!prevScript) {
        const tsepHandler = document.createElement("script");
        tsepHandler.innerHTML =
          `function tsepHandler(eventType, event) {
            if (eventType === 'TokenEvent' && event) {
              const { cardType, cvv2, expirationDate, maskedCardNumber, message, status, tsepToken } = event;
              if (status === 'PASS') {
                const actions = {
                  set: [
                    { target: 'g:errorMessage', value: '' },
                    { target: 'g:object.payment.cardNumber', value: tsepToken },
                    { target: 'g:object.payment.cvv', value: cvv2.trim() },
                    { target: 'g:object.payment.expirationDate', value: expirationDate },
                    { target: 'g:object.payment.expMonth', value: expirationDate.split('/')[0] },
                    { target: 'g:object.payment.expYear', value: expirationDate.split('/')[1] },
                    { target: 'g:object.payment.cardType', value: cardType },
                    { target: 'g:object.payment.maskedCardNumber', value: maskedCardNumber }
                  ]
                };
                window.postMessage({message: 'EDITOR_CALL_REMOTE_ACTION', actions: {actions}}, '*');
              } else {
                 const actions = {
                    set: [
                      { target: 'g:errorMessage', value: message },
                      { target: 'g:object.payment.cardNumber', value: '' },
                      { target: 'g:object.payment.cvv', value: '' },
                      { target: 'g:object.payment.expirationDate', value: '' },
                      { target: 'g:object.payment.cardType', value: '' },
                      { target: 'g:object.payment.maskedCardNumber', value: '' }
                    ]
                 };
                 window.postMessage({message: 'EDITOR_CALL_REMOTE_ACTION', actions: {actions}}, '*');
               }
            }
          }`;
        tsepHandler.async = true;
        document.head.appendChild(tsepHandler);
      }
      this.setState({encryptedManifest})
    })
  };

  resetPostMessage = (message, processorError) => {
    const actions = {
      set: [
        { target: 'g:errorMessage', value: message },
        { target: 'g:validation.processor', value: processorError },
        { target: 'g:object.payment.cardNumber', value: '' },
        { target: 'g:object.payment.cvv', value: '' },
        { target: 'g:object.payment.expirationDate', value: '' },
        { target: 'g:object.payment.cardType', value: '' },
        { target: 'g:object.payment.maskedCardNumber', value: '' }
      ]
    };
    window.postMessage({message: 'EDITOR_CALL_REMOTE_ACTION', actions: {actions}}, '*');
  }

  onChangeCard = (field, value) => {
    const { props, context } = this.props;
    if (!props || !context) return null;
    const target = props.value || props['@value'];
    const message = { type: '', content: '' };
    Debounced.start(`update-${target}-${field}`, () => {
      const streamName = `${target}.${field}`;
      setValue(streamName, value, context);
      const { card, swipe } = this.state;
      card[field] = value;
      if (swipe.active) this.onCancelSwipe();
      this.setState({ card, message });
      const cardValidation = doCardValidator(card);
      this.setState({ validation: cardValidation });
      setValue('g:cardValidation', cardValidation, context);
    }, 250);
  };

  onChangeNumber = (val) => {
    const { card } = this.state;
    const { cardNumber = '' } = card;
    const value = val.replace(/\D/g, '');
    this.onChangeCard('cardNumber', value);
    this.onChangeCard('cardType', validateCard(value));
    if (cardNumber.toString().length < val.toString().length) {
      const el = document.getElementById('cardNumber');
      if (el) {
        el.value = formatCardNumber(value);
      }
    }
  };

  /* START SWIPER */

  onChangeSwipe = (field, value) => {
    const { swipe } = this.state;
    swipe[field] = value;
    this.setState({ swipe });
  };

  onStartSwipe = () => {
    const { swipe } = this.state;
    swipe.active = true;
    swipe.data = '';
    const message = { type: '', content: '' };
    this.setState({ swipe, message });
    setTimeout(() => {
      if (document.getElementById('focusedInput')) document.getElementById('focusedInput').focus(); // eslint-disable-line
    }, 50);
  };

  onEndSwipe = () => {
    const { swipe } = this.state;
    swipe.active = false;
    this.setState({ swipe });
    this.decodeSwipe();
  };

  decodeSwipe = () => {
    const { swipe: { data = '' } = {} } = this.state;

    const parts = data.substr(2).split('^');
    if (parts.length > 1) {
      const swipe = getDecodeSwipe(data);
      this.setState({ swipe });
      this.onChangeCard('trackData', data);
    }

    if (data.substr(0, 2) !== '%B' || parts.length <= 1) {
      const message = {
        type: 'danger',
        content: 'Card reader can not recognize this card. ' +
        'Please try again or cancel and enter using keyboard manually.'
      };
      this.setState({ message });
    }
  };

  onCancelSwipe = () => {
    const swipe = { ...defaultSwipe };
    this.setState({ swipe });
    this.onChangeCard('trackData', '');
  };

  /* END SWIPER */

  render() {
    const { props, context } = this.props;
    const { onChangeNumber, onChangeCard } = this;
    const processorError = getWritableValue('g:validation.processor', context, '') || '';
    const showSwipe = getValue(props, 'swipeCard', context);
    const appearanceCard = getValue(props, 'appearanceCard', context) || 'fancy';
    const { card = {}, swipe = {}, message, payment } = this.state;
    const { validation = {}, encryptedManifest = '' } = this.state;
    const { cardHolder, cardNumber } = card;

    const hasTransactionResult = Object.keys(payment).length;
    const procSwipe = swipe && swipe.card && !isEmptyObject(swipe.card);
    const hasSwipeCard = procSwipe || swipe.active;
    const isEnteringManually = cardNumber || cardHolder;
    const isSwipe = showSwipe && !hasTransactionResult;
    const swipeProps = {
      onChangeSwipe: this.onChangeSwipe,
      onCancelSwipe: this.onCancelSwipe,
      onStartSwipe: this.onStartSwipe,
      onEndSwipe: this.onEndSwipe
    };

    const viewCard = {
      fancy: FancyCreditCard,
      v1: FormCreditCard
    };

    const children = props.container ? renderChildren({ items: props.container, props, context }) : false;
    return (
      <Card className={`id-${props._id} ${props.classes}`} style={this.props.styles} ref={el => this.element = el}>
        {
          viewCard[appearanceCard] && !hasSwipeCard && !hasTransactionResult ?
          viewCard[appearanceCard](
            { ...{ ...card, cardValidation: validation, encryptedManifest, onChangeNumber, onChangeCard, children } }
          )
          : null
        }
        {
          isEnteringManually ?
            null :
            <div style={stylesList.textCenter}>
              {isSwipe ? <SwipeForm {...swipe} {...swipeProps} /> : null}
              {message.content ? <MessageBox {...message} /> : null}
            </div>
        }
        {processorError ? (
          <div style={{ marginTop: '15px' }}>
            <MessageBox { ...{ type: 'danger', content: processorError }} />
          </div>
        ) : null}
      </Card>
    );
  }
}

export default PaymentCreditCardContainer;
