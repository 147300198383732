import { generateTableElement, getObjectOfTextElements } from './PdfElements';
import { getMoneyString, getStyles } from '../PaymentTypeHelpers';

export const getElement = ({ showDonation, status = '', amountDue = 0, grandTotal = 0, styles, isTplLarge }) => {
  if (!showDonation) {
    const stylesTotals = getStyles(styles, isTplLarge).totals;
    const labels = {
      amountDueString: 'Amount Due:'
    };
    const values = {
      amountDueString: status === 'hold' ? getMoneyString(grandTotal) : getMoneyString(amountDue)
    };
    const stylesLabels = Object.keys(labels).reduce((prev, curr) => {
      return { ...prev, [curr]: stylesTotals.label };
    }, {});

    const stylesValues = Object.keys(values).reduce((prev, curr) => {
      return { ...prev, [curr]: stylesTotals.value };
    }, {});

    const objectLabels = getObjectOfTextElements(labels, stylesLabels);
    const objectValues = getObjectOfTextElements(values, stylesValues);

    const params = {
      headers: objectLabels,
      values: objectValues,
      ...stylesTotals.table
    };

    return generateTableElement(params);
  }
  return null;
};
