import { fixedTo2, isDigit } from './GeneralHelpers';
import { isString, isEmptyString, isValue } from './VariableType';
import { getMoneyString, refundAliases, noChargeAlises } from './PaymentTypeHelpers';

const reservedPaymentTypes = [
  {
    id: 'cash',
    name: 'Cash',
    isActive: true,
    reserved: true
  },
  {
    id: 'check',
    name: 'Check',
    isActive: true,
    reserved: true
  },
  {
    id: 'giftCard',
    name: 'Gift Card',
    isActive: true,
    reserved: true
  },
  {
    id: 'credit',
    name: 'Customer Credit',
    isActive: true,
    reserved: true
  },
  {
    id: 'customerPoints',
    name: 'Customer Points',
    isActive: false,
    reserved: true
  },
  {
    id: 'employeePayrollDeduct',
    name: 'Employee Payroll Deduct',
    isActive: false,
    reserved: true
  }
];

const init = {
  change: 'Change',
  creditCard: 'Credit Card',
  refundCash: 'Cash Refund',
  payrollDeductRefund: 'Payroll Deduct Refund'
};

export const transactionPaymentTypes = (arr = []) => (
  [...reservedPaymentTypes, ...arr].reduce((res, curr) => {
    if (curr.id && curr.name) {
      return { ...res, [curr.id]: curr.name };
    }
    return res;
  }, init)
);

export const getTranAuthCode = ({ authorizationCode, authCode, authcode, result = {} }) => {
  const resultValue = Object.keys(result).length ? getTranAuthCode(result) : false;
  return authorizationCode || authCode || authcode || resultValue || false;
};

export const getTranAmount = ({ amount, purchase, transactionData = {} }) => {
  const { amount: value } = transactionData;
  return amount || purchase || value || 0;
};

const adjustValue = (type, amount) => {
  const isNegative = type && (refundAliases.includes(type.toLowerCase()) || parseFloat(amount) < 0);
  return !isNegative ? amount : (-1) * parseFloat(Math.abs(amount));
};

const maskRefnum = (val) => {
  const value = isValue(val) ? val.toString() : val;
  if (isString(value) && !isEmptyString(value) && value.length > 8 && !noChargeAlises.includes(value)) {
    return value.substring(0, 4) + '...' + value.substring(value.length - 4, value.length);
  }
  return val;
};

const parseEMVData = (EMVData) => {
  if (isString(EMVData) && !isEmptyString(EMVData)) {
    const arr = EMVData.split(',');
    return arr.reduce((res, curr) => {
      const [key, value] = curr.split('=');
      if (isString(key) && !isEmptyString(key) && isString(value) && !isEmptyString(value)) {
        return { ...res, [key]: value };
      }
      return res;
    }, {});
  }
  return {};
};

export const getTransactionProperties = transactions => (transaction) => {
  const properties = [];
  const {
    totalAmt,
    amount,
    SVC = 0,
    fee = 0,
    SHFee = 0,
    type = false,
    refId = false,
    refNo = false,
    refnum = false,
    EMVData = false,
    transactionId = false,
    extData: { APPLAB = '' } = {}
} = transaction;

  const authCode = getTranAuthCode(transaction);
  const { AID = '', AppName = '', TVR = '', TSI = '' } = parseEMVData(EMVData);
  const allTypes = transactionPaymentTypes();
  properties.push(
    {
      title: allTypes[type] || type,
      value: getMoneyString(adjustValue(type, amount || totalAmt))
    },
    {
      title: 'Transaction Id',
      value: transactionId
    },
    {
      title: 'Refnum',
      value: maskRefnum(refnum || refNo)
    },
    {
      title: 'RefId',
      value: maskRefnum(refId)
    },
    {
      title: 'Auth Code',
      value: authCode
    },
    {
      title: 'App Name',
      value: AppName || APPLAB
    },
    {
      title: 'AID',
      value: AID
    },
    {
      title: 'TVR',
      value: TVR
    },
    {
      title: 'TSI',
      value: TSI
    }
  );

  if (parseFloat(SVC) > 0) {
    properties.push(
      {
        title: 'Surcharge',
        value: getMoneyString(parseFloat(SVC))
      }
    );
  }
  if (parseFloat(fee) > 0) {
    properties.push(
      {
        title: 'Fee',
        value: getMoneyString(parseFloat(fee))
      }
    );
  }
  if (parseFloat(SHFee) > 0) {
    properties.push(
      {
        title: 'Service and Handling Fee',
        value: getMoneyString(parseFloat(SHFee))
      }
    );
  }
  return properties;
};

export const getMaxRefund = (payment) => {
  const { transactions = [], amount = 0 } = payment;
  const initValue = parseFloat(amount);
  if (!transactions.length) return fixedTo2(initValue);
  const max = transactions.reduce((result, transaction) => {
    const { type } = transaction;
    const val = getTranAmount(transaction) || 0;
    if (type && refundAliases.includes(type.toLowerCase())) {
      result -= parseFloat(val);  // eslint-disable-line
    }
    return result;
  }, initValue);
  return fixedTo2(max);
};

export const getTransactionHistoryRequest = (request, processor, transaction) => {
  const tranRequest = {
    processor,
    request,
    result: transaction
  };

  const isDeclined = ['dejavoo', 'datacap', 'worldpay', 'usaepay']
    .reduce((res, curr) => {
      if (curr === processor) {
        if (curr === 'worldpay') {
          const { responseText = '', responseCode } = transaction;
          tranRequest.status = responseText;
          return isDigit(responseCode) && +responseCode !== 1;
        }
        if (curr === 'dejavoo') {
          const { respMSG = '', resultCode } = transaction;
          tranRequest.status = respMSG;
          return isDigit(resultCode) && +resultCode !== 0;
        }
        if (curr === 'usaepay') {
          const { result = '' } = transaction;
          tranRequest.status = result;
          if (isString(result) && !isEmptyString(result)) {
            return (result.toLowerCase() !== 'approved');
          }
        }
        if (curr === 'datacap') {
          const { textResponse = '', DSIXReturnCode = '' } = transaction;
          tranRequest.status = textResponse;
          return DSIXReturnCode && DSIXReturnCode !== '000000';
        }
      }
      return res;
    }, false);
  return isDeclined ? tranRequest : {};
};
