/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isString = (variable) => {
  return (typeof variable === 'string') || (variable instanceof String);
};

export const isEmptyString = (variable) => {
  return isString(variable) && !variable.trim();
};

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isNumber = (variable) => {
  return (typeof variable === 'number') || (variable instanceof Number);
}

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isBoolean = (variable) => {
  return typeof variable === 'boolean';
}

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isArray = (variable) => {
  return Array.isArray(variable);
}

export const isEmptyArray = (variable) => {
  return isArray(variable) && !variable.length;
}

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isObject = (variable) => {
  return !isNull(variable) && !isArray(variable) && !isString(variable)
    && !isNumber(variable) && (typeof variable === 'object');
}

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isEmptyObject = (variable) => {
  return isObject(variable) && (Object.keys(variable).length === 0);
}

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isNull = (variable) => {
  return variable === null;
}

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isUndefined = (variable) => {
  return variable === undefined || typeof variable === 'undefined';
}

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isValue = (variable) => {
  return !isUndefined(variable) && !isNull(variable);
}
