import React, {Component} from 'react';
import ec from 'ec-react15-lib';

import {capitalizeTxt, getSafeAmount, onAmountInput, toFixedFloat2} from '../../../services/GeneralHelpers';
import {getConvenienceFee, useConvenienceFee} from '../../../services/ConvenienceFeeHelpers';
import * as variableType from '../../../services/VariableType';
import {Addon, Label, Value} from '../controls/StyledDiv';
import TextInput from '../controls/TextInput';

const {getStyling, setValue, getWritableValue, TextInput: TextInputEc, Dropdown} = ec;
const {isArray, isEmptyArray, isString, isEmptyString, isEmptyObject, isObject, isValue} = variableType;

const stylesList = {
  hint: {display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center'},
  warn: {border: '1px solid red'}
};

const calcAmount = (items) => {
  return items.reduce((sum, curr) => {
    const {field, type} = curr;
    if (type && type === 'money') {
      const safeValue = getSafeAmount(field);
      if (safeValue) {
        return parseFloat(sum) + parseFloat(safeValue);
      }
    }
    return sum;
  }, 0);
};

const getOptions = list => ([{label: '- Select -', value: ''}, ...list.map(label => ({label, value: label}))]);

class CustomFieldsContainer extends Component {

  state = {employeeNum: ''}

  onChange = (id, unsafeValue, context) => {
    const customFields = getWritableValue('g:object.customFields', context, []) || [];
    const index = customFields.findIndex(o => o.id === id);
    if (index > -1) {
      if (id === 'employeeNum') {
        this.setState({employeeNum: unsafeValue});
      }
      const object = customFields[index];
      const value = object.type && object.type === 'money' ? getSafeAmount(unsafeValue) : unsafeValue;
      object.field = value;
      const {customer} = object;
      if (customer) {
        const extraArray = getWritableValue('g:object.customer.customFields', context, []) || [];
        const extraObject = extraArray.find(o => o.id === id);
        if (isObject(extraObject) && !isEmptyObject(extraObject)) {
          extraObject.field = value;
        } else {
          extraArray.push(object);
        }
        setValue('g:object.customer.customFields', extraArray, context);
      }
      const newExtraFields = [...customFields];
      newExtraFields[index] = object;
      setValue('g:object.customFields', newExtraFields, context);

      const shouldCalcAmount = customFields.some(o => o.type && o.type === 'money');
      if (shouldCalcAmount) {
        const amount = calcAmount(customFields);
        this.onChangeTotals(amount, context);
      }
    }
  };
  onChangeTotals = (value, context) => {
    const applyFee = getWritableValue('g:useConvenienceFee', context, 0) || 0;
    const convenienceFeePercentage = getWritableValue('g:convenienceFeePercentage', context, 0);
    const convenienceFeeDollar = getWritableValue('g:convenienceFeeDollar', context, 0);
    const convenienceMinTotal = getWritableValue('g:convenienceMinTotal', context, 0);
    const convenienceMaxTotal = getWritableValue('g:convenienceMaxTotal', context, 0);

    if (applyFee) {
      if (useConvenienceFee(value, convenienceMinTotal, convenienceMaxTotal)) {
        const fee = getConvenienceFee(value, convenienceFeeDollar, convenienceFeePercentage);
        const totalAmount = parseFloat(value) + parseFloat(fee);
        setValue('g:object.payment.convenienceFee', +toFixedFloat2(fee), context);
        setValue('g:object.payment.amount', +toFixedFloat2(totalAmount), context);
      } else {
        setValue('g:object.payment.convenienceFee', 0, context);
        setValue('g:object.payment.amount', +toFixedFloat2(value), context);
      }
      setValue('g:object.payment.inputAmount', +toFixedFloat2(value), context);
    } else {
      setValue('g:object.payment.amount', +toFixedFloat2(value), context);
    }
  };

  componentWillReceiveProps(nextProps) {
    const prevEmployeeNum = this.state.employeeNum;
    const employeeNum = getWritableValue('g:object.customer.employeeNum', nextProps.context, '') || '';
    if (prevEmployeeNum !== employeeNum) {
      const customFields = getWritableValue('g:object.customFields', nextProps.context, []) || [];
      const shouldUpdate = customFields.find(o => o.id === 'employeeNum' && !o.field);
      if (shouldUpdate) {
        this.onChange('employeeNum', employeeNum, nextProps.context);
      }
    }
  }

  render() {
    const {index, props, context, pos, childIndex} = this.props;
    const sp = {props, context, pos, childIndex};
    const optional = ['container', 'position'];
    const {styles, classes} = getStyling({...sp, optional, styling: ['Block', 'Visibility']});

    if (styles === false) return null;

    const customFields = getWritableValue('g:object.customFields', context, []) || [];
    const validation = getWritableValue('g:validation', context, {}) || {};
    if (!isArray(customFields) || isEmptyArray(customFields)) return null;

    return (
      <div id='custom-fields' key={index} className={classes.join(' ')} style={styles}>
        {
          customFields.map((item) => {
            const {id, label, field, disabled, type = 'string', mandatory, options} = item;
            if (isValue(disabled) && disabled) return null;
            return (
              isString(label) && !isEmptyString(label) ? (
                <div key={id} className='form-group'>
                  <div style={{display: 'flex'}}>
                    <Label style={stylesList.hint}>
                      <span>{label}:&nbsp;</span>
                      {!mandatory ? (<span className='small'>(optional)</span>) : null}
                    </Label>
                    <Value>
                      {
                        type === 'money' ? (
                          <div style={{display: 'flex', flex: 1}}>
                            <Addon className='input-group-addon'>$</Addon>
                            <TextInput
                              value={field}
                              placeholder='0.00'
                              replace={onAmountInput}
                              onChange={value => this.onChange(id, value, context)}
                              className={`text-right id-input-customFields${capitalizeTxt(id)}`}
                            />
                          </div>
                        ) : (type === 'dropdown' && options && isArray(options) && !isEmptyArray(options) ? (
                          <div style={{width: '100%'}}>
                            <Dropdown
                              className={`id-input-customFields${capitalizeTxt(id)}`}
                              value={field}
                              options={getOptions(options)}
                              onChange={value => this.onChange(id, value, context)}
                            />
                          </div>
                        ) : (
                          <div style={{width: '100%'}}>
                            <TextInputEc
                              className={`id-input-customFields${capitalizeTxt(id)}`}
                              value={field}
                              placeholder={label}
                              onChange={value => this.onChange(id, value, context)}
                            />
                          </div>
                        ))
                      }
                    </Value>
                  </div>
                  {isString(id) && !isEmptyString(id) && validation[`customFields${capitalizeTxt(id)}`] ? (
                    <div
                      style={{textAlign: 'right'}}
                      className='small text-danger'
                    >
                      {validation[`customFields${capitalizeTxt(id)}`]}
                    </div>
                  ) : null}
                </div>
              ) : null
            );
          })
        }
      </div>
    );
  }
}

export default CustomFieldsContainer;
