import React from 'react';
import ec from 'ec-react15-lib';
import NumberInput from '../controls/NumberInput';

const { setValue, Debounced, Logger, getWritableValue, getStyling, getValue, triggerAction } = ec;

export const InputNumber = ({ section, index, props, context, pos, childIndex }) => {
  Logger.of('render.InputNumber').info('section', section, 'index', index, 'props', props, 'pos=', pos);
  const sp = { props, context, pos, childIndex };
  const optional = ['value', 'readOnly', 'width', 'max', 'min', 'name', 'onChange', 'cb', 'debounce'];
  const { styles, classes } = getStyling({
    ...sp, mandatory: ['target'], optional, styling: ['Block', 'Text', 'Visibility']
  });
  if (styles === false) return false;
  const value = getWritableValue(props.target, context, '');
  const max = props.max ? getWritableValue(props.max, context, '') : Number.POSITIVE_INFINITY;
  const isReadOnly = getValue(props, 'readOnly', context, false) === 'true';
  const nDebouncedInterval = props.debounce || 250;
  const onChangeValue = (val) => {
    Debounced.start(`update-${props.target}`, () => {
      setValue(props.target, val, context);
      if (props.onChange) {
        triggerAction(props.onChange, context);
      }
    }, nDebouncedInterval);
  };
  return (
    <NumberInput
      style={styles}
      readOnly={isReadOnly}
      className={classes.join(' ')}
      value={value}
      name={props.name}
      onChange={onChangeValue}
      max={max}
      min={props.min}
    />
  );
};

export default { InputNumber };
