import { isArray, isObject, isString, isValue, isEmptyArray, isEmptyString, isEmptyObject } from './VariableType';

/**
 * Expect input only Object!
 * hardRequirementError: checking that EVERY object's values are NOT empty
 * softRequirementError: checking that ONE of object's values are NOT empty
 */

const getSeparator = {
  and: ', ',
  or: ' or '
};

const getEmptyObjectValues = ({ mode = 'and', humanization = {}, object }) => {
  if (isArray(object) && isEmptyArray(object)) return 'Empty Array';
  if ((isString(object) && object.trim()) || (isArray(object) && !isEmptyArray(object))) return '';
  if (!isObject(object) || !isValue(object)) return 'value';

  const arrInvalidArgs = Object.keys(object)
    .reduce((prev, curr) => {
      const item = object[curr];
      if (!item) {
        prev.push(curr);
      } else if (isEmptyString(item)) {
        prev.push(curr);
      } else if (isEmptyArray(item)) {
        prev.push(curr);
      } else if (isObject(item) && !isEmptyObject(item) && !isNaN(new Date(item).getTime())) {
        Object.keys(item).forEach(key => {
          if (!item[key]) prev.push(key);
          if (isEmptyString(item[key])) {
            prev.push(key);
          }
        });
      }
      return prev;
    }, []);

  if (isEmptyArray(arrInvalidArgs)) return '';
  if ((mode === 'or' && Object.keys(object).length > arrInvalidArgs.length)) return '';

  const names = arrInvalidArgs.map(key => humanization[key] || key);
  return names.join(getSeparator[mode] || ', ');
};

const getMessageObject = (props) => {
  const stringMessage = getEmptyObjectValues({ ...props });
  return stringMessage ? { type: 'danger', content: `Please provide ${stringMessage}`} : '';
};

export const isNumberType = value => (!isNaN(value) ? !!/\d/.test(value) : '');

export const hardRequirementError = (object, humanization) => {
  const props = { mode: 'and', object, humanization };
  return getMessageObject(props);
};

export const softRequirementError = (object, humanization) => {
  const props = { mode: 'or', object, humanization };
  return getMessageObject(props);
};
