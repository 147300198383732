import { getWritableValue } from 'ec-react15-lib';

import * as pdfElements from '../../services/pdf';
import { getSpace } from '../../services/pdf/PdfElements';
import { getLocalFormat } from '../../services/DateHelpers';
import { isObject, isEmptyObject } from '../../services/VariableType';
import { addSuffix, getJoinObject } from '../../services/GeneralHelpers';
import { softRequirementError, hardRequirementError } from '../../services/ValidatorHelpers';
import { fixLengthText, getAddressString, getCompanyString, getEmailsString, getNameString, getPhonesString }
  from '../../services/PaymentTypeHelpers';

const PdfHeader = ({ gen, props, context }) => {
  const defaultTpl = getWritableValue('g:ecOptions.merchantOptions.receipt.preview', context) || false;
  const isTplLarge = defaultTpl === 'A4' || !defaultTpl;
  const styles = gen.getStyling(props, context);
  if (!styles || !context) return false;

  const { docs: { doc = {} } = {} } = context;
  const { notes = false, station = false, customer = false, user = false, dateCreated = false, store = false,
    accountNumber = false, terminalId = false, shipping = false } = doc;

  const customerName = getNameString(customer) || '';
  const customerCompany = getCompanyString(customer) || '';
  const customerPhones = getPhonesString(customer) || '';
  const customerEmails = getEmailsString(customer) || '';
  const customerAddress = getAddressString(customer) || '';

  const shippingSubject = {};

  if (isObject(shipping) && !isEmptyObject(shipping)) {
    shippingSubject.name = getNameString(shipping) || '';
    shippingSubject.company = getCompanyString(shipping) || '';
    shippingSubject.phones = getPhonesString(shipping) || '';
    shippingSubject.emails = getEmailsString(shipping) || '';
    shippingSubject.address = getAddressString(shipping) || '';
  }

  const userName = getNameString(user) || '';
  const userPhones = getPhonesString(user) || '';
  const userEmails = getEmailsString(user) || '';

  const accountNum = accountNumber ? fixLengthText(accountNumber) : '';
  const dateCreatedLocal = !hardRequirementError(dateCreated) ? getLocalFormat(dateCreated, 'MM/DD/YY, h:mm A') : '';
  const stationName = !softRequirementError(station) && station.stationName ? station.stationName : '';
  const storeName = !softRequirementError(store) && store.name ? store.name : '';
  const notesDetail = getJoinObject(notes, ', ');

  const labels = {
    customerName: 'Customer:',
    customerCompany: 'Company:',
    customerPhones: `Phone${addSuffix(customer ? customer.phones : '')}:`,
    customerEmails: `Email${addSuffix(customer ? customer.emails : '')}:`,
    customerAddress: 'Address:',

    shippingSubjectName: 'Shipping Customer:',
    shippingSubjectCompany: 'Shipping Company:',
    shippingSubjectPhones: `Shipping Phone${addSuffix(shippingSubject.phones || '')}:`,
    shippingSubjectEmails: `Shipping Email${addSuffix(shippingSubject.emails || '')}:`,
    shippingSubjectAddress: 'Shipping Address:',

    userName: 'Cashier:',
    userPhones: `Phone${addSuffix(user ? user.phones : '')}:`,
    userEmails: `Email${addSuffix(user ? user.emails : '')}:`,
    accountNum: 'Account Number:',
    matchCode: 'Match Code:',
    terminalId: 'Terminal ID:',
    stationName: 'Station:',
    storeName: 'Store:',
    notes: 'Message:',
    dateCreatedLocal: 'Date:'
  };
  const values = {
    customerName,
    customerCompany,
    customerPhones,
    customerEmails,
    customerAddress,

    shippingSubjectName: shippingSubject.name,
    shippingSubjectCompany: shippingSubject.company,
    shippingSubjectPhones: shippingSubject.phones,
    shippingSubjectEmails: shippingSubject.emails,
    shippingSubjectAddress: shippingSubject.address,

    userName,
    userPhones,
    userEmails,
    accountNum,
    storeName,
    stationName,
    terminalId,
    notes: notesDetail,
    dateCreatedLocal
  };

  if (pdfElements['Header']) {
    const element = pdfElements['Header'].getElement({ labels, values, isTplLarge, styles });

    gen.addElement(getSpace('0.5'));
    gen.addElement(element);
  }
  return false;
};

export default PdfHeader;
