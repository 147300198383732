import * as proc from '../../config/processors';
const { stationProperties } = proc;

export const getPoslinkParamsByCommType = station => {
  const { commType = '' } = station;
  const props = stationProperties['poslink'];
  if (commType === 'UART') {
    return props.filter(o => !['destIP', 'destPort'].includes(o.name)) || [];
  }
  if (['TCP', 'HTTP', 'HTTPS'].includes(commType)) {
    return props.filter(o => !['serialPort', 'baudRate'].includes(o.name)) || []
  }
  return props.filter(o => !['destIP', 'destPort', 'serialPort', 'baudRate'].includes(o.name)) || []
};

export const getActualPoslinkParams = (station) => {
  const { commType = '' } = station;
  if (commType === 'UART') {
    const { destIP = '', destPort = '', ...device} = station; // eslint-disable-line
    return device;
  }
  if (['TCP', 'HTTP', 'HTTPS'].includes(commType)) {
    const { serialPort = '', baudRate = '', ...device} = station; // eslint-disable-line
    return device;
  }
  const { destIP = '', destPort = '', serialPort = '', baudRate = '', ...device} = station; // eslint-disable-line
  return device;
};
