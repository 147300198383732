import React from 'react';
import ec from 'ec-react15-lib';
import { getAvailabilityInStore, isAvailabilityProduct } from '../../../services/CartHelpers';

const { getValue, getStyling, renderChildren } = ec;

const ProductContainer = ({ index, props, context, pos, childIndex }) => {
  const sp = { props, context, pos, childIndex };
  const { styles, classes } = getStyling({
    ...sp, optional: ['container', 'repeatable', 'position', 'source', 'store'], styling: ['Block', 'Visibility']
  });
  if (styles === false) return false;

  const { doc } = getValue(props, 'source', context) || {};
  if (!doc) return null;
  const store = getValue(props, 'store', context) || {};
  const { store: { doc: { negativeInventory = false } = {} } = {} } = doc;
  const onHand = getAvailabilityInStore(doc.onHand, store.name);
  const isActive = isAvailabilityProduct(onHand, doc.infinity, negativeInventory);
  const newRow = { ...context.row, isActive };
  const newContext = { ...context, row: newRow };
  const children = props.container ? renderChildren({ items: props.container, props, context: newContext }) : false;
  return (
    <div key={index} className={classes.join(' ')} style={styles}>{children}</div>
  );
};

export default ProductContainer;
