import { getWritableValue } from 'ec-react15-lib';

import * as pdfElements from '../../services/pdf';
import { getSpace } from '../../services/pdf/PdfElements';
import { getLocalFormat } from '../../services/DateHelpers';
import { getCustomerString } from '../../services/PaymentTypeHelpers';
import { softRequirementError, hardRequirementError } from '../../services/ValidatorHelpers';

const PdfHeader = ({ gen, props, context }) => {
  const defaultTpl = getWritableValue('g:ecOptions.merchantOptions.receipt.preview', context) || false;
  const isTplLarge = defaultTpl === 'A4' || !defaultTpl;
  const styles = gen.getStyling(props, context);
  if (!styles || !context) return false;

  const { docs: { doc = {} } = {} } = context;
  const { notes = false, station = false, customer = false, dateCreated = false, store = false, terminalId = false } = doc;

  const customerDetail = getCustomerString(customer) || '';
  const dateCreatedLocal = !hardRequirementError(dateCreated) ? getLocalFormat(dateCreated, 'MM/DD/YY, h:mm A') : '';
  const matchCode = !softRequirementError(notes) && notes.matchCode ? notes.matchCode : '';
  const stationName = !softRequirementError(station) && station.stationName ? station.stationName : '';
  const storeName = !softRequirementError(store) && store.name ? store.name : '';
  const type = !softRequirementError(notes) && notes.type ? notes.type : '';
  const subject = !softRequirementError(notes) && notes.subject ? notes.subject : '';

  const labels = {
    customerDetail: 'Customer:',
    matchCode: 'Match Code:',
    terminalId: 'Terminal ID:',
    stationName: 'Station:',
    storeName: 'Store:',
    subject: type,
    dateCreatedLocal: 'Date:'
  };
  const values = { customerDetail, storeName, stationName, terminalId, subject, matchCode, dateCreatedLocal };

  if (pdfElements['Header']) {
    const element = pdfElements['Header'].getElement({ labels, values, isTplLarge, styles });
    gen.addElement(getSpace('0.5'));
    gen.addElement(element);
  }
  return false;
};
export default PdfHeader;
