import React, { Component } from 'react';
import PropTypes from 'prop-types';

const getValidValue = (value, props) => {
  let validValue = +value || 0;

  if (isNaN(validValue)) {
    validValue = '';
  }

  if (validValue < props.min) {
    validValue = props.min;
  }

  if (validValue > props.max) {
    validValue = props.max;
  }

  return validValue;
};

class NumberInput extends Component {
  state = { value: getValidValue(this.props.value, this.props) };
  oldValue = this.state.value;
  isFocused = false;

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      if (!this.isFocused) {
        this.setState({ value: getValidValue(nextProps.value, nextProps) });
      }
    }
  }

  onFocus = () => {
    this.isFocused = true;
  };

  blurHandler = () => {
    let newValue = 0;
    if (this.state.value) {
      newValue = this.state.value;
    }

    let isFixed = false;
    if (newValue < this.props.min || this.props.max < newValue) {
      newValue = Math.max(this.props.min, Math.min(newValue, this.props.max));
      isFixed = true;
    }

    if (this.oldValue !== newValue || isFixed) {
      if (newValue > 0) {
        this.oldValue = newValue;
      }
      this.setState({ value: +newValue });
      this.oldValue = newValue;
      this.props.onChange(+newValue);
    }
    this.isFocused = false;
  };

  changeHandler = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/,/g, '.');
    newValue = newValue.replace(/-/g, '');
    if (!isNaN(newValue)) {
      if (newValue.length > 0) {
        this.setState({ value: newValue });
        if (newValue <= this.props.max && newValue > this.props.min) {
          if (!Number.isInteger(newValue)) newValue = parseFloat(newValue).toFixed(2);
          this.props.onChange(+newValue);
        }
      } else {
        this.setState({ value: '' });
      }
    }
  };

  handleKeyPress = (e) => {
    if (e.which === 13 && typeof this.props.onEnter === 'function') {
      this.props.onEnter();
    }
    if (e.which === 27 && typeof this.props.onEsc === 'function') {
      this.props.onEsc();
    }
  };

  render() {
    return (
      <div style={this.props.innerStyles || {}}>
        <input
          id={this.props.id}
          name={this.props.name}
          value={this.state.value}
          disabled={this.props.disabled}
          onChange={this.changeHandler}
          onFocus={this.onFocus}
          onBlur={this.blurHandler}
          onKeyPress={this.handleKeyPress}
          readOnly={this.props.readOnly}
          className={`form-control ${this.props.className}`}
          style={this.props.style}
        />
      </div>
    );
  }
}

NumberInput.defaultProps = {
  id: '',
  name: '',
  value: 0,
  style: {},
  className: '',
  readOnly: false,
  disabled: false,
  min: Number.NEGATIVE_INFINITY,
  max: Number.POSITIVE_INFINITY
};

NumberInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default NumberInput;
