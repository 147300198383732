import { Currency, PaymentType, InputNumber, PaymentForm, ProductContainer, PaymentCreditCard, BillingItems,
  TransactionAmount, ChargeButton, Location, Patients, BillingInformation, CustomFields, CustomPaymentType,
  ShippingInformation, CategoriesBrowse, ProductsBrowse, ImageDelimit, ProductsSearch } from './components/Editable';
import ButtonUseCoupon from './components/controls/ButtonUseCoupon';
import ItemOptions from './components/CartItem/ItemOptions';
import ItemSerials from './components/CartItem/ItemSerials';
import Modifiers from './components/CartItem/Modifiers';
import CartItem from './components/CartItem/CartItem';

export default {
  ShippingInformation,
  BillingInformation,
  CustomPaymentType,
  PaymentCreditCard,
  TransactionAmount,
  CategoriesBrowse,
  ProductContainer,
  ButtonUseCoupon,
  ProductsBrowse,
  ProductsSearch,
  BillingItems,
  CustomFields,
  ChargeButton,
  ImageDelimit,
  PaymentType,
  ItemSerials,
  ItemOptions,
  PaymentForm,
  InputNumber,
  Modifiers,
  Patients,
  CartItem,
  Location,
  Currency
};
