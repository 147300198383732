import React, { Component } from 'react';
import ec from 'ec-react15-lib';

import {isString, isEmptyString, isArray, isEmptyArray, isObject, isEmptyObject} from '../../../services/VariableType';
import { PaymentCreditCardContainer, ChargeContainer } from './CreditCard/index';
import {getDepartmentDetails} from '../../../services/DepartmentHelpers';
import AcceptCards from './CreditCard/AcceptCards';
import ACHContainer from "./ACH/ACHContainer";
import HCaptcha from "../controls/HCaptcha";

const { getValue, Logger, getStyling, getWritableValue, setValue } = ec;

const stylesL = {
  message: { width: '760px', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }
}

class PaymentCreditCard extends Component {

  componentWillMount() {
    const { props, context } = this.props;
    const showCharge = getValue(props, 'chargeStation', context);
    const acceptVisa = getValue(props, 'acceptVisa', context);
    const acceptDiscover = getValue(props, 'acceptDiscover', context);
    const acceptMastercard = getValue(props, 'acceptMastercard', context);
    const acceptAmex = getValue(props, 'acceptAmex', context);
    const showVisa = (isString(acceptVisa) && isEmptyString(acceptVisa)) || acceptVisa;
    const showDiscover = (isString(acceptDiscover) && isEmptyString(acceptDiscover)) || acceptDiscover;
    const showMastercard = (isString(acceptMastercard) && isEmptyString(acceptMastercard)) || acceptMastercard;
    const showAmex = (isString(acceptAmex) && isEmptyString(acceptAmex)) || acceptAmex;
    setValue('g:showVisa', showVisa, context);
    setValue('g:showDiscover', showDiscover, context);
    setValue('g:showMastercard', showMastercard, context);
    setValue('g:showAmex', showAmex, context);
    setValue('g:helpers.showCharge', showCharge, context);
  }

  handleVerificationSuccess = (token, ekey) => {
    const { context } = this.props;
    setValue('g:object.customer.captcha-response', token, context);
  };

  render() {
    const { section, index, props, context, pos, childIndex } = this.props;
    Logger.of('render.PaymentCreditCard').info('section', section, 'index', index, 'props', props, 'pos=', pos);
    const sp = { props, context, pos, childIndex };
    const optional = ['disabled', 'container', 'onSubmit', 'swipeCard', 'chargeStation', 'appearanceCard',
      'acceptVisa', 'acceptDiscover', 'acceptMastercard', 'acceptAmex'];
    const { styles, classes } = getStyling({
      ...sp, mandatory: ['value'], optional, styling: ['Block', 'Text', 'Visibility']
    });
    if (!styles) return null;
    const isFree = getWritableValue('g:object.payment.isFree', context, false) || false;
    if (isFree) return null;
    const showCharge = getValue(props, 'chargeStation', context);
    const acceptVisa = getValue(props, 'acceptVisa', context);
    const acceptDiscover = getValue(props, 'acceptDiscover', context);
    const acceptMastercard = getValue(props, 'acceptMastercard', context);
    const acceptAmex = getValue(props, 'acceptAmex', context);
    const station = getWritableValue('g:station', context, '');

    const showVisa = (isString(acceptVisa) && isEmptyString(acceptVisa)) || acceptVisa;
    const showDiscover = (isString(acceptDiscover) && isEmptyString(acceptDiscover)) || acceptDiscover;
    const showMastercard = (isString(acceptMastercard) && isEmptyString(acceptMastercard)) || acceptMastercard;
    const showAmex = (isString(acceptAmex) && isEmptyString(acceptAmex)) || acceptAmex;
    const cardType = !showCharge || !station ? getWritableValue('g:object.payment.cardType', context, '') : '';
    const cardToken = getWritableValue('g:object.payment.cardToken', context, '') || '';
    const usePaymentMethodDefault = cardToken ? 'useCardToken' : '';
    const usePaymentMethod = getWritableValue('g:helpers.usePaymentMethod', context) || usePaymentMethodDefault;


    const onlineDepartment = getWritableValue('docs:onlineDepartment', context, {}) || {};
    const onlineDepartmentType = isObject(onlineDepartment) && !isEmptyObject(onlineDepartment)
      && onlineDepartment.payments && onlineDepartment.payments.paymentEngine
      && onlineDepartment.payments.paymentEngine.type;

    const department = getWritableValue('g:department', context, '') || '';
    const store = getWritableValue('g:store', context, '') || '';
    const stationG = getWritableValue('g:station', context, '') || '';
    const captchaSiteKey = getWritableValue('g:captchaSiteKey', context, '') || '';
    const selected = [department, store, stationG].filter(v => v).join(' - ');
    const publicList = getWritableValue('docs:publicList', context, []) || [];
    const secureList = getWritableValue('docs:secureList', context, []) || [];
    const list = isArray(secureList) && !isEmptyArray(secureList) ? secureList : publicList;
    const object = list.find((o) => {
      const {department: d, store: s, stationName} = getDepartmentDetails(o);
      const current = [d, s, stationName].filter(v => v).join(' - ');
      return selected === current;
    });

    const achSelected = department
      ? list.find(o => o.department === department && o.paymentEngine === 'ach')
      : onlineDepartment ? onlineDepartmentType === 'ach' : false;

    const {paymentEngine = '', testMode = false} = object || {};

    Logger.of('render.PaymentCreditCard').info('showCharge', showCharge,
      'station', station, 'usePaymentMethod', usePaymentMethod);

    const paymentForm = usePaymentMethod && usePaymentMethod === 'useCardToken'
      ? null
      : <PaymentCreditCardContainer {...{ ...this.props, classes, styles }} />

    return (
      <div>
        {
          achSelected ? (
            <ACHContainer {...{ ...this.props, classes, styles }}/>
          ) : null
        }
        {
          showCharge && !!station ? (
            <ChargeContainer {...{ ...this.props, classes, styles }} />
          ) : achSelected ? null : (
            paymentForm
          )
        }
        {
          !(usePaymentMethod && usePaymentMethod === 'useCardToken') ? (
            <AcceptCards {...{ showVisa, showDiscover, showMastercard, showAmex, cardType }} />
          ) : null
        }
        {
          window.CAPTCHA_SITE_KEY ? (
            <div style={{
              textAlign: 'center',
              borderRadius: '4px',
              maxWidth: '760px',
              padding: '20px',
              margin: '0 auto'
            }}>
              <HCaptcha
                sitekey={captchaSiteKey}
                onVerify={this.handleVerificationSuccess}
              />
            </div>
          ) : null
        }
        {
          paymentEngine && paymentEngine === 'nocharge' ? (
            <div className='form-group alert alert-danger' style={stylesL.message}>
              Merchant charges are disabled
            </div>
          ) : null
        }
        {
          testMode && testMode !== 'false' ? (
            <div className='form-group alert alert-danger' style={stylesL.message}>
              Merchant is in test mode, there will be no real charge
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default PaymentCreditCard;
