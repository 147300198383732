import React, {Component} from 'react';
import ec from 'ec-react15-lib';
import styled from 'styled-components';

const { getValue, renderChildren, Logger, getStyling, setValue } = ec;

const Wrapper = styled.div`
  .imageBlock {
    position: relative;
    z-index: 0;
    float: left;

    &:before {
      content: '';
      left: 5px;
      width: 90%;
      z-index: -1;
      height: 10%;
      bottom: 15px;
      position: absolute;
      transform: rotate(-4deg);
      backface-visibility: hidden;
      box-shadow: 0 15px 6px rgba(0,0,0,0.3);
    }
  }
  .colorItem {
     background: ${props => props.color || '#fff'};
  }
  border-radius: 13px;
  background: #f5f5f5;
  border-color: black;
`;

class CartItem extends Component {

  componentWillUnmount() {
    const { context } = this.props;
    setValue('newOrder:itemsPreview', [], context);
  }

  render() {
    const { section, index, props, context, pos, childIndex } = this.props;
      Logger.of('render.CartItem').info('section', section, 'index', index, 'props', props, 'pos=', pos);
      const sp = { props, context, pos, childIndex };
      const optional = [];
      const mandatory = ['source', 'container'];
      const { styles, classes } = getStyling({ ...sp, mandatory, optional, styling: ['Block', 'Text', 'Visibility'] });
      if (styles === false) return false;
      const object = getValue(props, 'source', context) || {};
      const children = props.container ? renderChildren({ items: props.container, props, context }) : false;
      return (
        <Wrapper key={index} className={classes.join(' ')} style={styles}  color={object.color}>
          {children}
        </Wrapper>
      );
    };
  }

export default CartItem;
