import React, { Component } from 'react';
import styled from 'styled-components';
import ec from 'ec-react15-lib';

import { getConvenienceFee, useConvenienceFee } from '../../../services/ConvenienceFeeHelpers';
import { fixedTo2, isDigit, toFixedFloat2 } from '../../../services/GeneralHelpers';
import shallowEqualsObj from '../../../services/ShallowEqualsObj';
import Button from '../controls/Button';
import Icon from '../controls/Icon';

const { setValue, getWritableValue, getStyling } = ec;

const Block = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-bottom: 15px;
  justify-content: space-evenly;
  button {
    line-height: 1.5rem;
    width: 300px;
    margin: 15px;
  }
`;

const options = [
  {
    label: 'Make a donation',
    value: 'donation',
    icon: 'heart',
    description: 'can choose any amount'
  },
  {
    label: 'Buy a Brick',
    value: 'brick',
    price: '100',
    icon: 'cubes',
    description: '$100 per brick'
  }
];

class CustomPaymentType extends Component {

  state = { item: {} };

  componentWillUnmount() {
    const { context } = this.props;
    setValue('g:defaultPaymentType', '', context);
  }

  componentWillReceiveProps(nextProps) {
    const { context } = nextProps;
    this.onToggleItem(context);
  }

  onSelect = (op) => {
    const { value } = op;
    const { context } = this.props;
    setValue('g:defaultPaymentType', value, context);
  };

  onToggleItem = (context) => {
    const { item: prevItem = [] } = this.state;
    const object = getWritableValue('g:object', context);
    const defaultPaymentType = getWritableValue('g:defaultPaymentType', context);
    const prevDefaultPaymentType = getWritableValue('g:defaultPaymentType', this.props.context);

    if (defaultPaymentType !== prevDefaultPaymentType) {
      if (defaultPaymentType === 'donation') {
        setValue('g:object.item', { isService: true }, context);
        setValue('g:object.payment.amount', 0, context);
      } else {
        const { price = 0 } = options.find(o => o.value === defaultPaymentType);
        setValue('g:object.item', { isService: true, price }, context);
        setValue('g:object.payment.amount', 0, context);
      }
    }
    const item = { ...(object.item || {}) };

    if (defaultPaymentType !== 'donation' && !shallowEqualsObj(prevItem, item, shallowEqualsObj)) {
      const { price = 0, quantity = 0 } = item;
      if (isDigit(quantity) && isDigit(price) && parseFloat(price) >= 0 && parseFloat(quantity) >= 0) {
        const newAmount = fixedTo2((parseFloat(price) * parseFloat(quantity)));
        this.onChangeTotals(newAmount);
      } else {
        this.onChangeTotals(0);
      }
      this.setState({ item });
    }
  };

  onChangeTotals = (value) => {
    const { context } = this.props;
    const applyFee = getWritableValue('g:useConvenienceFee', context, 0) || 0;
    const convenienceFeePercentage = getWritableValue('g:convenienceFeePercentage', context, 0);
    const convenienceFeeDollar = getWritableValue('g:convenienceFeeDollar', context, 0);
    const convenienceMinTotal = getWritableValue('g:convenienceMinTotal', context, 0);
    const convenienceMaxTotal = getWritableValue('g:convenienceMaxTotal', context, 0);

    if (applyFee) {
      if (useConvenienceFee(value, convenienceMinTotal, convenienceMaxTotal)) {
        const fee = getConvenienceFee(value, convenienceFeeDollar, convenienceFeePercentage);
        const totalAmount = parseFloat(value) + parseFloat(fee);
        setValue('g:object.payment.convenienceFee', +toFixedFloat2(fee), context);
        setValue('g:object.payment.amount', +toFixedFloat2(totalAmount), context);
      } else {
        setValue('g:object.payment.convenienceFee', 0, context);
        setValue('g:object.payment.amount', +toFixedFloat2(value), context);
      }
      setValue('g:object.payment.inputAmount', +toFixedFloat2(value), context);
    } else {
      setValue('g:object.payment.amount', +toFixedFloat2(value), context);
    }
  };

  render() {
    const { index, props, context, pos, childIndex } = this.props;
    const sp = { props, context, pos, childIndex };
    const optional = ['container', 'position'];
    const { styles, classes } = getStyling({ ...sp, optional, styling: ['Block', 'Visibility'] });

    const alreadySelected = getWritableValue('g:defaultPaymentType', context, '');
    if (alreadySelected) return null;

    return (
      <div id='custom-payment-type' key={index} className={classes.join(' ')} style={styles}>
        <Block>
          {
            options.map(op => (
              <Button
                size='lg'
                type='info'
                key={op.value}
                outlines={false}
                onClick={_ => this.onSelect(op)}
              >
                <Icon src={`${op.icon}`} x={3} />
                <div style={{ marginTop: '30px' }}>{op.label}</div>
                <div>({op.description})</div>
              </Button>
            ))
          }
        </Block>
      </div>
    );
  }
}

export default CustomPaymentType;
