import React, {Component} from 'react';
import ec from 'ec-react15-lib';

import AddressForm from './AddressForm';
import {Header, Label, Value } from '../controls/StyledDiv';
import * as variableType from '../../../services/VariableType';
import shallowEqualsObj from '../../../services/ShallowEqualsObj';
import ShippingInformationContainer from './ShippingInformationContainer';

const {getStyling, setValue, getWritableValue, getValue, triggerAction, Debounced, Dropdown} = ec;
const {isString, isEmptyString, isArray, isEmptyArray} = variableType;

class BillingInformationContainer extends Component {

  state = {
    delivery: {},
    type: 'pickUp'
  }

  getValues = (field, value = '') => {
    const {context} = this.props;

    const line1 = getWritableValue('g:object.customer.address.line1', context, '') || '';
    const city = getWritableValue('g:object.customer.address.city', context, '') || '';
    const state = getWritableValue('g:object.customer.address.state', context, '') || '';
    const zip = getWritableValue('g:object.customer.address.zip', context, '') || '';
    const phones = getWritableValue('g:object.customer.phones', context, '') || '';
    const emails = getWritableValue('g:object.customer.emails', context, '') || '';
    const firstName = getWritableValue('g:object.customer.firstName', context, '') || '';
    const lastName = getWritableValue('g:object.customer.lastName', context, '') || '';
    const businessName = getWritableValue('g:object.customer.businessName', context, '') || '';
    const customFields = getWritableValue('g:object.customer.customFields', context, '') || '';
    const user = getWritableValue('g:user', context, '') || '';
    const props = {line1, city, state, zip, phones, emails, firstName, lastName, businessName, user, customFields};
    return (isString(field) && !isEmptyString(field)) ? {...props, [field]: value} : props;
  };
  onChangeCustomer = (field, value) => {
    const {context} = this.props;
    const object = getWritableValue('g:object', context, {}) || {};
    const {customer = {}} = object;
    const newCustomer = {...customer, [field]: value};
    const newObject = {...object, customer: newCustomer};
    setValue('g:object', newObject, context);
  };
  onChangeAddress = (field, value) => {
    const {context} = this.props;
    const object = getWritableValue('g:object', context, {}) || {};
    const {customer: {address = {}} = {}} = object;
    const newAddress = {...address, [field]: value};
    const newObject = {...object, customer: {...object.customer, address: newAddress}};
    setValue('g:object', newObject, context);
  };
  onChangeDeliveryType = (type) => {
    const {context} = this.props;
    this.setState({ type });
    if (type === 'pickUp') {
      triggerAction({
        actions: {
          dispatch: [
            {
              payload: {type},
              type: 'ORDER_SHIPPING_SET'
            }
          ],
          set: [
            {
              value: {type},
              target: "g:object.shipping.shipmentResults"
            }
          ]
        }
      }, context);
    } else {
      setValue('g:isMandatoryBillingInformation', true, context);
    }
  }

  onChangeState = (obj) => {
    const {value = '', country = ''} = obj;
    const {context} = this.props;
    const object = getWritableValue('g:object', context, {}) || {};
    const {customer: {address = {}} = {}} = object;
    const newAddress = {...address, state: value, country};
    const newObject = {...object, customer: {...object.customer, address: newAddress}};
    setValue('g:object', newObject, context);
  };

  componentWillMount() {
    const {context, props} = this.props;
    const isMandatoryEmail = getValue(props, 'isMandatoryEmail', context);
    setValue('g:isMandatoryEmail', !!isMandatoryEmail, context);
    const shippingOptions = getWritableValue('g:ecOptions.merchantOptions.shipping', context, {})
    const {enabled = false, types = []} = shippingOptions || {};
    const items = getWritableValue('g:object.items', context, []) || [];
    if (enabled && types.length && items.length) {
      const type = types.includes('fixed') || types.includes('dynamic') ? 'shipping' : 'pickUp';
      this.setState({ type });
      if (type === 'shipping') {
        setValue('g:isMandatoryBillingInformation', true, context);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const {context} = nextProps;
    const shippingOptions = getWritableValue('g:ecOptions.merchantOptions.shipping', context, {})
    const useShippingInformation = getWritableValue('g:useShippingInformation', context, '') || '';
    const customer = getWritableValue('g:object.customer', context, {}) || {};
    const shipping = getWritableValue('g:object.shipping', context, {}) || {};
    const store = getWritableValue('g:store', context, '') || '';
    const {address = {}, firstName, lastName} = useShippingInformation ? shipping : customer;
    const {line1, city, state = 'AL', zip} = address;
    const delivery = {type: this.state.type, store, firstName, lastName, line1, city, state, zip};
    const isValidAddress = [line1, city, zip].some(o => o);
    const items = getWritableValue('g:object.items', context, []) || [];

    const {enabled = false, types = [], dynamic = [], fixedCost = 0} = shippingOptions || {};
    Debounced.start(`update-shipping`, () => {
      if (this.state.type !== 'pickUp' && isValidAddress && items.length &&
          !shallowEqualsObj(delivery, this.state.delivery, shallowEqualsObj)) {
        console.log({ componentWillReceiveProps: 'update-shipping'});
        if (enabled && types.length) {
          if (types.includes('fixed')) {
            triggerAction({
              actions: {
                dispatch: [
                  {
                    payload: {fixedCost, type: 'fixed'},
                    type: 'ORDER_SHIPPING_SET'
                  }
                ],
                set: [
                  {
                    value: {fixedCost, type: 'fixed'},
                    target: "g:object.shipping.shipmentResults"
                  }
                ]
              }
            }, context);
            this.setState({delivery})
          } else if (types.includes('dynamic') && dynamic.length && dynamic.includes('ups')) {

            triggerAction({
              actions: {
                send: [
                  {
                    to: `shipping`,
                    method: 'POST',
                    errorTarget: 'g:errorMessage',
                    data: {
                      "@store": "g:store",
                      "@items": "g:object.items",
                      "@customer": !useShippingInformation ? "g:object.customer" : "g:object.shipping"
                    }
                  }
                ]
              }
            }, context)
              .then(res => {
                if (isArray(res) && !isEmptyArray(res)) {
                  const [resp = []] = res;
                  const {response = []} = resp;
                  const {errors = []} = response;
                  const [error] = errors;
                  const {code = '', message = ''} = error || {};
                  if (message) {
                    const mes = code ? `${code}: ${message}` : message;
                    triggerAction({
                      actions: {
                        dispatch: [
                          {
                            payload: {error: mes},
                            type: 'ORDER_SHIPPING_SET'
                          }
                        ],
                        set: [
                          {
                            "value": {error: mes},
                            "target": "g:object.shipping.shipmentResults"
                          },
                          {
                            "value": mes,
                            "target": "g:errorMessage"
                          }
                        ]
                      }
                    }, context);
                  } else if (isArray(res) && !isEmptyArray(res)) {
                    const [response] = res;
                    const {ShipmentResponse: {ShipmentResults = {}} = {}} = response;

                    triggerAction({
                      actions: {
                        dispatch: [
                          {
                            payload: ShipmentResults,
                            type: 'ORDER_SHIPPING_SET'
                          }
                        ],
                        set: [
                          {
                            value: ShipmentResults,
                            target: "g:object.shipping.shipmentResults"
                          }
                        ]
                      }
                    }, context);
                  } else {
                    const {error} = res;
                    triggerAction({
                      actions: {
                        dispatch: [
                          {
                            payload: {error},
                            type: 'ORDER_SHIPPING_SET'
                          }
                        ],
                        set: [
                          {
                            "value": {error},
                            "target": "g:object.shipping.shipmentResults"
                          },
                          {
                            "value": error,
                            "target": "g:errorMessage"
                          }
                        ]
                      }
                    }, context);
                  }
                } else {
                  const {error} = res;
                  triggerAction({
                    actions: {
                      dispatch: [
                        {
                          payload: {error},
                          type: 'ORDER_SHIPPING_SET'
                        }
                      ],
                      set: [
                        {
                          "value": {error},
                          "target": "g:object.shipping.shipmentResults"
                        },
                        {
                          "value": error,
                          "target": "g:errorMessage"
                        }
                      ]
                    }
                  }, context);
                }
              });
          }
          this.setState({delivery})
        }
      }
    })
  }

  render() {
    const {index, props, context, pos, childIndex} = this.props;
    const sp = {props, context, pos, childIndex};
    const optional = ['container', 'position', 'isMandatoryEmail'];
    const {styles, classes} = getStyling({...sp, optional, styling: ['Block', 'Visibility']});
    if (styles === false) return null;

    const useShippingInformation = getWritableValue('g:useShippingInformation', context, '') || '';
    const validation = getWritableValue('g:validation', context, '') || '';
    const {onChangeAddress, onChangeCustomer, onChangeState} = this;

    const {line1, city, state, zip, phones, emails, user} = this.getValues();
    const bgHeader = getWritableValue('g:bgHeader', context, '') || '';
    const isMandatoryEmail = getValue(props, 'isMandatoryEmail', context);
    const items = getWritableValue('g:object.items', context, []) || [];
    const shippingOptions = getWritableValue('g:ecOptions.merchantOptions.shipping', context, {})
    const { types = [], enabled = false } = shippingOptions || {};
    const {type = ''} = this.state;
    const options = []
    if (types.includes('pickUp')) {
      options.push(
        {
          label: 'Local Pick Up',
          value: 'pickUp'
        },
      )
    }
    if (types.some(n => ['fixed', 'dynamic'].includes(n))) {
      options.push(
        {
          label: 'via UPS',
          value: 'shipping'
        },
      )
    }
    return (
      <div key={index} className={classes.join(' ')} style={styles} id='billing-information'>
        <Header background={bgHeader}>
          Billing Information
        </Header>
        <AddressForm
          {...{
            validation, line1, city, state, zip, phones, emails, user, isMandatoryEmail,
            onChangeAddress, onChangeCustomer, onChangeState
          }}
        />
        {
          useShippingInformation ? (
            <ShippingInformationContainer {...this.props} />
          ) : null
        }
        {
          enabled && options.length > 1 && items.length ? (
            <div className='form-group'>
              <div style={{display: 'flex'}}>
                <Label>
                  Delivery:&nbsp;
                </Label>
                <Value>
                  <Dropdown
                    value={type}
                    options={options}
                    onChange={this.onChangeDeliveryType}
                  />
                </Value>
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default BillingInformationContainer;
