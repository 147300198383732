import React from 'react';
import { Link } from 'react-router';

const Button = ({
  id,
  className = '',
  style = {},
  to = false,
  type = 'primary',
  label = '',
  icon,
  children,
  onClick,
  disabled = false,
  outlines = true,
  size = false,
  spin = false,
  target = ''
}) => {
  const others = {};
  others.disabled = disabled ? 'disabled' : undefined;
  others.target = target;

  return (
    !to ? (
      <button
        id={id} style={style} onClick={onClick} {...others}
        className={`btn ${size ? `btn-${size}` : ''} btn${outlines ? '-outline' : ''}-${type} ${className}`}
      >
        <span>
          {icon ? (<i className={`fa fa-${icon} ${spin ? 'fa-spin' : ''}`} />) : null}
          {label ? (<span>{label}</span>) : ''}
          {children ? (<span>{children}</span>) : ''}
        </span>
      </button>
    ) : (
      <Link
        className={`btn ${size ? `btn-${size}` : ''} btn${outlines ? '-outline' : ''}-${type} ${className}`}
        to={to} style={style} id={id} {...others}
      >
        <i className={`fa fa-${icon} ${spin ? 'fa-spin' : ''}`} />
        {label ? (<span>{label}</span>) : ''}
        {children ? (<span>{children}</span>) : ''}
      </Link>
    )
  );
};

export default Button;
