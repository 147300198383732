export const PaymentEngine = {
  createTransaction: payload => ( // { apiKey, pin, test, device, total }
    {
      data: payload,
      method: 'POST',
      to: 'paymentengine',
      errorTarget: 'db:errorMessage'
    }
  ),
  stopTransaction: payload => ( // { apiKey, pin, test, device }
    {
      data: payload,
      method: 'DELETE',
      to: 'paymentengine',
      errorTarget: 'db:errorMessage'
    }
  ),
  checkTransactionStatus: payload => ( // { apiKey, pin, test, device, total }
    {
      data: payload,
      method: 'POST',
      to: 'paymentengine/status',
      errorTarget: 'db:errorMessage'
    }
  )
};

export const Tsys = {
  saleAch: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'tsys/ach',
      errorTarget: 'db:errorMessage'
    }
  )
};

export const WorldPay = {
  createTransaction: payload => ( // { test, device, total }
    {
      data: payload,
      method: 'POST',
      to: 'worldpay-station',
      errorTarget: 'db:errorMessage'
    }
  ),
  stopTransaction: requestId => ( // { test, device }
    {
      method: 'PUT',
      data: { message: 'stopped' },
      errorTarget: 'db:errorMessage',
      to: `worldpay-station/${requestId}`
    }
  ),
  checkTransactionStatus: requestId => ( // { test, device, total }
    {
      method: 'GET',
      errorTarget: 'db:errorMessage',
      to: `worldpay-station/${requestId}`
    }
  ),
  sale: payload => ( // { amount, department, card }
    {
      data: payload,
      method: 'POST',
      to: 'worldpay/sale',
      errorTarget: 'db:errorMessage'
    }
  ),
  void: payload => ( // { transactionId, department }
    {
      data: payload,
      method: 'POST',
      to: 'worldpay/void',
      errorTarget: 'db:errorMessage'
    }
  )
};

export const DataCap = {
  resetPinPad: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'datacap-station/reset',
      errorTarget: 'db:errorMessage'
    }
  ),
  stopTransaction: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'datacap-station/stop',
      errorTarget: 'db:errorMessage'
    }
  ),
  paramdownload: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'datacap-station/paramdownload',
      errorTarget: 'db:errorMessage'
    }
  ),
  createTransaction: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'datacap-station',
      errorTarget: 'db:errorMessage'
    }
  ),
  sale: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'datacap/sale',
      errorTarget: 'db:errorMessage'
    }
   // this return error - not Online payment
  ),
  voidTransaction: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'datacap-station/void',
      errorTarget: 'db:errorMessage'
    }
  ),
  refundTransaction: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'datacap-station/refund',
      errorTarget: 'db:errorMessage'
    }
  )
};

export const Dejavoo = {
  start: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'dejavoo-station',
      errorTarget: 'db:errorMessage'
    }
  ),
  check: requestId => (
    {
      method: 'GET',
      to: `dejavoo-station/${requestId}`,
      errorTarget: 'db:errorMessage'
    }
  ),
  stop: requestId => (
    {
      data: { message: 'stopped' },
      method: 'DELETE',
      to: `dejavoo-station/${requestId}`,
      errorTarget: 'db:errorMessage'
    }
  ),
  sale: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'dejavoo/sale',
      errorTarget: 'db:errorMessage'
    }
  ),
  voidTransaction: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'dejavoo-station/void',
      errorTarget: 'db:errorMessage'
    }
  ),
  refundTransaction: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'dejavoo-station/refund',
      errorTarget: 'db:errorMessage'
    }
  )
};

export const POSLink = {
  start: payload => (
    {
      data: payload,
      method: 'POST',
      to: 'poslink-station',
      errorTarget: 'db:errorMessage'
    }
  ),
  check: requestId => (
    {
      method: 'GET',
      to: `poslink-station/${requestId}`,
      errorTarget: 'db:errorMessage'
    }
  ),
  stop: requestId => (
    {
      data: {message: 'stopped'},
      method: 'DELETE',
      to: `poslink-station/${requestId}`,
      errorTarget: 'db:errorMessage'
    }
  )
}

export const Users = {
  fetchOne: id => (
    {
      method: 'GET',
      to: `users/${id}`,
      errorTarget: 'db:errorMessage',
      set: [
        {
          target: 'g:user',
          '@value': 'data'
        }
      ]
    }
  )
};

export const Shipping = {
  fetchOne: () => (
    {
      method: 'POST',
      to: 'shipping',
      errorTarget: 'db:errorMessage',
      "data": {
        "@department": "g:department",
        "@store": "g:store",
        "@customer": "g:object.customer",
        "@item": "g:object.item",
        "@items": "g:object.items",
      },
    }
  )
};

export const Stores = {
  fetchOne: id => (
    {
      method: 'GET',
      to: `list/${id}`,
      errorTarget: 'db:errorMessage',
      set: [
        {
          target: 'docs:secureList',
          '@value': 'data'
        }
      ]
    }
  )
};

export const Products = {
  fetch: (department, store) => (
    {
      method: 'GET',
      to: `products/${store}`,
      errorTarget: 'db:errorMessage',
      set: [
        {
          target: 'g:productOptions',
          '@value': 'data'
        }
      ]
    }
  ),
  search: (query) => (
    {
      method: 'POST',
      to: 'products',
      data: query,
      errorTarget: 'db:errorMessage',
      set: [
        {
          target: 'g:searchProduct',
          '@value': 'data'
        }
      ]
    }
  ),
  fetchByCategory: (department, store, category) => (
    {
      method: 'GET',
      to: `by_category_available/${category}/${store}`,
      errorTarget: 'db:errorMessage',
      set: [
        {
          target: `g:${category}`,
          '@value': 'data'
        }
      ]
    }
  )
};

export const Heartbeat = {
  ping: (merchant) => {
    // don't call this is user is logged in!!!!
    const w = window, d = w.document, e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight|| e.clientHeight|| g.clientHeight;
    if (merchant) fetch('/heartbeat', {
      method: 'POST', body: '',
      headers: {
        'X-Merchant': merchant,
        'X-Where': w.location.pathname,
        'X-Screen': x + 'x' + y,
        'X-Version': 'public',
        'X-Build': w.CLIENT_BUILD ? w.CLIENT_BUILD : ''
      },
    });
  }
};

export const TransactionsHistory = {
  createOne: object => (
    {
      method: 'POST',
      data: object,
      to: 'transaction-history/create',
      errorTarget: 'db:errorMessage'
    }
  )
};
