import React from 'react';
import ec from 'ec-react15-lib';

import DeviceKey from './DeviceKey';
import Button from '../../controls/Button';
import PosTerminalSVG from '../../controls/PosTerminalSVG';
import { isEmptyObject, isObject } from '../../../../services/VariableType';
import { getMaskedCardNumber, typeCard } from '../../../../services/CreditCardHelpers';

const { TextInput } = ec;

const stylesList = {
  styleInput: {
    fontSize: 4, border: 0, color: 'white', display: 'block', marginBottom: 0, padding: 0
  },
  textCenter: {
    textAlign: 'center'
  }
};

const TransactionResultLine = ({ result }) =>
  (result ? (<div>Result <b>{result}</b></div>) : null);

const CardNumberLine = ({ cardNumber }) =>
  (cardNumber ? (<div>Card Number: <b>{getMaskedCardNumber(cardNumber)}</b></div>) : null);

const CardHolderLine = ({ cardHolder }) =>
  (cardHolder ? (<div>Card Holder: <b>{cardHolder}</b></div>) : null);

const MessageBox = ({ type, content }) => (
  <div
    style={{ maxWidth: '760px', textAlign: 'center', margin: '0 auto' }}
    className={`alert alert-${type}`}
  >
    {content}
  </div>
);

const HiddenInputSwipe = ({ data, onChangeSwipe, onCancelSwipe, onEndSwipe }) => (
  <div>
    <span className='text-muted'>Swipe card...</span>
    <TextInput
      style={stylesList.styleInput}
      id='focusedInput' field='focusedInput' value={data}
      onChange={val => onChangeSwipe('data', val)} onEnter={onEndSwipe}
    />
    <div style={stylesList.textCenter}>
      <Button label='Cancel' type='danger' onClick={onCancelSwipe} />
    </div>
  </div>
);

const SwipeCreditCard = ({ card, onCancelSwipe }) => (
  <div style={{ marginBottom: 15, textAlign: 'left' }}>
    <CardNumberLine {...card} />
    <CardHolderLine {...card} />
    <div>Expires end: <strong>{card.expYear}/{card.expMonth}</strong></div>
    <div style={{ marginTop: 15, ...stylesList.textCenter }}>
      <Button label='Cancel' type='danger' onClick={onCancelSwipe} />
    </div>
  </div>
);

const SwipeForm = ({ active, data, card, ...actions }) => {
  const { onCancelSwipe, onStartSwipe } = actions;
  return (
    <div style={{ marginBottom: 15 }}>
      {active ? (
        <HiddenInputSwipe data={data} {...actions} />
      ) : (
        <div>
          {Object.keys(card).length ?
            (
              <SwipeCreditCard card={card} onCancelSwipe={onCancelSwipe} />
            ) : (
              <Button
                type='secondary'
                label=' SWIPE Card'
                icon='credit-card-alt'
                className='text-muted'
                onClick={onStartSwipe}
              >
                <div><small>(online payment)</small></div>
              </Button>
            )}
        </div>
      )}
    </div>
  );
};

const CreditCardsChip = ({ cardType }) => {
  const cType = cardType || '';
  return <i style={{ fontSize: '5em' }} className={`fa fa-cc-${typeCard[cType]}`} />;
};

const Transaction = ({ payment }) => {
  return (
    <div style={{ marginBottom: 15 }}>
      <CardNumberLine {...payment} />
      <CardHolderLine {...payment} />
      {payment.transactions && payment.transactions[0]
        ? (<TransactionResultLine {...payment.transactions[0]} />)
        : null}
    </div>
  );
};

const ChargeComponent = ({ station, device, status, sendingToDevice, disabled, payment, onStart }) => (
  <div>
    {
      sendingToDevice ? (
        <div>
          <div className='text-primary bold'>
            {status}
          </div>
          <div>
            <img
              style={{ maxWidth: '500px', width: '100%' }}
              src='https://s3.amazonaws.com/indywebhost/loading/small_animation.gif'
              alt='charge'
            />
          </div>
        </div>
      ) : (
        <Button
          size='lg'
          type='success'
          outlines={false}
          disabled={disabled}
          style={{cursor: 'pointer'}}
          onClick={!disabled ? () => onStart() : null}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <PosTerminalSVG />
            <span style={{ paddingLeft: '5px', fontSize: 'x-large' }}>Charge @{station}</span>
          </div>
        </Button>
      )
    }
    <div style={{ marginTop: '15px' }}>
      {isObject(device) && !isEmptyObject(device) ? (
        <DeviceKey {...device} />
      ) : null}
      {payment && Object.keys(payment).length ? (
        <div>
          <Transaction {...{ payment }} />
        </div>
      ) : null}
    </div>
  </div>
);

export { ChargeComponent, CreditCardsChip, SwipeForm, MessageBox };
