import { getMoneyString, getPercentString, getStyles } from '../PaymentTypeHelpers';
import { generateTableElement, getObjectOfTextElements } from './PdfElements';
import { getJoinObject } from '../GeneralHelpers';

const getTransactionDiscountTable = ({ labels = {}, values = {}, isTplLarge, styles = {} }) => {
  const stylesCommon = getStyles(styles, isTplLarge).transactionPaymentType.label;
  const stylesLabels = Object.keys(labels).reduce((prev, curr) => ({ ...prev, [curr]: stylesCommon }), {});
  const stylesValue = getStyles(styles, isTplLarge).transactionPaymentType.value;
  const stylesValues = Object.keys(values).reduce((prev, curr) => ({ ...prev, [curr]: stylesValue }), {});

  const objectValues = getObjectOfTextElements(values, stylesValues);
  const objectLabels = getObjectOfTextElements(labels, stylesLabels);

  const params = {
    headers: objectLabels,
    values: objectValues,
    ...getStyles(styles, isTplLarge).transactionPaymentType.table
  };

  return generateTableElement(params);
};

export const getElement = (discount, styles) => {
  if (!discount) return '';
  const { coupon: { code = false, description = false } = {}, discountType = false, amount = false } = discount;
  if (!amount) return {};
  const amountCoupon = discountType === 'percent' ? getPercentString(-1 * amount) : getMoneyString(-1 * amount);
  const object = { code: `(${code})`, description, amountCoupon };
  const labels = { coupon: 'Coupon' };
  const values = { coupon: getJoinObject(object) };
  return getTransactionDiscountTable({ labels, values, styles });
};
