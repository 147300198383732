import { getWritableValue } from 'ec-react15-lib';
import * as pdfElements from '../services/pdf';
import { getSpace } from '../services/pdf/PdfElements';

const PdfSignature = ({ gen, props, context }) => {
  const defaultTpl = getWritableValue('g:ecOptions.merchantOptions.receipt.preview', context) || false;
  const isTplLarge = defaultTpl === 'A4' || !defaultTpl;
  const styles = gen.getStyling(props, context);

  if (!styles || !context || !pdfElements['Signature']) return false;
  const title = 'Signature';
  const element = pdfElements['Signature'].getElement({ title, styles, isTplLarge });
  gen.addElement(element);
  gen.addElement(getSpace());
  return false;
};

export default PdfSignature;
