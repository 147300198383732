import { getWritableValue } from 'ec-react15-lib';

import * as pdfElements from '../../services/pdf';
import { getSpace } from '../../services/pdf/PdfElements';
import { addSuffix } from '../../services/GeneralHelpers';
import { getLocalFormat } from '../../services/DateHelpers';
import { isObject, isEmptyObject, isString, isEmptyString } from '../../services/VariableType';
import { getAddressString, getCompanyString, getEmailsString, getNameString, getPhonesString
} from '../../services/PaymentTypeHelpers';

const PdfHeader = ({ gen, props, context }) => {
  const defaultTpl = getWritableValue('g:ecOptions.merchantOptions.receipt.preview', context) || false;
  const isTplLarge = defaultTpl === 'A4' || !defaultTpl;
  const styles = gen.getStyling(props, context);
  if (!styles || !context) return false;
  const { docs: { doc = {} } = {} } = context;

  const {
    station = false, customer = false, user = false,
    dateCreated = false, store = false, terminalId = false
  } = doc;

  const customerName = getNameString(customer) || '';
  const customerCompany = getCompanyString(customer) || '';
  const customerPhones = getPhonesString(customer) || '';
  const customerEmails = getEmailsString(customer) || '';
  const customerAddress = getAddressString(customer) || '';

  const userName = getNameString(user) || '';
  const userPhones = getPhonesString(user) || '';
  const userEmails = getEmailsString(user) || '';

  const stationName = isObject(station) && !isEmptyObject(station)
    && isString(station.stationName) && !isEmptyString(station.stationName)
    ? station.stationName.trim() : '';
  const storeName = isObject(store) && !isEmptyObject(store)
    && isString(store.name) && !isEmptyString(store.name)
    ? store.name.trim() : '';

  const date = getLocalFormat(dateCreated, 'MM/DD/YY, h:mm A');

  const labels = {
    customerName: 'Customer:',
    customerCompany: 'Company:',
    customerPhones: `Phone${addSuffix(customer ? customer.phones : '')}:`,
    customerEmails: `Email${addSuffix(customer ? customer.emails : '')}:`,
    customerAddress: 'Address:',

    userName: 'Cashier:',
    userPhones: `Phone${addSuffix(user ? user.phones : '')}:`,
    userEmails: `Email${addSuffix(user ? user.emails : '')}:`,
    terminalId: 'Terminal ID:',
    stationName: 'Station:',
    storeName: 'Store:',
    date: 'Date:'
  };

  const values = {
    customerName,
    customerCompany,
    customerPhones,
    customerEmails,
    customerAddress,

    userName,
    userPhones,
    userEmails,
    storeName,
    stationName,
    terminalId,
    date
  };

  if (pdfElements['Header']) {
    const element = pdfElements['Header'].getElement({ labels, values, isTplLarge, styles });
    gen.addElement(getSpace('0.5'));
    gen.addElement(element);
  }
  return false;
};

export default PdfHeader;
