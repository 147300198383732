import { getStyles } from '../PaymentTypeHelpers';

export const getElement = ({ title, styles, isTplLarge }) => {
  const stylesTotals = getStyles(styles, isTplLarge).totals;
  const label = { text: title, ...stylesTotals.label };

  return {
    table: {
      widths: !isTplLarge ? ['*', '*'] : ['*', 100],
      body: [
        [
          { ...label, border: [false, false, false, false] },
          { text: '', border: [false, false, false, true] }
        ]
      ]
    }
  };
};
