import { generateTableElement, getObjectOfTextElements } from './PdfElements';
import { getMoneyString, getStyles } from '../PaymentTypeHelpers';

const getTotalsObject = (showDonation, totals) => {
  const { subTotal = 0, discountTotal = 0, couponTotal = 0, taxTotal = 0, grandTotal = '0.00',
    convenienceFee = 0, fee = 0, surcharge = 0, SHFee = 0, tips = 0
  } = totals;

  if (showDonation) {
    const labels = { grandTotalString: 'Donated:' };
    const values = { grandTotalString: getMoneyString(grandTotal) };
    return { labels, values };
  }

  const labels = {};
  const values = {};
  if (parseFloat(subTotal) !== parseFloat(grandTotal)) {
    labels.subTotalString = 'Sub Total:';
    values.subTotalString = getMoneyString(subTotal);
  }
  labels.convenienceFee = 'Convenience Fee:';
  labels.discountTotalString = 'Discount:';
  labels.coupon = 'Coupon:';
  labels.fee = 'Fee:';
  labels.surcharge = 'Surcharge:';
  labels.SHFee = 'Service and Handling Fee:';
  labels.tips = 'Tips:';
  labels.taxTotalString = 'Tax:';

  values.convenienceFee = getMoneyString(convenienceFee, false);
  values.discountTotalString = getMoneyString(`-${discountTotal}`, false);
  values.coupon = getMoneyString(`-${couponTotal}`, false);
  values.fee = getMoneyString(fee, false);
  values.surcharge = getMoneyString(surcharge, false);
  values.SHFee = getMoneyString(SHFee, false);
  values.tips = getMoneyString(tips, false);
  values.taxTotalString = getMoneyString(taxTotal, false);

  if (parseFloat(subTotal) !== parseFloat(grandTotal)) {
    labels.subTotalString = 'Sub Total:';
    values.subTotalString = getMoneyString(subTotal);
    labels.grandTotalString = 'Grand Total:';
    values.grandTotalString = getMoneyString(grandTotal);
  } else {
    labels.grandTotalString = 'Total:';
    values.grandTotalString = getMoneyString(grandTotal);
  }

  return { labels, values };
};

export const getElement = ({ totals, showDonation, styles, isTplLarge }) => {
  const stylesTotals = getStyles(styles, isTplLarge).totals;
  const { labels = {}, values = {} } = getTotalsObject(showDonation, totals) || {};

  const specLabels = ['grandTotalString'];
  const stylesSpec = { bold: true };

  const stylesLabels = Object.keys(labels).reduce((prev, curr) => {
    if (specLabels.includes(curr)) return { ...prev, [curr]: { ...stylesTotals.label, ...stylesSpec } };
    return { ...prev, [curr]: stylesTotals.label };
  }, {});

  const stylesValues = Object.keys(values).reduce((prev, curr) => {
    if (specLabels.includes(curr)) return { ...prev, [curr]: { ...stylesTotals.value, ...stylesSpec } };
    return { ...prev, [curr]: stylesTotals.value };
  }, {});

  const objectLabels = getObjectOfTextElements(labels, stylesLabels);
  const objectValues = getObjectOfTextElements(values, stylesValues);

  const params = {
    headers: objectLabels,
    values: objectValues,
    ...stylesTotals.table
  };

  return generateTableElement(params);
};