import React from 'react';
import styled from 'styled-components';
import { Label, Value } from '../../controls/StyledDiv';
import { isValue } from '../../../../services/VariableType';
import { typeCard } from '../../../../services/CreditCardHelpers';

const Image = styled.img`
  filter: ${props => props.isActive ? '' : 'grayscale(100%)'};
  margin-right: 5px;
`;

const AcceptCard = ({ showVisa, showDiscover, showMastercard, showAmex, cardType }) => {
  const type = typeCard[cardType];
  return (
    (showVisa || showDiscover || showMastercard || showAmex) ? (
      <div className='form-group' style={{ display: 'flex' }}>
        <Label>
          Accept Cards:
        </Label>
        <Value>
          <div>
            {
              showVisa ? (
                <Image
                  isActive={!isValue(type) || type === 'visa'}
                  src='https://indygateway.net/static/indygate/images/visa.png' alt='visa'
                />
              ) : null
            }
            {
              showDiscover ? (
                <Image
                  isActive={!isValue(type) || type === 'discover'}
                  src='https://indygateway.net/static/indygate/images/discover-curved.png' alt='discover'
                />
              ) : null
            }
            {
              showMastercard ? (
                <Image
                  isActive={!isValue(type) || type === 'mastercard'}
                  src='https://indygateway.net/static/indygate/images/mastercard-curved.png' alt='mastercard'
                />
              ) : null
            }
            {
              showAmex ? (
                <Image
                  isActive={!isValue(type) || type === 'amex'}
                  src='https://indygateway.net/static/indygate/images/card-american-expres.ico' alt='amex'
                />
              ) : null
            }
          </div>
        </Value>
      </div>
    ) : null
  );
}

export default AcceptCard;
