export const stationProperties = {
  no_department_payment_engine: [],
  poslink: [
    { name: 'mid', label: 'Merchant ID', required: true },
    { name: 'deviceID', label: 'Device ID', required: true },
    { name: 'userID', label: 'User Name', required: true, placeholder: 'TAXXXXXXX' },
    { name: 'password', label: 'User Password', required: true, isAlphaNumeric: false },
    { name: 'commType', label: 'COMM Type', defaultValue: 'HTTP', required: true },
    { name: 'serialNumber', label: 'Serial Number', required: true },
    { name: 'serialPort', label: 'Serial Port', required: true, defaultValue: 'COM4' },
    { name: 'baudRate', label: 'Baud Rate', required: true, defaultValue: '115200' },
    { name: 'destIP', label: 'Dest IP', required: true, isAlphaNumeric: false, defaultValue: '192.168.0.108' },
    { name: 'destPort', label: 'Dest Port', required: true, defaultValue: '10009' },
    { name: 'timeOut', label: 'Time Out', required: true, defaultValue: '30000' }
  ],
  worldpay: [
    { name: 'deviceKey', required: true, placeholder: 'Serial Nr' }
  ],
  usaepay: [
    { name: 'deviceKey', required: true, placeholder: 'sa_',  isAlphaNumeric: false },
    { name: 'apiKey', required: true },
    { name: 'pin', required: true }
  ],
  datacap: [
    { name: 'accountID', required: true },
    { name: 'authCode', required: true },
    { name: 'deviceID', required: true, placeholder: 'PTXXXXXXXXXX' },
    { name: 'secureDevice', required: true, placeholder: 'CloudEMV2' },
    { name: 'terminalID', required: true },
    { name: 'pinPadMACAddress', required: false },
    { name: 'pinPadIpAddress', required: false },
    { name: 'pinPadIpPort', required: false }
  ],
  dejavoo: [
    { name: 'authKey', required: true },
    { name: 'registerId', option: true },
    { name: 'tpn', option: true }
  ]
};

export const paymentEngineHint = {
  pin: 'PIN',
  sourceKey: 'Source Key',
  secretKey: 'Secret Key',
  publicKey: 'Public Key',
  securenetID: 'Secure Net ID'
};

export const stationHint = {
  pinPadIpPort: 'Pin Pad IP Port',
  pinPadMACAddress: 'Pin Pad MAC',
  pinPadIpAddress: 'Pin Pad IP',
  secureDevice: 'Secure Device',
  terminalID: 'Terminal ID',
  registerId: 'Register ID',
  deviceKey: 'Device Key',
  accountID: 'Account ID',
  deviceID: 'Device ID',
  authCode: 'Auth Code',
  authKey: 'Auth Key',
  apiKey: 'API Key',
  tpn: 'TPN',
  pin: 'PIN'
};
