import React, {Component} from 'react';
import ec from 'ec-react15-lib';
import FormACH from "./FormACH";
import Button from "../../controls/Button";
import {isEmptyObject, isObject, isValue} from "../../../../services/VariableType";
import {Tsys} from "../../../../services/ApiData";
import {getErrorString} from "../../../../services/HandlerError";
import {onTriggerAction} from "../../../../services/ContextHelpers";

const { setValue, getValue, Debounced, triggerAction, getWritableValue } = ec;

const defaultState = {
  sending: '',
  achData: {
    achSecCode: 'WEB',
    firstName: '',
    lastName: '',
    accountDetails: {
      accountType: 'CHECKING',
      routingNumber: '',
      accountNumber: ''
    }
  }
};

class ACHContainer extends Component {

  state = {...defaultState};

  componentWillReceiveProps() {
    const el = document.getElementsByClassName("id-submitBtn")[0];
    if (el && el.style.display !== 'none') {
      el.style.display = 'none';
    }
  }

  componentWillUnmount() {
    const el = document.getElementsByClassName("id-submitBtn")[0];
    if (el) {
      el.style.display = 'block';
    }
  }

  onChangeACH = (field, value) => {
    const { props, context } = this.props;
    if (!props || !context) return null;
    const target = props.value || props['@value'];
    const message = { type: '', content: '' };
    const object = getValue(props, 'value', context);
    const {amount = 0} = object || {};
    Debounced.start(`update-${target}`, () => {
      const streamName = `${target}`;
      const { achData = {} } = this.state;
      if (field === 'routingNumber') {
        achData.accountDetails.routingNumber = value;
      } else if (field === 'accountNumber') {
        achData.accountDetails.accountNumber = value;
      } else {
        achData[field] = value;
      }
      achData.amount = amount;
      const newPayment = { ...object, achData };
      setValue(streamName, newPayment, context);
      this.setState({ achData, message });
    }, 250);
  };

  onMessage = ({type, content}) => {
    if (content) {
      console.log('Message:', content);
    }
    const ctn = isObject(content) && !isEmptyObject(content) ? getErrorString(content) : content;
    const {context} = this.props;
    if (type === 'danger') {
      setValue('g:errorMessage', ctn, context);
    } else {
      const message = {type, content: ctn};
      this.setState({message});
      setTimeout(() => {
        if (this.element) this.setState({message: {type: '', content: ''}});
      }, 1000);
    }
  };

  onChargeACH = () => {
    const { context, props } = this.props;
    const {achData = {}} = this.state;
    const department = getWritableValue('g:department', context, '') || '';
    const payload = {
      department,
      achData
    };
    const object = getValue(props, 'value', context);
    const {amount = 0} = object || {};
    this.setState({sending: true});
    onTriggerAction(Tsys.saleAch(payload), context)
      .then(response => {
        const err = getErrorString(response);
        if (!err) {
          const [object] = response;
          const {originData = {}} = object;
          const status = originData.status || object.status || '';
          this.setState({sending: false});
          if (status !== 'PASS') {
            const {responseMessage, achHostMessage} = object;
            const message = [status, responseMessage, achHostMessage].filter(o => o).join(' ');
            this.onMessage({type: 'danger', content: message });
          } else {
            setTimeout(() => {
              this.onSaveTransaction({
                amount,
                firstName: achData.firstName,
                lastName: achData.lastName,
                ...achData.accountDetails,
                source: 'device',
                processor: 'ACH',
                type: 'ACH',
                transactions: [{...object, amount, type: 'Sale'}]
              })
            }, 1000);
          }
        } else {
          this.onMessage({type: 'danger', content: err});
          this.setState({sending: false})
        }
      })
      .catch(e => {
        const err = getErrorString(e);
        this.onMessage({type: 'danger', content: err});
        this.setState({sending: false})
      });
  };

  onSaveTransaction = (transaction) => {
    const {props, context} = this.props;
    if (!props || !context) return null;

    const target = props.value || props['@value'];
    const streamName = `${target}.transaction`;
    setValue(streamName, transaction, context);
    this.setState({payment: transaction});

    const {amount = 0, SVC = 0, fee = 0, SHFee = 0, convenienceFee = 0} = transaction;
    setValue('newOrder:totals.grandTotal', amount, context);
    if (parseFloat(SVC) > 0) {
      setValue('newOrder:totals.surcharge', SVC, context);
    }
    if (parseFloat(convenienceFee) > 0) {
      setValue('newOrder:totals.convenienceFee', convenienceFee, context);
    }
    if (parseFloat(fee) > 0) {
      setValue('newOrder:totals.fee', fee, context);
    }
    if (parseFloat(SHFee) > 0) {
      setValue('newOrder:totals.SHFee', SHFee, context);
    }

    const {onSubmit} = props;
    if (onSubmit) {
      triggerAction(onSubmit, context)
        .catch(e => {
          const error = getErrorString(e);
          if (error) {
            this.onMessage({type: 'danger', content: error});
          }
        });
    }
  };

  render() {
    const {sending} = this.state;
    const {props, context, styles: {disabled: isDisabled} = {}} = this.props;
    const canSubmit = getWritableValue('g:validation.canSubmit', context, '') || '';
    const isAgree = getWritableValue('g:object.agree', context, '') || '';
    const disabled = !canSubmit || !isAgree || (isValue(isDisabled) && isDisabled);

    return (
      <div className={`id-${props._id} ${props.classes}`} style={this.props.styles} ref={el => this.element = el}>
        <div style={{textAlign: 'center', margin: '0 auto'}}>
          <FormACH onChange={this.onChangeACH}/>
          <Button
            label="Charge with 'TSYS - ACH'"
            style={{marginTop: '15px'}}
            onClick={this.onChargeACH}
            disabled={disabled || sending}
            outlines={false}
            type='success'
            size='lg'
          />
        </div>
      </div>
    )
  }
}

export default ACHContainer;
