import { isEmptyString, isString, isValue, isObject, isEmptyObject } from './VariableType';
import checkStation from './validations/checkStation';
import { pad2 } from './GeneralHelpers';

export const formatCardNumber = (val) => {
  // remove all non digit characters
  const value = val.replace(/\D/g, '');
  let formattedValue = '';
  let maxLength;
  // american express, 15 digits
  if ((/^3[47]\d{0,13}$/).test(value)) {
    formattedValue = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
    maxLength = 17;
  } else if((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
    formattedValue = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
    maxLength = 16;
  } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
    formattedValue = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
    maxLength = 19;
  }
  return formattedValue.toString().substring(0, maxLength);
}

const acceptedCreditCards = {
  VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
  MC: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
  AMEX: /^3[47][0-9]{13}$/,
  DISC: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/
};

export const validateCard = (val) => {
  const value = val.replace(/\D/g, '');
  let sum = 0;
  let shouldDouble = false;
  for (let i = value.length - 1; i >= 0; i--) {
    let digit = parseInt(value.charAt(i), 10);

    // eslint-disable-next-line
    if (shouldDouble && ((digit *= 2) > 9)) {
      digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  const valid = (sum % 10) === 0;
  let accepted = false;
  let type = '';

  // loop through the keys (visa, mastercard, amex, etc.)
  Object.keys(acceptedCreditCards).forEach((key) => {
    const regex = acceptedCreditCards[key];
    if (regex.test(value)) {
      accepted = true;
      type = key;
    }
  });

  return valid && accepted ? type : false;
};

export const getCardType = (value) => {
  switch (parseInt(value.substring(0, 1), 10)) {
    case 3: return 'AMEX';
    case 4: return 'VISA';
    case 5: return 'MC';
    case 6: return 'DISC';
    default: return '';
  }
};

const listMonths = [...new Array(12)].map((val, i) => i + 1)
  .reduce((result, i) => {
    result.push({ value: i, label: pad2(i) });
    return result;
  }, [{ value: '', label: 'Month' }]);

const year = new Date().getFullYear();
const listYears = [...new Array(15)].map((val, i) => i)
  .reduce((result, i) => {
    result.push({ value: year + i, label: year + i });
    return result;
  }, [{ value: '', label: 'Year' }]);

export const stationHint = {
  pinPadIpPort: 'Pin Pad IP Port',
  pinPadMACAddress: 'Pin Pad MAC',
  pinPadIpAddress: 'Pin Pad IP',
  secureDevice: 'Secure Device',
  terminalID: 'Terminal ID',
  registerId: 'Register ID',
  deviceKey: 'Device Key',
  accountID: 'Account ID',
  deviceID: 'Device ID',
  authCode: 'Auth Code',
  authKey: 'Auth Key',
  apiKey: 'API Key',
  tpn: 'TPN',
  pin: 'PIN'
};

export const Expiration = { MONTHS: listMonths, YEARS: listYears };

export const typeCard = {
  MC: 'mastercard',
  VISA: 'visa',
  AMEX: 'amex',
  DISC: 'discover'
};

export const getMaskedCardNumber = (fistNum, lastNum) => {
  if (isString(fistNum) && !isEmptyString(fistNum)) {
    const hasLast = isString(lastNum) && !isEmptyString(lastNum);
    const suffix = hasLast ? lastNum.substr(lastNum.length - 4) : fistNum.substr(fistNum.length - 4);
    const prefix = fistNum[0];
    const count = 11;
    const masked = 'X'.repeat(count);
    return `${prefix}${masked}${suffix}`;
  } else if (isString(lastNum) && !isEmptyString(lastNum)) {
    const count = 12;
    const masked = 'X'.repeat(count);
    const suffix = lastNum.substr(lastNum.length - 4);
    return `${masked}${suffix}`;
  }
  return fistNum;
};

export const getDecodeSwipe = (data) => {
  const swipe = {
    data: data ? data.trim() : '',
    active: false,
    card: {}
  };
  let parts = swipe.data.substr(2).split('^');
  if (parts.length > 1) {
    if (parts[0].indexOf('/') !== -1) {
      parts = parts[0].split('&');
    }
    parts[1] = parts[1].trim();
    const holderName = parts[1].split('/');
    swipe.card.cardHolder = `${holderName[0]} ${holderName[1]}`;
    swipe.card.cardNumber = parts[0];
    if (parts[2]) {
      swipe.card.expYear = `20${parts[2].substr(0, 2)}`;
      swipe.card.expMonth = `${parts[2].substr(2, 2)}`;
    }
  }
  return swipe;
};

const formatKeyCase = key => {
  return (key.charAt(0).toUpperCase() === key.charAt(0) && key.charAt(1).toUpperCase() !== key.charAt(1)) ? (
    key.substring(0, 1).toLowerCase() + key.substring(1)
  ) : key;
};

export const formatObjectKeys = (object) => {
  if (!isObject(object) || isEmptyObject(object)) return {};

  return Object.keys(object).reduce((res, key) => {
    if (!isValue(object[key]) || !isString(object[key])) return res;
    const value = object[key];
    const newKey = formatKeyCase(key.trim());
    res[newKey] = decodeURIComponent(!isEmptyString(value) ? value.trim() : value);
    return res;
  }, {});
};

export const getDejavooResponse = (object) => {
  if (!isObject(object) || isEmptyObject(object)) return {};
  try {
    const { response: res = {} } = object;
    const { ExtData = {} } = res;
    const parsedResponse = formatObjectKeys(res);
    if (!ExtData || !Object.keys(ExtData).length) {
      return parsedResponse;
    }
    delete parsedResponse['extData'];
    const parsedExtData = formatObjectKeys(ExtData);
    return { ...parsedResponse, ...parsedExtData, originData: res };
  } catch (e) {
    console.log('Error parse Dejavoo Response', e);
    return {};
  }
};

export const isValidStation = (processorType, station) => {
  const validationMessage = checkStation(station, processorType);
  return isString(validationMessage) && isEmptyString(validationMessage);
};

export const getDeviceId = (deviceData) => {
  const device = {};
  const { deviceKey, deviceID, registerId } = deviceData || {};

  if (isString(deviceKey) && !isEmptyString(deviceKey)) {
    device.label = stationHint['deviceKey'];
    device.value = deviceKey;
  }

  if (isString(deviceID) && !isEmptyString(deviceID)) {
    device.label = stationHint['deviceID'];
    device.value = deviceID;
  }

  if (isString(registerId) && !isEmptyString(registerId)) {
    device.label = stationHint['registerId'];
    device.value = registerId;
  }

  return device;
};

export const ERROR_DATACAP_BUSY = 'Some transaction is in process with a particular TranDeviceID and ' +
  'PinPadIpAddress/PinPadMACAddress combination';
export const ERROR_DATACAP_BEFORE_TRAN = 'Sorry, no response from server before starting a new transaction. ' +
  'Please assure that no card is in the EMV PIN pad chip card (insertion) reader';
export const ERROR_DATACAP_AFTER_TRAN = 'Sorry, no response from server on finish transaction. ' +
  'Please assure that the user is prompted to remove their card';
export const ERROR_TRAN_STATUS = 'Sorry, no response from server while checking transaction status';
export const ERROR_TRAN_REFNO = 'Sorry, order payment item should have sale transaction with comparable refNo';


export const usaepayErrorcode = {
  21121: 'Unknown devicekey xxxxxxx',
  21143: 'Request failed (Payment terminal battery too low)',
  21144: 'Transaction request has timed out.',
  21145: 'Device is currently offline.',
  21146: 'Device is currently processing another transaction.'
};

export const usaepayStatus = {
  processing: [
    'sending to device',
    'sent to device',
    'waiting for card dip',
    'changing interfaces',
    'customer see phone and tap again',
    'processing payment',
    'completing payment',
    'capturing signature'
  ],
  success: ['transaction complete'],
  error: ['signature capture error', 'canceled', 'transaction canceled', 'transaction failed', 'timeout', 'error']
};

export default { Expiration, getCardType, usaepayErrorcode, usaepayStatus };
