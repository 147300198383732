import { getJoinObject, formatString } from '../GeneralHelpers';
import { transactionPaymentTypes } from '../TransactionProperties';
import { getMoneyString, getStyles, refundAliases } from '../PaymentTypeHelpers';
import { generateTableElement, getObjectOfTextElements } from './PdfElements';
import * as types from '../VariableType';

const {isString, isEmptyString } = types;

const getCardNumberMask = (value) => {
  if (!value) return '';
  const { length } = String(value);
  const minLength = 7;
  if (length < minLength) return value;
  const firstChar = value.substring(0, 1);
  const prefix = !['*', 'X'].includes(firstChar) ? value.substring(0, 1) : '*';
  const suffix = value.substring(length - 4);
  const maskedCharacters = '*'.repeat(4);
  return `${prefix}${maskedCharacters}${suffix}`;
};

const getCardMask = (response = {}, transaction, cardNum) => {
  const { maskedCardNumber } = response;
  const { cardNumber } = transaction;
  return getCardNumberMask(cardNum || cardNumber || maskedCardNumber);
};

const getCardHolder = cardHolder => (cardHolder && cardHolder !== 'undefined undefined'
  ? decodeURIComponent(cardHolder) : '');

const getPaymentTypeObject = ({ type = false, ...props }) => {
  const {
    cardType = '',
    amount = 0,
    balance = 0,
    response = false,
    checkNum = false,
    cardNumber = false,
    defaultTpl = false,
    cardHolder = false,
    transaction = false,
    giftCardCode = false,
    employeeNum = false,
    accountNumber = false,
    routingNumber = false,
    storePaymentTypes = []
  } = props;

  const allTypes = transactionPaymentTypes(storePaymentTypes);
  const typePayment = type ? (allTypes[type] || formatString(type)) : '';
  const prefixTitle = `Paid By${defaultTpl === '50mm' ? '\n' : ' '}`;
  const nameObject = {
    prefix:
      type && ![...refundAliases, 'change'].includes(type.toLowerCase())
       ? prefixTitle : '',
    type: typePayment
  };
  const label = getJoinObject(nameObject);

  const isNegative = type && (refundAliases.includes(type.toLowerCase()) || parseFloat(amount) < 0);
  const amountPayment = getMoneyString(!isNegative ? amount : (-1) * parseFloat(Math.abs(amount)));

  switch (type) {
    case 'giftCard':
      if (!giftCardCode) return {};
      if (balance && balance >= 0) {
        return { label: `${label} (Balance: $${balance.toFixed(2)})`, value: amountPayment };
      }
      return { label, value: amountPayment };
    case 'creditCard':
      const cardNumberPayment = getCardMask(response, transaction, cardNumber);
      const creditCardString = getJoinObject({ cardType, cardNumberPayment });
      const cardHolderString = getCardHolder(cardHolder);

      const object = { creditCardString, cardHolderString, amountPayment };
      const value = getJoinObject(object);

      return { label, value };
    case 'check':
      return { label: isString(checkNum) && !isEmptyString(checkNum)
        ? `${label} #${checkNum}` : label, value: amountPayment };
    case 'employeePayrollDeduct':
      return { label: isString(employeeNum) && !isEmptyString(employeeNum)
        ? `${label} #${employeeNum}` : label, value: amountPayment };
    case 'ACH':
      return { label: isString(accountNumber) && !isEmptyString(accountNumber)
        ? `${label} Account #${accountNumber} Routing #${routingNumber}` : label, value: amountPayment };
    default:
      if (cardType && cardNumber) {
        const cardString = getJoinObject({ cardType, cardNumber });
        const obj = { cardString, amountPayment };
        const val = getJoinObject(obj);
        return { label, value: val };
      } else {
        return { label, value: amountPayment };
      }
  }
};

export const getTransactionPaymentTypeTable = ({ labels = {}, values = {}, isTplLarge, styles = {} }) => {
  const stylesCommon = getStyles(styles, isTplLarge).transactionPaymentType.label;
  const stylesLabels = Object.keys(labels).reduce((prev, curr) => ({ ...prev, [curr]: stylesCommon }), {});
  const stylesValue = getStyles(styles, isTplLarge).transactionPaymentType.value;
  const stylesValues = Object.keys(values).reduce((prev, curr) => ({ ...prev, [curr]: stylesValue }), {});

  const objectLabels = getObjectOfTextElements(labels, stylesLabels);
  const objectValues = getObjectOfTextElements(values, stylesValues);

  const params = {
    headers: objectLabels,
    values: objectValues,
    ...getStyles(styles, isTplLarge).transactionPaymentType.table
  };

  return generateTableElement(params);
};

export const getElement = (payment, styles, isTplLarge, defaultTpl) => {
  const object = getPaymentTypeObject({ ...payment, defaultTpl });
  const labels = { payment: object.label };
  const values = { payment: object.value };
  return getTransactionPaymentTypeTable({ labels, values, styles, isTplLarge });
};
