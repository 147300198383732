import React, {Component} from 'react';
import ec from 'ec-react15-lib';
import AOS from 'aos';
import styled from 'styled-components';
// import { remove } from 'lodash';
import 'aos/dist/aos.css'

const { setValue, Logger, getWritableValue, getStyling, triggerAction } = ec;

const styleItemList = { display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'};

const colorDefault = {
  white: {
    accent1: '#0275d8',
    accent2: '#f0ad4e',
    background: '#fff',
    gradient: 'linear-gradient( 90deg, #0275d8,  #93A8DE, #0275d8)'
  },
  tan: {
    accent1:  "#007560",
    accent2: "#f0ad4e",
    background: '#F8eee7',
    gradient: 'linear-gradient( 90deg,   #000, #007560, #000)'
  },
  grey: {
    accent1: 'rgba(41,102,223,1)',
    accent2:  'rgb(1,165,159)',
    background: '#e8e9f0',
    gradient: 'linear-gradient( 0deg, rgba(41,102,223,1) 0% , rgba(1,165,159,1) 50%, rgba(41,102,223,1) 100% )'
  }
};

const Announcement = styled.div`
  height: 50px;
  margin-top: -29px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;

`
const Category = styled.div`
  width: 250px;
  height: 290px;
  cursor: pointer;
  margin: 20px;
  
  border-radius: 20px;
  border: 1px solid;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 10px;
  &:hover: {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    position: relative;
  }
  @media (max-width: 600px) {
    width:125px;
    height:170px;  
  }
`;
const Title = styled.div`
  margin-top: 5px;
  text-align: center;
  color: #000000;
  font-weight: 500;
  width: 100%;
  padding: 0 3px 0 3px;
  @media (max-width: 600px) {
    margin-top: 3px;
    font-size: 9px;
  } 
`;

const Image = styled.div`
  background: ${props => props.image ? `url('${props.image}')` : 'transparent'};
  background-repeat: no-repeat;
  
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 220px;
  margin-top: 5px;
  @media (max-width: 600px) {
   height: 115px;
  }
`;
const Banner = styled.div`
  height: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display:flex;
  justify-content: center;
  align-items: center; 
  -webkit-animation: kenburns-bottom 2s ease-out both;
	        animation: kenburns-bottom 2s ease-out both;

`;
const Merchant = styled.div`
  color: white;
  font-size: 70px;
  text-align: center;
`
const Header = styled.h2`
  text-align: center;
  font-family: 'Rubik', sans-serif;
  font-family: 'Varela Round', sans-serif;
 
  font-size: 40px;
  font-weight: 100;
  line-height: 50px;
  letter-spacing: 1px;
  padding: 10px 0 10px;
  border-bottom: double #555;
  
  
`;

class CategoriesBrowse extends Component {

  onClickItem = (title) => {
    const {context, props: {onSelect}} = this.props;
    setValue('g:category', title, context);
    const store = getWritableValue('g:ecOptions.merchantOptions.onlineForm.store', context, '') || '';
    // const themeNum = context.globals.ecOptions.merchantOptions.theme

    triggerAction({
      actions: {
        send: [
          {
            to: `by_category_available/${title}/${store}`,
            method: 'GET',
            errorTarget: 'g:errorMessage',
            loadingTarget: 'g:loadingStatus',
            set: [
              {
                "@value": "data",
                "target": "docs:products"
              }
            ]
          }
        ]
      }
    }, context)
      .then(_ => {
        triggerAction(onSelect, context);
      });
  };
  componentDidMount(){
    AOS.init()
  }

  render() {
    const {index, props, context, pos, section, childIndex} = this.props;
    Logger.of('render.html.CategoriesBrowse').info('section', section, 'index', index, 'props', props, 'pos', pos);
    const sp = { props, context, pos, childIndex };
    
    const announcement = context.globals.ecOptions.merchantOptions.announcement || '';
    const url = context.globals.ecOptions.merchantOptions.backgroundImage || '';
    const theme = context.globals.ecOptions.merchantOptions.theme || 'white';
    const colors = context.globals.ecOptions.merchantOptions.color || colorDefault;
    const color = colors[theme] || colors['white'];

    document.body.style.backgroundColor = color.background;

    const backgroundImage = context.globals.ecOptions.merchantOptions.backgroundImage || '';
    const background = (backgroundImage.length < 2) ? {backgroundImage: color.gradient } : {backgroundImage:  `url(${url}`};

    // function remove(e){
    //   // TODO: get rid of this - manipulating DOM directly should not be done in React
    //   return ( e.currentTarget.parentNode.style.height = '0px')
    // }
    
    const { styles, classes } = getStyling({
      ...sp, mandatory: ['onSelect'], optional: [], styling: ['Block', 'Visibility']
    });
    if (styles === false) return false;
    const list = getWritableValue('docs:categories.list', context, []) || [];
    const loadingStatus = getWritableValue('g:loadingStatus', context, '') || '';
    if (loadingStatus && loadingStatus === 'in_progress') return null;
    return (
      <div style={{background: color.background}}>
        <div key={index} className={classes.join(' ')} style={styles}>
          <Announcement style={ (announcement.length < 2)? {height: 0} :  {backgroundColor: color.accent2}}>
           <div style={{paddingTop: '8px'}}> {announcement} </div> 
          </Announcement>
          <Banner style={ background} className='header-in'>
            <Merchant >
              {context.globals.ecOptions.merchantOptions.title.toUpperCase() } 
            </Merchant>
          </Banner>
          <br></br>
          <Header style={{color: color.accent1}} > CATEGORIES </Header>
          <div style={styleItemList}  >
            {list && list.length ? (
              list.map((category, i) => {
                const imgOriginal = category.image ? (category.image.original || category.image) : '';
                const imgRoot = process.env.REACT_APP_IMG_ROOT || '';
                const img = imgOriginal.replace('https://s3.amazonaws.com/', `${imgRoot}/img/x250/`);
                if (!category.title) return null;
                
                return (
                  <Category
                    key={i}
                    onClick={() => this.onClickItem(category.title)}
                    data-aos='zoom-in' data-aos-delay='150'
                    className={category.title === "Sale Items" ? "heartbeat" : ""}
                    style={{borderColor:color.accent2}}
                  >  
                    <Image image={img}  />
                  
                    <Title style={
                    
                    (category.title.length > 35 && window.innerWidth < 600 )  ? {fontSize:"7px"}
                    :(category.title.length > 35 )? {fontSize:"12px"} : {}
                    }>{category.title.toUpperCase()
                    
                    } 
                    </Title>
                  </Category>
                );
              })
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default CategoriesBrowse;
