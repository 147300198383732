import React from 'react';
import ec from 'ec-react15-lib';

import * as variableType from '../../../services/VariableType';
import {Label, Value} from '../controls/StyledDiv';
import {sortArrObjectsByKey} from "../../../services/GeneralHelpers";

const {TextInput, Dropdown} = ec;
const {isObject, isEmptyObject} = variableType;

const states = [
  {
    "name": "United States",
    "abbreviation": "US",
    "states": [
      {
        "name": "Alabama",
        "abbreviation": "AL"
      },
      {
        "name": "Alaska",
        "abbreviation": "AK"
      },
      {
        "name": "American Samoa",
        "abbreviation": "AS"
      },
      {
        "name": "Arizona",
        "abbreviation": "AZ"
      },
      {
        "name": "Arkansas",
        "abbreviation": "AR"
      },
      {
        "name": "California",
        "abbreviation": "CA"
      },
      {
        "name": "Colorado",
        "abbreviation": "CO"
      },
      {
        "name": "Connecticut",
        "abbreviation": "CT"
      },
      {
        "name": "Delaware",
        "abbreviation": "DE"
      },
      {
        "name": "District Of Columbia",
        "abbreviation": "DC"
      },
      {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
      },
      {
        "name": "Florida",
        "abbreviation": "FL"
      },
      {
        "name": "Georgia",
        "abbreviation": "GA"
      },
      {
        "name": "Guam",
        "abbreviation": "GU"
      },
      {
        "name": "Hawaii",
        "abbreviation": "HI"
      },
      {
        "name": "Idaho",
        "abbreviation": "ID"
      },
      {
        "name": "Illinois",
        "abbreviation": "IL"
      },
      {
        "name": "Indiana",
        "abbreviation": "IN"
      },
      {
        "name": "Iowa",
        "abbreviation": "IA"
      },
      {
        "name": "Kansas",
        "abbreviation": "KS"
      },
      {
        "name": "Kentucky",
        "abbreviation": "KY"
      },
      {
        "name": "Louisiana",
        "abbreviation": "LA"
      },
      {
        "name": "Maine",
        "abbreviation": "ME"
      },
      {
        "name": "Marshall Islands",
        "abbreviation": "MH"
      },
      {
        "name": "Maryland",
        "abbreviation": "MD"
      },
      {
        "name": "Massachusetts",
        "abbreviation": "MA"
      },
      {
        "name": "Michigan",
        "abbreviation": "MI"
      },
      {
        "name": "Minnesota",
        "abbreviation": "MN"
      },
      {
        "name": "Mississippi",
        "abbreviation": "MS"
      },
      {
        "name": "Missouri",
        "abbreviation": "MO"
      },
      {
        "name": "Montana",
        "abbreviation": "MT"
      },
      {
        "name": "Nebraska",
        "abbreviation": "NE"
      },
      {
        "name": "Nevada",
        "abbreviation": "NV"
      },
      {
        "name": "New Hampshire",
        "abbreviation": "NH"
      },
      {
        "name": "New Jersey",
        "abbreviation": "NJ"
      },
      {
        "name": "New Mexico",
        "abbreviation": "NM"
      },
      {
        "name": "New York",
        "abbreviation": "NY"
      },
      {
        "name": "North Carolina",
        "abbreviation": "NC"
      },
      {
        "name": "North Dakota",
        "abbreviation": "ND"
      },
      {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
      },
      {
        "name": "Ohio",
        "abbreviation": "OH"
      },
      {
        "name": "Oklahoma",
        "abbreviation": "OK"
      },
      {
        "name": "Oregon",
        "abbreviation": "OR"
      },
      {
        "name": "Palau",
        "abbreviation": "PW"
      },
      {
        "name": "Pennsylvania",
        "abbreviation": "PA"
      },
      {
        "name": "Puerto Rico",
        "abbreviation": "PR"
      },
      {
        "name": "Rhode Island",
        "abbreviation": "RI"
      },
      {
        "name": "South Carolina",
        "abbreviation": "SC"
      },
      {
        "name": "South Dakota",
        "abbreviation": "SD"
      },
      {
        "name": "Tennessee",
        "abbreviation": "TN"
      },
      {
        "name": "Texas",
        "abbreviation": "TX"
      },
      {
        "name": "Utah",
        "abbreviation": "UT"
      },
      {
        "name": "Vermont",
        "abbreviation": "VT"
      },
      {
        "name": "Virgin Islands",
        "abbreviation": "VI"
      },
      {
        "name": "Virginia",
        "abbreviation": "VA"
      },
      {
        "name": "Washington",
        "abbreviation": "WA"
      },
      {
        "name": "West Virginia",
        "abbreviation": "WV"
      },
      {
        "name": "Wisconsin",
        "abbreviation": "WI"
      },
      {
        "name": "Wyoming",
        "abbreviation": "WY"
      }
    ]
  },
  {
    "name": "Canada",
    "abbreviation": "CA",
    "states": [
      {
        "name": "Alberta",
        "abbreviation": "AB"
      },
      {
        "name": "British Columbia",
        "abbreviation": "BC"
      },
      {
        "name": "Manitoba",
        "abbreviation": "MB"
      },
      {
        "name": "New Brunswick",
        "abbreviation": "NB"
      },
      {
        "name": "Newfoundland and Labrador",
        "abbreviation": "NL"
      },
      {
        "name": "Northwest Territories",
        "abbreviation": "NT"
      },
      {
        "name": "Nova Scotia",
        "abbreviation": "NS"
      },
      {
        "name": "Nunavut",
        "abbreviation": "NU"
      },
      {
        "name": "Ontario",
        "abbreviation": "ON"
      },
      {
        "name": "Prince Edward Island",
        "abbreviation": "PE"
      },
      {
        "name": "Quebec",
        "abbreviation": "QC"
      },
      {
        "name": "Saskatchewan",
        "abbreviation": "SK"
      },
      {
        "name": "Yukon Territory",
        "abbreviation": "YT"
      }
    ]
  }
]
const formStyles = {display: 'flex', flexWrap: 'wrap', alignItems: 'baseline', justifyContent: 'center' }

const AddressForm = ({
                       user, validation, line1, city, state, zip, phones, emails, isMandatoryEmail = false,
                       onChangeAddress, onChangeCustomer, onChangeState
                     }) => {
  const op = states.reduce((res, curr) => {
    const {states: arr = [], abbreviation = '', name = '' } = curr;
    const list = arr
      .map(o => ({
        label: `${o.name}${name === 'Canada' ? ` (${name})` : ''}`,
        value: o.abbreviation,
        country: abbreviation }));
    return [...res, ...list];
  }, []);
  const options = sortArrObjectsByKey(op, 'label');

  return (
    <div>
      <div className='form-group'>
        <div style={formStyles}>
          <Label>
            Street Address:&nbsp;
          </Label>
          <Value>
            <TextInput
              value={line1}
              placeholder='Street Address'
              onChange={value => onChangeAddress('line1', value)}
            />
          </Value>
        </div>
        {validation['line1'] ? (
          <div
            style={{textAlign: 'right'}}
            className='small text-danger'
          >
            {validation['line1']}
          </div>
        ) : null}
      </div>
      <div className='form-group'>
        <div  style={formStyles}>
          <Label>
            City:&nbsp;
          </Label>
          <Value>
            <TextInput
              value={city}
              placeholder='City'
              onChange={value => onChangeAddress('city', value)}
            />
          </Value>
        </div>
        {validation['city'] ? (
          <div
            style={{textAlign: 'right'}}
            className='small text-danger'
          >
            {validation['city']}
          </div>
        ) : null}
      </div>
      <div className='form-group'>
        <div style={formStyles} >
          <Label>
            State:&nbsp;
          </Label>
          <Value>
            <Dropdown
              value={state}
              options={options}
              onChange={value => onChangeState(options.find(o => o.value === value))}
            />
          </Value>
        </div>
        {validation['state'] ? (
          <div
            style={{textAlign: 'right'}}
            className='small text-danger'
          >
            {validation['state']}
          </div>
        ) : null}
      </div>
      <div className='form-group'>
        <div style={formStyles} >
          <Label>
            Zip Code:&nbsp;
          </Label>
          <Value>
            <TextInput
              value={zip}
              placeholder='Zip Code'
              onChange={value => onChangeAddress('zip', value)}
            />
          </Value>
        </div>
        {validation['zip'] ? (
          <div
            style={{textAlign: 'right'}}
            className='small text-danger'
          >
            {validation['zip']}
          </div>
        ) : null}
      </div>
      <div className='form-group' style={formStyles}  >
        <Label >
          <span>Contact Phone:&nbsp;</span>
          {isObject(user) && !isEmptyObject(user)
            ? (<span className='small'>(optional)</span>)
            : null}
        </Label>
        <Value>
          <TextInput
            value={phones}
            placeholder='Contact Phone'
            onChange={value => onChangeCustomer('phones', value)}
          />
        </Value>
      </div>
      <div className='form-group'>
        <div style={formStyles}>
          <Label >
            <span>E-Mail Address:&nbsp;</span>
            {!isMandatoryEmail ? (<span className='small'>(optional)</span>) : null}
          </Label>
          <Value>
            <TextInput
              value={emails}
              placeholder='E-Mail Address'
              onChange={value => onChangeCustomer('emails', value)}
            />
          </Value>
        </div>
        {validation['emails'] ? (
          <div
            style={{textAlign: 'right'}}
            className='small text-danger'
          >
            {validation['emails']}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AddressForm;
