/* eslint-disable */
import { getWritableValue } from 'ec-react15-lib';
import * as elements from './Elements';

const PdfHeader = ({ gen, props, context }) => {
  const styles = gen.getStyling(props, context);
  if (!styles) return false;
  const { type } = context.docs.doc;

  if (type && elements[type]) {
    elements[type].PdfHeader({ gen, props, context });
  }
  return false;
};
export default PdfHeader;
/* eslint-enable */
