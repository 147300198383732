import moment from 'moment';

import {fixedTo2, isDigit, toAdd, toMultiply, toSubtract} from './GeneralHelpers';
import {isObject, isEmptyObject, isValue, isArray, isEmptyArray, isString, isEmptyString} from './VariableType';

const convertToMomentArr = (dates = []) => {
  const moments = dates.map(date => moment(date).startOf('date'));
  if (moments.length === 0) moments.push(moment().startOf('date'));
  return moments;
};

export const getDateExtremum = {
  max: (dates = []) => moment.max(convertToMomentArr(dates)),
  min: (dates = []) => moment.min(convertToMomentArr(dates))
};

export const isRental = (el) => {
  if (!isValue(el)) return false;
  const rental = isString(el) && !isEmptyString(el) ? el : el.rental;
  return rental && rental !== 'inventory';
};

export const getRentalDuration = {
  days: (start, end) => moment(end).diff(moment(start), 'days'),
  months: (start, end) => Math.round(moment(end).diff(moment(start), 'months', true))
};

export const getRentalTotalQty = ({ rentalSerials = [] }) => {
  let durations = 0;
  rentalSerials.forEach((obj) => {
    if (obj.dates) {
      const start = getDateExtremum.min(obj.dates);
      const end = getDateExtremum.max(obj.dates);
      const duration = getRentalDuration.days(start, end);
      durations += duration;
    }
  });
  return durations;
};

export const isAvailabilityProduct =
  (onHand = 0, infinity = false, negativeInventory = false) => (onHand > 0 || infinity || negativeInventory === 'Yes');

export const isValidQuantity = (qty = 0, onHand = 0, infinity = false, negativeInventory = false) =>
  ((onHand >= qty && onHand > 0) || infinity || negativeInventory === 'Yes');

export const isValidQty = ({
  qty = 0, onHand = 0, infinity = false, infinityPerStore = false, negativeInventory = false
}) => {
  if (isDigit(onHand) && parseFloat(onHand) > 0 && parseFloat(onHand) >= parseFloat(qty)) {
    return true;
  }
  if (infinity || infinityPerStore || negativeInventory === 'Yes') {
    return true;
  }
  return false;
};

export const getAvailabilityInStore = (onHand = 0, store = '') => {
  let value = 0;
  if (onHand) {
    onHand.forEach((item) => {
      if (item.store === store && item.amount) value = item.amount;
    });
  }
  return value;
};

export const paymentModeOptions = [
  {
    label: 'Card Token',
    value: 'useCardToken'
  },
  {
    label: 'Credit Card Form',
    value: 'useCardForm'
  }
];

export const discountDocument = {
  isRegular: doc => doc && !doc.customerPoints,
  isCustomerPoints: doc => doc && doc.customerPoints
};

export const getSafeQuantity = (isAvail, newQty) => isValue(isAvail) && isAvail ? fixedTo2(newQty) : 1;

export const isAvailOnHand = (quantity, infinity, onHand, store) => {
  if (isObject(store) && !isEmptyObject(store) && store.negativeInventory === 'Yes') return true;
  return parseFloat(quantity) < parseFloat(onHand) || infinity;
};

const findStore = (storeName, item) => item.store === storeName;

export const getOnHand = (item, store) => {
  if (!(isObject(item) && !isEmptyObject(item)) || !(isObject(store) && !isEmptyObject(store))) return 0;
  const {onHand} = item;
  const {name: storeName} = store;
  if (!(isArray(onHand) && !isEmptyArray(onHand))) return 0;

  const onHandObj = onHand.find(o => findStore(storeName, o)) || {};
  if (!(isObject(onHandObj) && !isEmptyObject(onHandObj))) return 0;
  const validOnHand = onHandObj.amount && isDigit(onHandObj.amount) && parseFloat(onHandObj.amount) > 0;
  return validOnHand ? parseFloat(onHandObj.amount) : 0;
};

export const getInfinityPerStore = (item, store) => {
  if (!(isObject(item) && !isEmptyObject(item)) || !(isObject(store) && !isEmptyObject(store))) return false;
  const {onHand} = item;
  const {name: storeName} = store;
  if (!(isArray(onHand) && !isEmptyArray(onHand))) {
    if (item.infinityPerStore) return item.infinityPerStore;
    return 0;
  }

  const onHandObj = onHand.find(o => findStore(storeName, o)) || {};
  if (!(isObject(onHandObj) && !isEmptyObject(onHandObj))) return 0;

  const {infinityPerStore} = onHandObj;
  return infinityPerStore;
};

export const containsItem = (list, name) => {
  if (isArray(list) && !isEmptyArray(list)
    && isString(name) && !isEmptyString(name)) {
    const array = list.map(c => {
      if (isString(c) && !isEmptyString(c)) {
        return c.trim().toLowerCase()
      }
      return false;
    }).filter(o => o) || [];
    const canApply = array.includes(name.trim().toLowerCase());
    return canApply;
  }
  return false;
}

export const isApplicableToItem = (applyTo, item) => {
  if (!(isObject(applyTo) && !isEmptyObject(applyTo))) return false;
  if (applyTo.all) return true;
  if (!(isObject(item) && !isEmptyObject(item))) return false;

  const {category, name} = item;
  const {categories, products} = applyTo;
  const canApplyCategory = containsItem(categories, category);
  const canApplyProduct = containsItem(products, name);
  return canApplyCategory || canApplyProduct;
};

export const isSubtotalHigherThanMinimum = (minSubtotal, subTotal) => (
  !minSubtotal || (parseFloat(subTotal) > parseFloat(minSubtotal))
);

export const isAlreadyActive = dateStart => (
  !dateStart || new Date(dateStart) < new Date()
);

export const isStillActive = dateEnd => (
  !dateEnd || new Date(dateEnd) > new Date()
);

export const canApplyDiscountToOrder = (discount, subTotal) => {
  if (!(isObject(discount) && !isEmptyObject(discount))) {
    return false;
  }
  const {minSubtotal, range: {dateStart, dateEnd} = {}} = discount;
  return isSubtotalHigherThanMinimum(minSubtotal, subTotal)
    && isAlreadyActive(dateStart)
    && isStillActive(dateEnd)
    && discount.isActive;
};

export const canApplyDiscountToItem = (item, discount, subTotal = 0) => {
  if (!(isObject(item) && !isEmptyObject(item))
    || !(isObject(discount) && !isEmptyObject(discount))) {
    return false;
  }
  const {applyTo} = discount;
  return (canApplyDiscountToOrder(discount, subTotal) && isApplicableToItem(applyTo, item));
};

export const adjustAmountToIncrements = (amount, rewardIncrements) => {
  if (!!rewardIncrements && parseFloat(rewardIncrements) > 0) {
    return parseFloat(amount) - (parseFloat(amount) % parseFloat(rewardIncrements));
  }
  return amount;
};

export const getRewards = (totalPoints, pointsConditions) => {
  const { customerPoints = 0, amount: rewardAmount = 0, rewardIncrements = 0 } = pointsConditions;
  if (!totalPoints || !customerPoints) return 0;
  const rewards = parseFloat(totalPoints) / parseFloat(customerPoints) * parseFloat(rewardAmount);
  return adjustAmountToIncrements(rewards, rewardIncrements);
};

export const canBeUsedRewards = (rewards, pointsConditions) => {
  const { rewardIncrements = 0 } = pointsConditions;
  return parseFloat(rewards) > 0 && parseFloat(rewardIncrements) <= parseFloat(rewards);
};

export const getQTYCoef = (item) => {
  return isRental(item) ? getRentalTotalQty(item) : item.quantity;
};

export const getPercentage = (amount, percent) => {
  if (!(isValue(percent) && isDigit(percent))) return 0;
  if (isValue(amount) && isDigit(amount)) {
    return parseFloat(amount) / 100 * parseFloat(percent);
  }
  return 0;
};

export const getDiscountedAmount = (amount, percent) => {
  if (!(isValue(percent) && isDigit(percent))) return amount;
  if (isValue(amount) && isDigit(amount)) {
    return parseFloat((amount / 100) * (100 - percent)).toFixed(2);
  }
  return amount;
};

export const updateAppliedItemDiscounts = (item, newDiscountDetails) => {
  // percent discount applies to item
  // dollar discount applies to order subtotal
  if (isObject(item) && !isEmptyObject(item)
    && isObject(newDiscountDetails) && !isEmptyObject(newDiscountDetails)) {

    const {source} = newDiscountDetails;
    const {appliedDiscounts} = item;

    if (source === 'individualDiscount') {
      if (isArray(appliedDiscounts) && !isEmptyArray(appliedDiscounts)) {
        // only one percent discount applies to item price
        const newAppliedDiscounts = ((appliedDiscounts
          .filter(o => o.source !== 'individualDiscount') || [])
          .filter(o => o.source !== 'volumeDiscounts') || [])
          .filter(o => o.type !== 'percent') || [];
        newAppliedDiscounts.push(newDiscountDetails);
        item.appliedDiscounts = newAppliedDiscounts;
      } else {
        item.appliedDiscounts = [newDiscountDetails];
      }
    } else if (source === 'coupon') {
      if (isArray(appliedDiscounts) && !isEmptyArray(appliedDiscounts)) {
        // only one percent discount applies to item price
        const newAppliedDiscounts = (appliedDiscounts
          .filter(o => o.source !== 'coupon') || [])
          .filter(o => o.type !== 'percent') || [];
        newAppliedDiscounts.push(newDiscountDetails);
        item.appliedDiscounts = newAppliedDiscounts;
      } else {
        item.appliedDiscounts = [newDiscountDetails];
      }
    } else if (source === 'volumeDiscounts') {
      if (isArray(appliedDiscounts) && !isEmptyArray(appliedDiscounts)) {
        // only one discount applies to item price
        const newAppliedDiscounts = ((appliedDiscounts
          .filter(o => o.source !== 'individualDiscount') || [])
          .filter(o => o.source !== 'volumeDiscounts') || [])
          .filter(o => o.type !== 'percent') || [];
        newAppliedDiscounts.push(newDiscountDetails);
        item.appliedDiscounts = newAppliedDiscounts;
      } else {
        item.appliedDiscounts = [newDiscountDetails];
      }
    } else {
      if (isArray(appliedDiscounts) && !isEmptyArray(appliedDiscounts)) {
        // suppose individualDiscount and coupon was applied
        const newAppliedDiscounts = appliedDiscounts
          .filter(o => o.source !== 'discount') || [];
        newAppliedDiscounts.push(newDiscountDetails);
        item.appliedDiscounts = newAppliedDiscounts;
      } else {
        item.appliedDiscounts = [newDiscountDetails];
      }
    }
  }
};

export const getVolumeDiscountByItem = (item) => {
  const itemQty = getQTYCoef(item);
  const {volumeDiscounts = [], price = 0} = item;
  if (itemQty && isDigit(itemQty) && isArray(volumeDiscounts) && !isEmptyArray(volumeDiscounts)) {
    const volumeDiscountObject = volumeDiscounts.reduce((res, obj) => {
      if (isObject(obj) && !isEmptyObject(obj)) {
        const {list, type, name, range: {dateStart = '', dateEnd = ''} = {}, isActive} = obj;
        if (isActive && isAlreadyActive(dateStart) && isStillActive(dateEnd)
          && list && isArray(list) && !isEmptyArray(list)) {
          const foundVolumeDiscount = list.reduce((result, o) => {
            if (isObject(o) && !isEmptyObject(o)) {
              const {quantity: reqQty} = o;
              const {quantity: prevQty = 0} = result;
              if (reqQty && isDigit(reqQty) && parseFloat(prevQty) < parseFloat(reqQty)) {
                return parseFloat(reqQty) <= parseFloat(itemQty) ? o : result;
              }
            }
            return result;
          }, {});
          if (foundVolumeDiscount && isObject(foundVolumeDiscount) && !isEmptyObject(foundVolumeDiscount)) {
            const {quantity: prevQty = 0} = res;
            if (parseFloat(prevQty) < parseFloat(foundVolumeDiscount.quantity)) {
              return {...foundVolumeDiscount, type, name, range: obj.range, isActive};
            }
          }
        }
      }
      return res;
    }, {});
    if (isObject(volumeDiscountObject) && !isEmptyObject(volumeDiscountObject)) {
      const {type, discount} = volumeDiscountObject;
      if (type === 'percent') {
        const discountAmount = getPercentage(price, discount);
        const discountedPrice = getDiscountedAmount(price, discount);
        item.discountedPrice = discountedPrice;
        if (parseFloat(price) >= parseFloat(discountedPrice) && parseFloat(discountAmount) > 0) {
          const details = {
            type: 'volumeDiscount',
            discountType: 'percent',
            discountAmount,
            discountedPrice,
            doc: volumeDiscountObject
          };
          updateAppliedItemDiscounts(item, details);
          return discountAmount;
        }
      }
      if (type === 'dollar' && parseFloat(price) >= parseFloat(discount) && parseFloat(discount) > 0) {
        item.discountedPrice = discount;
        const discountAmount = toSubtract(price, discount);
        const details = {
          type: 'volumeDiscount',
          discountType: 'dollar',
          discountAmount: discountAmount,
          discountedPrice: discount,
          doc: volumeDiscountObject
        };
        updateAppliedItemDiscounts(item, details);
        return discountAmount;
      }
    }
  }
  return 0;
};

export const getIndividualDiscountTotal = (items, volumeDiscountsAllowed) => {
  const total = items.reduce((amount, item) => {
    // reset appliedDiscounts
    item.appliedDiscounts = [];
    if (isObject(item) && !isEmptyObject(item)) {
      const qty = getQTYCoef(item);
      const {individualDiscount = 0, price = 0} = item;
      if (isDigit(individualDiscount) && individualDiscount) {
        // only individualDiscount or volumeDiscounts can be applied
        const discountAmount = getPercentage(price, individualDiscount);
        amount += toMultiply(discountAmount, qty);
        const discountedPrice = getDiscountedAmount(price, individualDiscount);
        item.discountedPrice = discountedPrice;

        const details = {
          type: 'individualDiscount',
          discountType: 'percent',
          discountAmount,
          discountedPrice
        };
        updateAppliedItemDiscounts(item, details);
      } else if (volumeDiscountsAllowed) {
        // only individualDiscount or volumeDiscounts can be applied
        const discountAmount = getVolumeDiscountByItem(item);
        if (discountAmount && parseFloat(discountAmount) > 0) {
          amount += toMultiply(discountAmount, qty);
        }
      }
    }
    return amount;
  }, 0);
  return total.toFixed(2);
};

export const getSubTotal = (items) => {
  const total = items.reduce((total, item) => {
    if (isObject(item) && !isEmptyObject(item)) {
      const qty = getQTYCoef(item);
      if (isValue(qty) && isDigit(qty)) {
        item.subtotal = (item.price * qty).toFixed(2);
        total += +item.subtotal;
      }
    }
    return total;
  }, 0);
  return total.toFixed(2);
};

export const checkCoupon = (availableDiscount, items) => {
  const subTotal = getSubTotal(items);
  if (!canApplyDiscountToOrder(availableDiscount, subTotal)) {
    return false;
  }
  const {coupon} = availableDiscount
  if (availableDiscount && !availableDiscount.activated) {
    if (!coupon.limited) {
      coupon.numberOfUses += 1;
    }
    availableDiscount.activated = true;
    coupon.used = true;
    // Coupons.saveOne(coupon._id, coupon);
  }
};

export const getUndiscountedAmount = (items) => {
  const total = items.reduce((amount, item) => {
    if (isObject(item) && !isEmptyObject(item)) {
      const qty = getQTYCoef(item);
      const {individualDiscount = 0, price = 0} = item;
      if (isDigit(individualDiscount) && individualDiscount) {
        amount += toMultiply(price, qty)
      }
    }
    return amount;
  }, 0);
  return total.toFixed(2);
};

export const hasSpecialDiscount = (item, specialDiscounts) => specialDiscounts.some(name => item[name]);

export const getCouponTotal = (items, coupon, orderSubTotal, specialDiscounts) => {
  if (!(isArray(items) && !isEmptyArray(items))) return 0;
  if (!(isObject(coupon) && !isEmptyObject(coupon))) return 0;

  const subTotal = getSubTotal(items);
  const unDiscountedAmount = getUndiscountedAmount(items);
  const allowedAmount = subTotal <= unDiscountedAmount ? 0 : toSubtract(subTotal, unDiscountedAmount);

  if (isValue(allowedAmount) && isDigit(allowedAmount) && allowedAmount > 0) {
    const {discountType, amount: discountValue} = coupon;
    const total = items.reduce((amount, item) => {
      if (isObject(item) && !isEmptyObject(item)) {
        const canApply = canApplyDiscountToItem(item, coupon, orderSubTotal);
        if (canApply && !hasSpecialDiscount(item, specialDiscounts)) {
          if (!isDigit(discountValue) || discountValue <= 0) return amount;
          const {price} = item;
          const qty = getQTYCoef(item);
          item.coupon = coupon;

          if (discountType === 'percent') {
            const discountAmount = getPercentage(price, discountValue);
            const discountedPrice = getDiscountedAmount(price, discountValue);
            item.discountedPrice = discountedPrice;
            amount += toMultiply(discountAmount, qty);
            const discountDetail = {
              type: 'coupon',
              discountType: 'percent',
              discountAmount,
              discountedPrice,
              doc: coupon
            };
            updateAppliedItemDiscounts(item, discountDetail);
          } else if (discountType === 'dollar') {
            amount += toMultiply(price, qty);
            const discountDetail = {
              type: 'coupon',
              discountType: 'percent',
              discountAmount: discountValue,
              doc: coupon
            };
            updateAppliedItemDiscounts(item, discountDetail);
          }
        }
      }
      return amount;
    }, 0);

    if (discountType === 'dollar') {
      const sum = Math.min(total, discountValue);
      return Math.min(sum, subTotal);
    }
    return Math.min(total, subTotal);
  }
  return 0
};

export const sumArray = arr => {
  if (isArray(arr)) {
    const result = arr.reduce((sum, op) => {
      if (isDigit(op) && isDigit(op)) {
        return parseFloat(sum) + parseFloat(op);
      }
      return sum;
    }, 0);
    return +fixedTo2(result);
  }
  return 0;
};


const shouldApplyDiscount = (item, discount, subTotal, specDiscounts) => (
  isObject(item) && !isEmptyObject(item)
  && !hasSpecialDiscount(item, specDiscounts)
  && canApplyDiscountToItem(item, discount, subTotal));

const getPercentDiscount = ({discounts, items, subTotal, discountPreference, multiDiscountsAllowed, specialDiscounts}) => {
  const needMax = discountPreference === 'applyGreaterDiscount';

  const itemsWithDiscountDocs = items.reduce((arr, item) => {
    const {appliedDiscounts = []} = item;
    const hasAlreadyDiscount = isArray(appliedDiscounts) && !isEmptyArray(appliedDiscounts)
      ? appliedDiscounts.some(o => o.type !== 'discount' && o.discountType === 'percent') : false;

    const applyDiscounts = !hasAlreadyDiscount ? discounts.reduce((discountsList, discountObj) => {
      if (!multiDiscountsAllowed && discountsList.length) return discountsList;
      const {doc: discount} = discountObj;
      const {amount} = discount;
      if (!isDigit(amount) || amount <= 0) return discountsList;

      if (shouldApplyDiscount(item, discount, subTotal, specialDiscounts)) {
        return [...discountsList, discount];
      }
      return discountsList
    }, []) : [];
    item.applyDiscounts = applyDiscounts;
    return [...arr, item]
  }, []);

  const totalPercentDiscount = itemsWithDiscountDocs.reduce((result, item) => {
    const {price} = item;
    const {applyDiscounts} = item;
    const qty = getQTYCoef(item);

    if (needMax) {
      const max = applyDiscounts.reduce((prev, current) => (prev.amount > current.amount) ? prev : current, {})
      if (isObject(max) && !isEmptyObject(max)) {
        delete item.applyDiscounts
        const discountAmount = fixedTo2(getPercentage(price, max.amount));
        const discountedPrice = getDiscountedAmount(price, max.amount);
        item.discountedPrice = discountedPrice;
        const discountDetail = {
          doc: max,
          type: 'discount',
          discountType: 'percent',
          discountAmount,
          discountedPrice
        };
        updateAppliedItemDiscounts(item, discountDetail);
        return toAdd(result, toMultiply(discountAmount, qty));
      }
    } else {
      const min = applyDiscounts.reduce((prev, current) => (prev.amount < current.amount) ? prev : current, {})

      if (isObject(min) && !isEmptyObject(min)) {
        // delete item.applyDiscounts
        const discountAmount = fixedTo2(getPercentage(price, min.amount));
        const discountedPrice = getDiscountedAmount(price, min.amount);
        item.discountedPrice = discountedPrice;

        const discountDetail = {
          doc: min,
          type: 'discount',
          discountType: 'percent',
          discountAmount,
          discountedPrice
        };
        updateAppliedItemDiscounts(item, discountDetail);
        return toAdd(result, toMultiply(discountAmount, qty));
      }
    }
    return result;
  }, 0);
  return Math.min(+subTotal, totalPercentDiscount);
};

const getDollarDiscount = ({
                             discounts, items, subTotal, discountPreference, multiDiscountsAllowed,
                             specialDiscounts
                           }) => {
  const needMax = discountPreference === 'applyGreaterDiscount';

  const itemsWithDiscountDocs = items.reduce((arr, item) => {
    const {appliedDiscounts = []} = item;
    const hasAlreadyDiscount = isArray(appliedDiscounts) && !isEmptyArray(appliedDiscounts)
      ? appliedDiscounts.some(o => o.type !== 'discount' && o.discountType === 'dollar') : false;

    const applyDiscounts = !hasAlreadyDiscount ? discounts.reduce((discountsList, discountObj) => {
      if (!multiDiscountsAllowed && discountsList.length) return discountsList;
      const {doc: discount} = discountObj;
      const {amount} = discount;
      if (!isDigit(amount) || amount <= 0) return discountsList;

      if (shouldApplyDiscount(item, discount, subTotal, specialDiscounts)) {
        return [...discountsList, discount];
      }
      return discountsList
    }, []) : []
    item.applyDiscounts = applyDiscounts;
    return [...arr, item]
  }, []);

  const totalDollarDiscount = itemsWithDiscountDocs.reduce((result, item) => {
    const {applyDiscounts} = item;
    if (needMax) {
      const max = applyDiscounts.reduce((prev, current) => (prev.amount > current.amount) ? prev : current, {});
      if (isObject(max) && !isEmptyObject(max)) {
        delete item.applyDiscounts
        const discountDetail = {
          doc: max,
          type: 'discount',
          discountType: 'dollar',
          discountAmount: max.amount
        };
        updateAppliedItemDiscounts(item, discountDetail);
        return toAdd(result, max.amount)
      }
    } else {
      const min = applyDiscounts.reduce((prev, current) => (prev.amount < current.amount) ? prev : current, {})
      if (isObject(min) && !isEmptyObject(min)) {
        delete item.applyDiscounts
        const discountDetail = {
          doc: min,
          type: 'discount',
          discountType: 'dollar',
          discountAmount: min.amount
        };
        updateAppliedItemDiscounts(item, discountDetail);
        return toAdd(result, min.amount)
      }
    }
    return result;
  }, 0);

  return Math.min(+subTotal, totalDollarDiscount);
}

export const partition = (arr, fn) => {
  if (!isArray(arr) || isEmptyArray(arr)) return [[], []];
  return arr.reduce(
    (acc, val, i, arr) => {
      acc[fn(val, i, arr) ? 0 : 1].push(val);
      return acc;
    },
    [[], []]
  );
};

export const getDiscountTotal = ({
                                   items,
                                   discounts,
                                   orderSubTotal,
                                   multiDiscountsAllowed,
                                   discountPreference = 'applyGreaterDiscount',
                                   specialDiscounts
                                 }) => {
  if (!(isArray(items) && !isEmptyArray(items))) return 0;
  const subTotal = getSubTotal(items);
  const unDiscountedAmount = getUndiscountedAmount(items);
  const allowedAmount = subTotal <= unDiscountedAmount ? 0 : toSubtract(subTotal, unDiscountedAmount);
  if (isValue(allowedAmount) && isDigit(allowedAmount) && allowedAmount > 0) {
    const getPercentDiscounts = obj => obj.doc.discountType === 'percent';
    const regDiscounts = isArray(discounts) && !isEmptyArray(discounts)
      ? discounts.filter(obj => obj && obj.doc && discountDocument.isRegular(obj.doc)) || []
      : [];
    const [percentDiscounts = [], dollarDiscounts = []] = partition(regDiscounts, getPercentDiscounts);

    const params = {
      subTotal: orderSubTotal,
      multiDiscountsAllowed,
      discountPreference,
      specialDiscounts,
      items
    };

    // PERCENT DISCOUNTS
    params.discounts = percentDiscounts;
    const percentDiscountTotal = getPercentDiscount({...params});
    // DOLLAR DISCOUNTS
    params.discounts = dollarDiscounts;
    const dollarDiscountTotal = getDollarDiscount(params);
    const sum = toAdd(percentDiscountTotal, dollarDiscountTotal);
    return Math.min(+orderSubTotal, sum);
  }
};

