import React, { Component } from 'react';
import ec from 'ec-react15-lib';

import Button from '../controls/Button';
import TextInput from '../controls/TextInput';
import { Addon } from '../controls/StyledDiv';
import { Products } from '../../../services/ApiData';
import * as variableType from '../../../services/VariableType';
import shallowEqualsObj from '../../../services/ShallowEqualsObj';
import { onTriggerAction } from '../../../services/ContextHelpers';
import { getConvenienceFee, useConvenienceFee } from '../../../services/ConvenienceFeeHelpers';
import { getSafeAmount, isDigit, onAmountInput, toFixedFloat2 } from '../../../services/GeneralHelpers';

const { getStyling, setValue, getWritableValue, Dropdown, getValue } = ec;
const { isString, isEmptyString, isObject, isEmptyObject, isEmptyArray, isArray } = variableType;

const defaultItem = { name: '', description: '', price: '' };

const styled = {
  thead: {
    background: 'rgb(64, 64, 64)',
    color: 'white'
  }
};

const calcAmount = (items) => {
  return items.reduce((sum, curr) => {
    const { price } = curr;
    const safeValue = getSafeAmount(price);
    if (safeValue) {
      return parseFloat(sum) + parseFloat(safeValue);
    }
    return sum;
  }, 0);
};

const camalize = (str) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

class BillingItemsContainer extends Component {

  state = {items: []}

  componentWillMount() {
    const { context, props } = this.props;
    const column1 = getValue(props, 'column1', context, 0);
    const column2 = getValue(props, 'column2', context, 0);
    const count = getValue(props, 'count', context, 5) || 5;
    const customFields = [{ label: column1, id: camalize(column1)},
      { label: column2, id: camalize(column2) }, { label: 'Total', id: 'total' }];
    const newItems = Array.from({ length: count }, _ => ({ ...defaultItem, customFields }));
    const amount = calcAmount(newItems);
    this.onChangeTotals(amount, context);
    this.setState({ items: newItems })
    setValue('g:object.items', newItems, context);

    const onlineForm = getWritableValue('g:ecOptions.merchantOptions.onlineForm', this.props.context, {}) || {};
    if (isObject(onlineForm) && !isEmptyObject(onlineForm)) {
      const { store, department } = onlineForm;
      if (isString(department) && !isEmptyString(department)
        && isString(store) && !isEmptyString(store)) {
        onTriggerAction(Products.fetch(department, store), this.props.context);
      }
    }
  }

  onValidation = () => {
    const { context } = this.props;
    const prevWarnings = { ...getWritableValue('g:validation', context) || {} };
    const warnings = { ...prevWarnings };

    const amount = getWritableValue('g:object.payment.amount', context, '') || '';
    if (!(isDigit(amount) && +amount > 0)) {
      warnings.amount = "Please provide 'Amount'";
    } else {
      warnings.amount = '';
    }
    const { canSubmit, ...obj } = warnings;
    if (isObject(obj) && !isEmptyObject(obj) && Object.keys(obj).some(s => obj[s])) {
      warnings.canSubmit = false;
    } else {
      warnings.canSubmit = true;
    }
    if (!shallowEqualsObj(prevWarnings, warnings, shallowEqualsObj)) {
      setValue('g:validation', warnings, context);
    }
  };

  onChange = (index, field, value) => {
    const { context } = this.props;
    const { items } = this.state;
    const options = getWritableValue('g:productOptions', context, []) || [];

    if (isArray(items) && !isEmptyArray(items)) {
      const newItems = [...items];
      const item = newItems[index];
      const { customFields } = item;
      if (isObject(item) && !isEmptyObject(item)) {
        if (field === '_id') {
          const selected = options.find(o => o._id === value);
          if (selected && isObject(selected) && !isEmptyObject(selected)) {
            const { price: oldPrice, name, description: oldDescription } = selected;
            customFields[0].field = name;
            const newObject = { ...selected, oldPrice, customFields, oldDescription, description: '' };
            newItems[index] = newObject;
          } else {
            customFields[0].field = '';
            customFields[1].field = '';
            customFields[2].field = '';
            newItems[index] = {
              description: '',
              customFields,
              name: '',
              price: ''
            };
          }
        } else if (field === 'price') {
          const safeValue = getSafeAmount(value);
          customFields[2].field = safeValue;
          item[field] = safeValue;
          const newObject = { ...item, customFields };
          newItems[index] = newObject;
        } else if (field === 'description') {
          customFields[1].field = value;
          item[field] = value;
          const newObject = { ...item, customFields };
          newItems[index] = newObject;
        }
      }
      const amount = calcAmount(newItems);
      this.onChangeTotals(amount, context);
      setValue('g:object.items', newItems, this.props.context);
      this.setState({items: newItems})
      this.onValidation();
    }
  };

  onChangeTotals = (value, context) => {
    const applyFee = getWritableValue('g:useConvenienceFee', context, 0) || 0;
    const convenienceFeePercentage = getWritableValue('g:convenienceFeePercentage', context, 0);
    const convenienceFeeDollar = getWritableValue('g:convenienceFeeDollar', context, 0);
    const convenienceMinTotal = getWritableValue('g:convenienceMinTotal', context, 0);
    const convenienceMaxTotal = getWritableValue('g:convenienceMaxTotal', context, 0);

    if (applyFee) {
      if (useConvenienceFee(value, convenienceMinTotal, convenienceMaxTotal)) {
        const fee = getConvenienceFee(value, convenienceFeeDollar, convenienceFeePercentage);
        const totalAmount = parseFloat(value) + parseFloat(fee);
        setValue('g:object.payment.convenienceFee', +toFixedFloat2(fee), context);
        setValue('g:object.payment.amount', +toFixedFloat2(totalAmount), context);
      } else {
        setValue('g:object.payment.convenienceFee', 0, context);
        setValue('g:object.payment.amount', +toFixedFloat2(value), context);
      }
      setValue('g:object.payment.inputAmount', +toFixedFloat2(value), context);
    } else {
      setValue('g:object.payment.amount', +toFixedFloat2(value), context);
    }
  };

  onAdd = (params) => {
    const { props, context } = params;
    const products = getWritableValue('g:productOptions', this.props.context, []) || [];
    const column1 = getValue(props, 'column1', context, 0);
    const column2 = getValue(props, 'column2', context, 0);
    const defProd = isArray(products) && !isEmptyArray(products) && products.length === 1 ? products[0] : {};
    const customFields = [
      { label: column1, id: camalize(column1), field: defProd.name || '' },
      { label: column2, id: camalize(column2), field: defProd.description || '' },
      { label: 'Total', id: 'total', field: defProd.price || '' }
    ];
    const prod = { ...defaultItem, ...defProd, customFields };
    const items = getWritableValue('g:object.items', context, []) || [];
    const newItems = [...items, { ...prod }];
    const amount = calcAmount(newItems);
    this.onChangeTotals(amount, context);
    setValue('g:object.items', newItems, context);
    this.setState({items: newItems})
    this.onValidation();
  };

  onDelete = (index) => {
    const { context } = this.props;
    const items = getWritableValue('g:object.items', context, []) || [];
    const newItems = items.map((o, i) => (i !== index) ? o : '').filter(o => o) || []
    const amount = calcAmount(newItems);
    this.onChangeTotals(amount, context);
    setValue('g:object.items', newItems, context);
    this.setState({items: newItems})
  };

  render() {
    const { index, props, context, pos, childIndex } = this.props;
    const sp = { props, context, pos, childIndex };
    const optional = ['column1', 'column2', 'fixedPrice', 'floatCount', 'count'];
    const { styles, classes } = getStyling({ ...sp, optional, styling: ['Block', 'Visibility'] });
    if (styles === false) return null;

    const {items} = this.state;
    if (!(isArray(items) && !isEmptyArray(items))) return null;
    const products = getWritableValue('g:productOptions', this.props.context, []) || [];
    if (!(isArray(products) && !isEmptyArray(products))) return null;

    const options = [...products.map(o => ({...o, label: o.name, value: o._id})), {label: '- Please Select-', value: ''}];
    const { column1 = 'Item', column2 = 'Description', fixedPrice = false, floatCount = false  } = props;

    return (
      <div key={index} className={[...classes, 'text-sm-center'].join(' ')} style={styles} id='billing-items'>
        <table className='table table-border'>
          <thead style={styled.thead}>
            <tr>
              <th className='text-center' style={{ height: '40px' }}>#</th>
              <th className='text-center' style={{ height: '40px' }}>{column1}</th>
              <th className='text-center' style={{ height: '40px' }}>{column2}</th>
              <th className='text-center' style={{ width: '150px', height: '40px' }}>Total</th>
              {floatCount ? <th className='text-center' style={{ width: '50px', height: '40px' }}/> : null}
            </tr>
          </thead>
          <tbody>
            {items.map((item, i) => {
              const { _id = '', description, price } = item;
              return (
                <tr key={i}>
                  <td
                    className='text-center'
                    style={{ padding: '5px', paddingLeft: 0, verticalAlign: 'middle', fontWeight: 'bold' }}
                  >
                    {i + 1}.
                  </td>
                  <td style={{ padding: '5px' }}>
                    <Dropdown
                      value={_id}
                      options={options}
                      onChange={value => this.onChange(i, '_id', value)}
                    />
                  </td>
                  <td style={{ padding: '5px' }}>
                    <TextInput
                      field='description'
                      value={description}
                      onChange={(field, value) => this.onChange(i, field, value)}
                    />
                  </td>
                  {
                    !fixedPrice ? (
                      <td style={{ display: 'flex', paddingRight: 0, padding: '5px' }}>
                        <Addon className='input-group-addon'>$</Addon>
                        <TextInput
                          value={price}
                          field='price'
                          placeholder='0.00'
                          className='text-right'
                          replace={onAmountInput}
                          onChange={(field, value) => this.onChange(i, field, value)}
                        />
                      </td>
                    ) : (
                      <td style={{ textAlign: 'right', padding: '5px', paddingRight: 0,  }}>
                        ${price || '0.00'}
                      </td>
                    )
                  }
                  {
                    floatCount ? (
                      <td style={{ textAlign: 'right',  padding: '5px', paddingRight: 0 }}>
                        {i > 0 ? (
                          <Button
                            icon='close'
                            type='danger'
                            size='sm'
                            outlines={false}
                            onClick={() => this.onDelete(i)}
                          />
                        ) : null}
                      </td>
                    ) : null
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
        { floatCount ? (
          <Button
            icon='plus'
            type='primary'
            outlines={false}
            onClick={_ => this.onAdd(this.props)}
            label=' Add Another Tribute'
            style={{ marginRight: '5px' }}
          />
        ) : null}
      </div>
    );
  }
}

export default BillingItemsContainer;
