import React from 'react';
import ec from 'ec-react15-lib';
import styled from 'styled-components';

import { Button, NumberInput } from '../controls/index';

const { setValue, Logger, getWritableValue, getStyling } = ec;

const defaultCurrency = [5, 10, 25, 50];

const stylesComponent = {
  row: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row nowrap'
  },
  addon: {
    borderRadius: '5px 0 0 5px',
    zIndex: 10
  },
  input: {
    zIndex: 0,
    marginLeft: '-3px',
    background: '#fff'
  }
};

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    padding-right: 20px;
    flex: 1 0 150px;
    @media (max-width: 450px) {
      flex: auto;
      padding-right: 5px;
    }
`;

const Control = styled.div`
  display: flex;
  flex: 1 0 220px;
  @media (max-width: 450px) {
    flex: auto;
  }
`;

const Span = styled.div`
  @media (max-width: 450px) {
    font-size: 14px;
    min-width: 57px;
  }
`;

const Currency = ({ section, index, props, context, pos, childIndex }) => {
  Logger.of('render.InputNumber').info('section', section, 'index', index, 'props', props, 'pos=', pos);
  const sp = { props, context, pos, childIndex };
  const optional = [];
  const { styles, classes } = getStyling({
    ...sp, mandatory: [], optional, styling: ['Block', 'Text', 'Visibility']
  });
  if (styles === false) return false;
  const currency = getWritableValue('g:object.payment.currency', context) || {};

  const onChangeCount = (value, op) => {
    if (op === 'add') {
      currency[value] = currency[value] ? currency[value] += 1 : 1;
    }
    if (op === 'deduct') {
      currency[value] = (typeof currency[value] !== 'undefined') && currency[value] > 0 ? currency[value] -= 1 : 0;
    }
    const other = currency.other || 0;
    const amound = defaultCurrency.reduce((prev, cur) => (prev + ((currency[cur] || 0) * cur)), 0) + other;
    setValue('g:object.payment.currency', currency, context);
    setValue('g:object.payment.amount', amound, context);
  };

  const onChanceCustomCurrency = (value = 0) => {
    currency.other = value;
    const other = currency.other || 0;
    const amound = defaultCurrency.reduce((prev, cur) => (prev + ((currency[cur] || 0) * cur)), 0) + other;
    setValue('g:object.payment.currency', currency, context);
    setValue('g:object.payment.amount', amound, context);
  };

  const onReset = () => {
    setValue('g:object.payment.currency', {}, context);
    setValue('g:object.payment.amount', 0, context);
  };

  return (
    <div className={classes}>
      {defaultCurrency.map((parValue) => {
        return (
          <div key={parValue} style={stylesComponent.row} className='form-group'>
            <Title>
              <Span>${parseFloat(parValue).toFixed(2)}:</Span>
            </Title>
            <Control>
              <Button
                type='info' icon='minus' style={{ marginRight: '10px' }}
                onClick={() => onChangeCount(parValue, 'deduct')}
              />
              <span className='input-group-addon' style={stylesComponent.addon}>$</span>
              <NumberInput
                min={0}
                readOnly={true}
                onChange={onChangeCount}
                style={stylesComponent.input}
                value={currency[parValue] ? (currency[parValue] * parValue) : 0}
              />
              <Button
                type='warning' icon='plus' style={{ marginLeft: '10px' }}
                onClick={() => onChangeCount(parValue, 'add')}
              />
            </Control>
          </div>
        );
      })}
      <div style={stylesComponent.row} className='form-group'>
        <Title>
          <span style={{ flex: 1, paddingRight: 5 }}>Other:</span>
        </Title>
        <Control>
          <span className='input-group-addon' style={stylesComponent.addon}>$</span>
          <NumberInput
            min={0}
            style={stylesComponent.input}
            value={currency.other || 0}
            onChange={onChanceCustomCurrency}
          />
        </Control>
        <Button
          type='danger' onClick={onReset}
        >
          Reset All
        </Button>
      </div>
    </div>
  );
};

export default Currency;
