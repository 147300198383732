import { getWritableValue } from 'ec-react15-lib';

import * as pdfElements from '../../services/pdf';
import { checkItems } from '../../services/OrderHelpers';
import { getLine, getSpace } from '../../services/pdf/PdfElements';
import {isValue} from "../../services/VariableType";

const PdfDetails = ({ gen, props, context }) => {
  const defaultTpl = getWritableValue('g:ecOptions.merchantOptions.receipt.preview', context) || false;
  const isTplLarge = defaultTpl === 'A4' || !defaultTpl;
  const { width } = context;
  const stylesContext = gen.getStyling(props, context);
  const styles = { ...stylesContext, maxWidth: width };
  const { showDonation = false } = props; // default show Totals
  const { docs: { doc = {} } = {} } = context;

  const { totals = false, items: products = false, coupon: discount = false } = doc;

  if (pdfElements['InvoiceDetail']) {
    if (!isTplLarge) {
      gen.addElement(getSpace());
      gen.addElement(getLine(styles, context));
      gen.addElement(getSpace());
    }
    const invoiceElements = pdfElements['InvoiceDetail'].getElement({ ...doc, styles, isTplLarge });
    gen.addElement(invoiceElements);
  }

  if (pdfElements['Products']) {
    if (!isTplLarge && checkItems(products)) {
      gen.addElement(getSpace());
      gen.addElement(getLine(styles, context));
      gen.addElement(getSpace());
    }
    const productsTable = pdfElements['Products'].getElement({ products, styles, isTplLarge });
    gen.addElement(productsTable);
  }

  if (pdfElements['Totals'] && totals) {
    gen.addElement(getSpace('0.5'));
    const totalsElement = pdfElements['Totals'].getElement({ totals, showDonation, styles, isTplLarge });
    gen.addElement(totalsElement);
  }

  if (pdfElements['TransactionDiscount']) {
    const couponTable = pdfElements['TransactionDiscount'].getElement(discount, styles);
    gen.addElement(couponTable);
  }
  gen.addElement(getSpace());

  if (pdfElements['AmountDue'] && isValue(totals.amountDue) && parseFloat(totals.amountDue) > 0) {
    gen.addElement(getSpace());
    gen.addElement(getLine(styles, context));
    gen.addElement(getSpace());

    const amountDueElement = pdfElements['AmountDue'].getElement({ showDonation, ...totals, styles, isTplLarge });
    gen.addElement(amountDueElement);
    gen.addElement(getSpace('1.5'));
  }

  return false;
};

export default PdfDetails;
