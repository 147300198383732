import React from 'react';
import ec from 'ec-react15-lib';

const { Logger, getWritableValue, getStyling, Dropdown, triggerAction } = ec;

const getSerials = (serials, arr) => {
  const i = arr[0];
  const value = arr[1];
  const selectedSerials = serials || [];
  selectedSerials[i] = value;
  return selectedSerials.filter(item => item);
};

const ItemSerials = ({ section, index, props, context, pos, childIndex }) => {
  Logger.of('render.ItemSerials').info('section', section, 'index', index, 'props', props, 'pos=', pos);
  const sp = { props, context, pos, childIndex };
  const optional = [];
  const mandatory = ['selectedSerials', 'serials', 'hash', 'qty', 'onChange'];
  const { styles, classes } = getStyling({
    ...sp, mandatory, optional, styling: ['Block', 'Text', 'Visibility']
  });
  if (styles === false) return false;

  const hash = getWritableValue(props.hash, context, '');
  const qty = getWritableValue(props.qty, context, '');
  const serials = getWritableValue(props.serials, context, []) || [];
  const selectedSerials = getWritableValue(props.selectedSerials, context, []);
  const availableSerials = serials.filter(s => !s.sold);
  const serialsCount = qty > availableSerials.length ? availableSerials.length : qty;
  const defaultOptions = [{
    value: '',
    label: 'Select serial'
  }];

  return (<div className={classes.join(' ')} style={{ ...styles, display: 'flex', flexDirection: 'column' }} >
    {
      [...Array(serialsCount)].map((i, n) => {
        const key = `serial-${n}`;
        serials.forEach((serial) => {
          if (!serial.sold &&
            (selectedSerials.indexOf(serial.value) === -1
              || selectedSerials.indexOf(serial.value) === n)) {
            defaultOptions.push({
              value: serial.value,
              label: serial.value
            });
          }
        });
        const selected = selectedSerials && selectedSerials[n] ? selectedSerials[n] : '';
        if (defaultOptions && defaultOptions.length > 1) {
          return (
            <Dropdown
              options={defaultOptions} value={selected} key={key}
              onChange={
                val => triggerAction(props.onChange,
                  { ...context, value: [hash, getSerials(selectedSerials, [n, val])] })
              }
            />
          );
        }
        return false;
      })
    }
  </div>);
};

export default ItemSerials;
