import * as types from '../../services/VariableType';
import { getMoneyString, getStyles } from '../PaymentTypeHelpers';
import { generateTableElement, getObjectOfTextElements } from './PdfElements';

const { isArray, isEmptyArray, isEmptyString, isString, isObject, isEmptyObject } = types;

const getPatientsObject = (patients) => {
  if (isArray(patients) && !isEmptyArray(patients)) {
    return patients
      .reduce((result, item, i) => {
        const { labels = {}, values = {} } = result;
        if (isObject(item) && !isEmptyObject(item)) {
          const { firstName, lastName, account, amount } = item;
          const name = ([firstName, lastName].filter(o => o) || []).join(' ');
          if (name && isString(name) && !isEmptyString(name)) {
            labels[`patient${i}`] = `Patient ${i + 1}`;
            values[`patient${i}`] = name;
          }
          if (account && isString(account) && !isEmptyString(account)) {
            labels[`account${i}`] = 'Account';
            values[`account${i}`] = account;
          }
          if (amount && parseFloat(amount) > 0) {
            labels[`amount${i}`] = 'Amount';
            values[`amount${i}`] = getMoneyString(amount);
          }
        }
        return { labels, values };
      }, { labels: {}, values: {} });
  }
};

export const getElement = ({ patients, styles, isTplLarge }) => {
  const stylesCommon = getStyles(styles, isTplLarge).header;
  const { labels = {}, values = {} } = getPatientsObject(patients) || {};

  const stylesLabels = Object.keys(labels).reduce((prev, curr) => {
    return { ...prev, [curr]: stylesCommon.label };
  }, {});

  const stylesValues = Object.keys(values).reduce((prev, curr) => {
    return { ...prev, [curr]: stylesCommon.value };
  }, {});

  const objectLabels = getObjectOfTextElements(labels, stylesLabels);
  const objectValues = getObjectOfTextElements(values, stylesValues);

  const params = {
    headers: objectLabels,
    values: objectValues,
    ...stylesCommon.table
  };

  return generateTableElement(params);
};
