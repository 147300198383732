import { isArray, isEmptyArray, isString, isEmptyString } from '../VariableType';

const getTableWidths = (widths, pullRight) => {
  if (!pullRight && widths.length === 2) return widths;
  if (!pullRight && widths.length > 2) return widths.slice(0, 2);
  if (!pullRight && widths.length < 2) {
    return [...widths, ...[...Array(2 - widths.length || 0)].map(() => ('auto'))];
  }

  if (pullRight && widths.length === 3) return widths;
  if (pullRight && widths.length > 3) return widths.slice(0, 3);
  if (pullRight && widths.length < 3) {
    return [...widths, ...[...Array(3 - widths.length || 0)].map(() => ('auto'))];
  }
};

export const pdfTemplateSize = {
  width: {
    A4: 595.28,
    '80mm': 205,
    '50mm': 130
  },
  height: {
    A4: 841.89,
    '80mm': 'auto',
    '50mm': 'auto'
  }
};

export const messureText = (string, fontSize) => {
  let ctx;

  if (typeof document !== 'undefined') {
    ctx = document.createElement('canvas').getContext('2d');
  } else {
    return 0;
  }
  ctx.font = `${fontSize}px`;

  // Calculate the width of the encoding
  const size = ctx.measureText(string).width;

  return size;
};

export const chunkString = (value, length) => {
  if (isArray(value) && !isEmptyArray(value)) {
    return value.reduce((result, curr) => {
      if (isString(curr) && !isEmptyString(curr)) {
        result.push(value.match(new RegExp('.{1,' + length + '}', 'g')));
      } else if (isArray(curr) && !isEmptyArray(curr)) {
        result.push(curr);
      }
      return result;
    });
  }
  if (isString(value) && !isEmptyString(value)) {
    return value.match(new RegExp('.{1,' + length + '}', 'g'));
  } else if (isArray(value) && !isEmptyArray(value)) {
    return value;
  }
};

export const fitText = (value, fontSize, maxWidth) => {
  if (isString(value) && !isEmptyString(value)) {
    const str = value.toString();
    const width = messureText(str, fontSize);
    if (str && width > maxWidth) {
      const { length } = str.toString();
      const op = Math.ceil(maxWidth * length);
      const newLength = Math.ceil(op / width);
      return chunkString(str, newLength);
    }
  }
  return value;
};

export const getTextElement = (value, styles) => {
  const { fontSize, maxWidth } = styles;
  if (fontSize && maxWidth && value) {
    const adjustValue = fitText(value, styles.fontSize, styles.maxWidth);
    return { text: adjustValue, ...styles };
  }
  return value ? { text: value, ...styles } : '';
};

export const generateTableElement = ({ headers = {}, values = {}, headerRows = 0, margin = [0, 0, 0, 0],
                                       layout = 'noBorders', widths, pullRight = false }) => {
  const emptyCell = getTextElement(' ', { border: [false, false, false, false] });
  const body = Object.keys(values).reduce((prev, curr) => {
    if (values[curr]) {
      const row = [];
      if (pullRight) row.push(emptyCell);
      row.push(headers[curr] || emptyCell);
      row.push(values[curr]);
      prev.push(row);
    }
    return prev;
  }, []);

  const widthsDefault = !pullRight ? ['auto', '*'] : ['*', 'auto', '*'];
  const safeWidth = getTableWidths(widths || widthsDefault, pullRight);
  return {
    table: { body, widths: safeWidth, headerRows },
    margin,
    layout
  };
};

export const generateColumnElement = ({ headers = {}, values = {}, styles = {} }) => {
  const emptyCell = getTextElement(' ', { width: 'auto' });
  return Object.keys(values).reduce((prev, curr) => {
    if (values[curr]) {
      const row = [];
      row.push(headers[curr] || emptyCell, values[curr]);
      prev.push({ columns: row, ...styles[curr] });
    }
    return prev;
  }, []);
};

export const getObjectOfTextElements = (object, styles = {}) => Object.keys(object).reduce((prev, curr) => {
  const value = getTextElement(object[curr], styles[curr]);
  if (value) return { ...prev, [curr]: value };
  return prev;
}, {});

export const getArrOfTextElements = (arr, styles) => arr.reduce((prev, curr) => {
  if (curr) prev.push(getTextElement(curr, styles[curr]));
  return prev;
}, []);

export const getLine = (styles = {}, context = {}, widthCustom = 0) => {
  const { margins = [0, 0, 0, 0], length = 2, space = 2, lineWidth = 0.5 } = styles;
  const width = widthCustom || context.width || 100;
  return {
    canvas: [
      {
        type: 'line',
        x1: margins[3] || 0,
        y1: margins[0] || 0,
        x2: width - (margins[3] || 0),
        y2: margins[0] || 0,
        lineWidth,
        lineColor: 'black',
        dash: { length, space }
      }
    ]
  };
};

export const getSpace = (lineHeight = '0.3') => {
  return { text: '\n', lineHeight };
};
