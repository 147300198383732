import React, { Component } from 'react';
import ec from 'ec-react15-lib';

import {onTriggerAction} from '../../../services/ContextHelpers';
import {Products} from '../../../services/ApiData';

const { Logger, getWritableValue, getStyling, Dropdown, triggerAction } = ec;

class Modifiers extends Component {

  componentWillMount() {
    const { props, context } = this.props;
    const options = getWritableValue(props.options, context, []);
    if (options && options.length > 0) {
      for (let i = 0, l = options.length; i < l; i++) {
        const department = getWritableValue('g:ecOptions.merchantOptions.onlineForm.department', context, '') || '';
        const store = getWritableValue('g:ecOptions.merchantOptions.onlineForm.store', context, '') || '';
        onTriggerAction(Products.fetchByCategory(department, store, options[i].label), context);
      }
    }
  }

  onChangeValue = (value) => {
    const { props, context } = this.props;
    const hash = getWritableValue(props.hash, context, '');
    triggerAction(props.onChange, { ...context, value: [hash, value] });
  };

  render() {
    const { section, index, props, context, pos, childIndex } = this.props;
    Logger.of('render.Modifiers').info('section', section, 'index', index, 'props', props, 'pos=', pos);
    const sp = {props, context, pos, childIndex};
    const optional = [];
    const mandatory = ['options', 'selected', 'hash', 'onChange'];
    const {styles, classes} = getStyling({
      ...sp, mandatory, optional, styling: ['Block', 'Text', 'Visibility']
    });
    if (styles === false) return false;

    const hash = getWritableValue(props.hash, context, '');
    const subcategories = getWritableValue(props.options, context, []) || [];
    const modifiers = getWritableValue(props.selected, context, []) || [];
    const mode = getWritableValue('newOrder:mode', context, '') || '';
    const editable = mode === 'cart';

    return (<div className={classes.join(' ')} style={{ ...styles, display: 'flex', flexDirection: 'column' }} >
      {
        subcategories && subcategories.map(option => {
          const {rows = []} = getWritableValue(`g:${option.label}`, context, {}) || {};
          const op = rows
            .map(o => ({...o, label: o.name.toUpperCase(), value: o.name}))

          op.unshift({label: '- SELECT -', value: ''})
          const {value: selected = ''} = modifiers.find(o => o.category === option.label) || {};
          return (
            <div key={`${hash}.${option.value}`} style={{ display: 'flex', marginBottom: '15px' }}>
              <div style={{ color: '#636c72', marginRight: '15px', minWidth: '100px' }}>{option.label}</div>
              {
                editable ? (
                  <Dropdown
                    onChange={value => this.onChangeValue(op.find(o => o.value === value))}
                    value={selected}
                    options={op}
                  />
                ) : (
                  <div>
                    {
                      selected
                        ? selected.toUpperCase()
                        : op.filter(o => o.value).map(o => o.value.toUpperCase()).join(', ')
                    }
                  </div>
                )
              }
            </div>)
          }
        )
      }
    </div>);
  }
}

export default Modifiers;
