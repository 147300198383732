import React from 'react';
import { isString, isEmptyString } from '../../../../services/VariableType';

const DeviceKey = ({ deviceKey, deviceID, registerId }) => {
  const device = [deviceKey, deviceID, registerId].find(v => isString(v) && !isEmptyString(v));
  if (!(isString(device) && !isEmptyString(device))) return null;
  const length = device.length;
  if (length > 3) {
    const l = Math.min(~~(length/3), 4);
    const n = Math.min(length - l * 2, 4);
    const prefix = device.substring(0, l);
    const suffix = device.substring(length - l);
    const maskedCharacters = '*'.repeat(n);
    return (
      <div>
        <span>Device Key:&nbsp;</span>
        <b>{`${prefix}${maskedCharacters}${suffix}`}</b>
      </div>
    );
  }
  return (
    <div>
      <span>Device Key: </span>
      <b>{'*'.repeat(length)}</b>
    </div>
  );
};

export default DeviceKey;
