import { generateTableElement, getObjectOfTextElements } from './PdfElements';
import { capitalizeTxt, getJoinDates } from '../GeneralHelpers';
import { hardRequirementError } from '../ValidatorHelpers';
import { getScheduleDates } from '../PaymentTypeHelpers';
import { toLocalShortDate } from '../DateHelpers';

export const getElement = ({ stylesPaymentType = {}, ...paymentType }) => {
  const { startDate, schedule, timesToRecur } = paymentType;

  const scheduleDates = getJoinDates(getScheduleDates({ ...paymentType }));
  const scheduleString = !hardRequirementError(schedule) ? capitalizeTxt(schedule) : '';
  const startDateString = !hardRequirementError(startDate) ? toLocalShortDate(startDate) : '';

  const labels = {
    scheduleString: 'Schedule:',
    startDateString: 'Start Date:',
    timesToRecurString: `${timesToRecur} times to Recur:`
  };

  const values = { scheduleString, startDateString, timesToRecurString: scheduleDates };

  const stylesLabels = Object.keys(labels)
    .reduce((prev, curr) => ({ ...prev, [curr]: stylesPaymentType.label || {} }), {});
  const stylesValues = Object.keys(values)
    .reduce((prev, curr) => ({ ...prev, [curr]: stylesPaymentType.value || {} }), {});

  const objectValues = getObjectOfTextElements(values, stylesValues);
  const objectLabels = getObjectOfTextElements(labels, stylesLabels);

  const params = {
    headers: objectLabels,
    values: objectValues,
    ...stylesPaymentType.table
  };

  return generateTableElement(params);
};
