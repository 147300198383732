import React, { Component } from 'react';
import ec from 'ec-react15-lib';

import PaymentType from './PaymentType';
import TextInput from '../controls/TextInput';
import { Label, Value, Addon } from '../controls/StyledDiv';
import shallowEqualsObj from '../../../services/ShallowEqualsObj';
import { paymentModeOptions } from '../../../services/CartHelpers';
import * as helpers from './../../../services/ConvenienceFeeHelpers';
import { getMoneyString } from '../../../services/PaymentTypeHelpers';
import { isObject, isString, isEmptyString, isEmptyObject } from '../../../services/VariableType';
import { fixedTo2, isDigit, getSafeAmount, onAmountInput } from '../../../services/GeneralHelpers';

const { useConvenienceFee, getConvenienceFee } = helpers;
const { getWritableValue, getValue, getStyling, setValue, Dropdown } = ec;

class TransactionAmountContainer extends Component {

  state = { cardToken: '' };

  componentWillMount() {
    this.onToggleConvenienceFee(this.props);
  }

  onToggleConvenienceFee = (nextProps) => {
    const { props, context } = nextProps;
    const applyDepartmentFee =
      getWritableValue('g:ecOptions.merchantOptions.onlineForm.applyConvenienceFee', context, '');

    const convenienceFeePercentage = getValue(props, 'convenienceFeePercentage', context, 0);
    const convenienceFeeDollar = getValue(props, 'convenienceFeeDollar', context, 0);

    if (!applyDepartmentFee && (+convenienceFeePercentage > 0 || +convenienceFeeDollar > 0)) {
      const minTotal = getValue(props, 'minTotal', context, '');
      const maxTotal = getValue(props, 'maxTotal', context, '');
      setValue('g:useConvenienceFee', true, context);
      setValue('g:convenienceFeeDollar', convenienceFeeDollar, context);
      setValue('g:convenienceFeePercentage', convenienceFeePercentage, context);
      setValue('g:convenienceMinTotal', minTotal, context);
      setValue('g:convenienceMaxTotal', maxTotal, context);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const cardToken = getWritableValue('g:object.payment.cardToken', this.props.context, '') || '';
    if ((prevState.cardToken !== cardToken) && cardToken) {
      const usePaymentMethodDefault = cardToken ? 'useCardToken' : '';
      setValue('g:helpers.usePaymentMethod', usePaymentMethodDefault, this.props.context);
      this.setState({ cardToken });
    }
    const applyDepartmentFee =
      getWritableValue('g:ecOptions.merchantOptions.onlineForm.applyConvenienceFee', this.props.context, '');
    const departmentConvenienceFee = getWritableValue('g:departmentConvenienceFee', this.props.context, {}) || {};
    const departmentConvenienceFeePrev = getWritableValue('g:departmentConvenienceFee', prevProps.context, {}) || {};
    if (!shallowEqualsObj(departmentConvenienceFee, departmentConvenienceFeePrev, shallowEqualsObj)) {
      if (isObject(departmentConvenienceFee) && !isEmptyObject(departmentConvenienceFee)) {
        setValue('g:convenienceFeeDollar', departmentConvenienceFee.convenienceFeeDollar, this.props.context);
        setValue('g:convenienceFeePercentage', departmentConvenienceFee.convenienceFeePercentage, this.props.context);
        setValue('g:convenienceMinTotal', departmentConvenienceFee.minTotal, this.props.context);
        setValue('g:convenienceMaxTotal', departmentConvenienceFee.maxTotal, this.props.context);
      } else {
        const { context, props } = this.props;
        const convenienceFeePercentage = getValue(props, 'convenienceFeePercentage', context, 0);
        const convenienceFeeDollar = getValue(props, 'convenienceFeeDollar', context, 0);
        const minTotal = getValue(props, 'minTotal', context, '');
        const maxTotal = getValue(props, 'maxTotal', context, '');
        setValue('g:convenienceFeeDollar', !applyDepartmentFee ? convenienceFeeDollar : 0, context);
        setValue('g:convenienceFeePercentage', !applyDepartmentFee ? convenienceFeePercentage : 0, context);
        setValue('g:convenienceMinTotal', minTotal, context);
        setValue('g:convenienceMaxTotal', maxTotal, context);
      }
      const inputAmount = getWritableValue('g:object.payment.inputAmount', this.props.context);
      this.onChange('inputAmount', inputAmount)
    }
  }

  getValues = (field, value = '') => {
    const { context, props } = this.props;
    const applyDepartmentFee =
      getWritableValue('g:ecOptions.merchantOptions.onlineForm.applyConvenienceFee', context, '');

    const amount = getWritableValue('g:object.payment.amount', context, 0) || '';
    const inputAmount = getWritableValue('g:object.payment.inputAmount', context, 0) || '';
    const fee = getWritableValue('g:object.payment.convenienceFee', context, 0) || 0;

    const departmentConvenienceFee = getWritableValue('g:departmentConvenienceFee', context, {}) || {};

    if (isObject(departmentConvenienceFee) && !isEmptyObject(departmentConvenienceFee)) {
      return (isString(field) && !isEmptyString(field))
        ? { ...departmentConvenienceFee, amount, inputAmount, fee, [field]: value }
        : { ...departmentConvenienceFee,  amount, inputAmount, fee };
    }
    const convenienceFeePercentage = !applyDepartmentFee ? getValue(props, 'convenienceFeePercentage', context, 0) : 0;
    const convenienceFeeDollar = !applyDepartmentFee ? getValue(props, 'convenienceFeeDollar', context, 0) : 0;
    const minTotal = getValue(props, 'minTotal', context, 0);
    const maxTotal = getValue(props, 'maxTotal', context, 0);
    const params = { convenienceFeePercentage, convenienceFeeDollar, inputAmount, minTotal, maxTotal, fee, amount };
    return (isString(field) && !isEmptyString(field)) ? { ...params, [field]: value } : params;
  };

  onChange = (field, value) => {
    const { context } = this.props;
    const params = this.getValues(field, value);

    if (field === 'inputAmount') {
      const safeValue = getSafeAmount(value);
      const { convenienceFeePercentage, convenienceFeeDollar, minTotal, maxTotal } = params;
      if (useConvenienceFee(+safeValue, minTotal, maxTotal)) {
        const fee = getConvenienceFee(safeValue, convenienceFeeDollar, convenienceFeePercentage);
        const totalAmount = parseFloat(safeValue) + parseFloat(fee);
        setValue('g:object.payment.convenienceFee', +fixedTo2(fee), context);
        setValue('g:object.payment.amount', +fixedTo2(totalAmount), context);
      } else {
        setValue('g:object.payment.convenienceFee', 0, context);
        setValue('g:object.payment.amount', +safeValue, context);
      }
      setValue('g:object.payment.inputAmount', +safeValue, context);
    }
    if (field === 'amount') {
      const safeValue = getSafeAmount(value);
      setValue('g:object.payment.amount', +safeValue, context);
    }
  };

  onChangePaymentMode = (value) => {
    const { context } = this.props;
    setValue('g:helpers.usePaymentMethod', value, context);
    if (value !== 'useCardToken') {
      setValue('g:object.payment.cardToken', '', context);
    } else {
      setValue('g:object.payment.cardToken', this.state.cardToken, context);
    }
  };

  render() {
    const { index, props, context, pos, childIndex } = this.props;
    const sp = { props, context, pos, childIndex };
    const optional = ['container', 'title', 'maxTotal', 'minTotal',
      'convenienceFeeDollar', 'convenienceFeePercentage', 'readOnly'];
    const { styles, classes } = getStyling({ ...sp, optional, styling: ['Block', 'Visibility'] });
    if (styles === false) return null;
    const isFree = getWritableValue('g:object.payment.isFree', context, false) || false;
    if (isFree) return null;

    const { inputAmount, amount, convenienceFeeDollar, convenienceFeePercentage, fee } = this.getValues();
    const readOnly = getValue(props, 'readOnly', context, false);
    const title = getValue(props, 'title', context, 'Transaction Amount');
    const showConvenienceFee = (isDigit(convenienceFeeDollar) && +convenienceFeeDollar > 0)
      || (isDigit(convenienceFeePercentage) && +convenienceFeePercentage > 0);
    const label = showConvenienceFee ? 'Total Amount' : title;
    const usePaymentRecurring = getWritableValue('g:usePaymentRecurring', context, false) || false;
    const validation = getWritableValue('g:validation.amount', context, '') || '';
    const validationCardNumber = getWritableValue('g:validation.cardNumber', context, '') || '';
    const cardToken = getWritableValue('g:object.payment.cardToken', context, '') || '';
    const usePaymentMethod = getWritableValue('g:helpers.usePaymentMethod', context);
    const showCharge = getWritableValue('g:helpers.showCharge', context, '');
    const station = getWritableValue('g:station', context, '');

    const paymentMethod = !(showCharge && !!station) ? (
      <div>
        {
          usePaymentMethod ? (
            <div className='form-group'>
              <div style={{ display: 'flex' }}>
                <Label>
                  Use payment method:&nbsp;
                </Label>
                <Value>
                  <Dropdown
                    id='usePaymentMethod'
                    value={usePaymentMethod}
                    options={paymentModeOptions}
                    onChange={this.onChangePaymentMode}
                  />
                </Value>
              </div>
            </div>
          ) : null
        }
        {
          usePaymentMethod === 'useCardToken' ? (
            <div className='form-group'>
              <div style={{ display: 'flex' }}>
                <Label>
                  Card Token:&nbsp;
                </Label>
                <Value>
                  <div style={{ fontWeight: 'bold', paddingRight: 10 }}>
                    {cardToken.slice(0, -3).replace(/[^-]/g, 'X')}{cardToken.slice(-3)}
                  </div>
                </Value>
              </div>
            </div>
          ) : null
        }
      </div>
    ) : null;

    return (
      <div key={index} className={classes.join(' ')} style={styles}>
        {
          usePaymentRecurring ? (
            <PaymentType {...{ ...this.props, props: { '@value': 'g:object.paymentType' } }} />
          ) : null
        }
        {
          showConvenienceFee ? (
            <div>
              <div className='form-group' style={{ display: 'flex' }}>
                <Label>
                  Transaction Amount:&nbsp;
                </Label>
                {
                  !readOnly || readOnly === 'false' ? (
                    <Value>
                      <Addon className='input-group-addon'>$</Addon>
                      <TextInput
                        placeholder=''
                        id='inputAmount'
                        value={inputAmount}
                        field={'inputAmount'}
                        className='text-right'
                        replace={onAmountInput}
                        onChange={this.onChange}
                      />
                    </Value>
                  ) : (
                    <div style={{ fontWeight: 'bold', paddingRight: 10 }}>
                      {getMoneyString(inputAmount)}
                    </div>
                  )
                }
              </div>
              <div className='form-group' style={{ display: 'flex' }}>
                <Label>
                  Convenience Fee:&nbsp;
                </Label>
                {
                  !readOnly || readOnly === 'false' ? (
                    <Value>
                      <Addon className='input-group-addon'>$</Addon>
                      <TextInput
                        value={fee}
                        disabled={true}
                        placeholder=''
                        className='text-right'
                        onChange={_ => {}}
                      />
                    </Value>
                  ) : (
                    <div style={{ fontWeight: 'bold', paddingRight: 10 }}>
                      {getMoneyString(fee)}
                    </div>
                  )
                }
              </div>
            </div>
          ) : null
        }
        <div className='form-group'>
          <div style={{ display: 'flex' }}>
            <Label>
              {label}:&nbsp;
            </Label>
            {
              !readOnly || readOnly === 'false' ? (
                <Value>
                  <Addon className='input-group-addon'>$</Addon>
                  <TextInput
                    value={amount}
                    placeholder=''
                    field={'amount'}
                    className='text-right'
                    replace={onAmountInput}
                    onChange={this.onChange}
                    disabled={showConvenienceFee}
                  />
                </Value>
              ) : (
                <div style={{ fontWeight: 'bold', paddingRight: 10 }}>
                  {getMoneyString(amount)}
                </div>
              )
            }
          </div>
          {isString(validation) && !isEmptyString(validation) ? (
            <div
              style={{ textAlign: 'right' }}
              className='small text-danger'
            >
              {validation}
            </div>
          ) : null}
          {isString(validationCardNumber) && !isEmptyString(validationCardNumber) ? (
            <div
              style={{ textAlign: 'right' }}
              className='small text-danger'
            >
              {validationCardNumber}
            </div>
          ) : null}
        </div>
        {paymentMethod}
      </div>
    );
  }
}

export default TransactionAmountContainer;
