import { getLocalFormat } from '../DateHelpers';
import { getStyles } from '../PaymentTypeHelpers';
import { generateTableElement, getObjectOfTextElements } from './PdfElements';

export const getElement = ({
                             invoiceComment = false, invoiceDueDate = false, invoiceStartDate = false,
                             invoiceRef = false, invoiceNum = false, styles, isTplLarge
                           }) => {
  const stylesCommon = getStyles(styles, isTplLarge).header;

  const startDate = invoiceStartDate ? getLocalFormat(invoiceStartDate, 'MM/DD/YY') : '';
  const dueDate = invoiceDueDate ? getLocalFormat(invoiceDueDate, 'MM/DD/YY') : '';

  const labels = {
    invoiceNum: 'Invoice #',
    invoiceRef: 'Invoice Ref',
    invoiceComment: 'Comment',
    invoiceStartDate: 'Invoice Start Date',
    invoiceDueDate: 'Invoice Due Date'
  };

  const values = {
    invoiceNum,
    invoiceRef,
    invoiceComment,
    invoiceStartDate: startDate,
    invoiceDueDate: dueDate
  };

  const stylesLabels = Object.keys(labels).reduce((prev, curr) => {
    return { ...prev, [curr]: stylesCommon.label };
  }, {});

  const stylesValues = Object.keys(values).reduce((prev, curr) => {
    return { ...prev, [curr]: stylesCommon.value };
  }, {});

  const objectLabels = getObjectOfTextElements(labels, stylesLabels);
  const objectValues = getObjectOfTextElements(values, stylesValues);

  const params = {
    headers: objectLabels,
    values: objectValues,
    ...stylesCommon.table
  };

  return generateTableElement(params);
};
