const shallowEqualsObj = (a, b, compare) => {
  const aIsNull = a === null,
        bIsNull = b === null;

  if (aIsNull !== bIsNull) return false;

  const aIsArray = Array.isArray(a),
        bIsArray = Array.isArray(b);

  if (aIsArray !== bIsArray) return false;

  const aTypeof = typeof a,
        bTypeof = typeof b;

  if (aTypeof !== bTypeof) return false;
  if (flat(aTypeof)) return compare
    ? compare(a, b)
    : a === b;

  return aIsArray
    ? shallowArray(a, b, compare)
    : shallowObject(a, b, compare)
};

const shallowArray = (a, b, compare) => {
  const l = a.length;
  if (l !== b.length) return false;

  if (compare) {
    for (let i = 0; i < l; i++)
      if (!compare(a[i], b[i])) return false;
  } else {
    for (let i = 0; i < l; i++) {
      if (a[i] !== b[i]) return false;
    }
  }

  return true;
};

const shallowObject = (a, b, compare) => {
  let ka = 0,
      kb = 0;

  if (compare) {
    for (let key in a) {
      if (
        a.hasOwnProperty(key) &&
        !compare(a[key], b[key])
      ) return false;

      ka++;
    }
  } else {
    for (let key in a) {
      if (
        a.hasOwnProperty(key) &&
        a[key] !== b[key]
      ) return false;

      ka++;
    }
  }

  for (let key in b) {
    if (b.hasOwnProperty(key)) kb++;
  }

  return ka === kb;
};

const flat = (type) => {
  return (
    type !== 'function' &&
    type !== 'object'
  )
};

export default shallowEqualsObj;