import React, {Component} from 'react';
import ec from 'ec-react15-lib';

import * as variableTypes from '../../../services/VariableType';
import {onTriggerAction} from '../../../services/ContextHelpers';
import shallowEqualsObj from '../../../services/ShallowEqualsObj';
import {Users, Stores, Heartbeat} from '../../../services/ApiData';
import {capitalizeTxt, fixedTo2, isDigit} from '../../../services/GeneralHelpers';
import {getConvenienceFee, useConvenienceFee} from "../../../services/ConvenienceFeeHelpers";

const {isEmptyString, isString, isValue, isArray, isEmptyArray, isEmptyObject, isObject, isBoolean} = variableTypes;
const {getValue, getStyling, setValue, renderChildren, triggerAction, getWritableValue, Debounced} = ec;

const TIMEOUT = 1000;

const defaultPatient = {
  firstName: '',
  lastName: '',
  account: '',
  amount: ''
};

class PaymentFormContainer extends Component {

  state = {user: '', keyPressed: false, object: {}, canForceCleanForm: true, update: true};

  componentWillMount() {
    const {props, context} = this.props;
    const object = {...getWritableValue('g:object', context, {})};
    const saveCustomerAllowed = getWritableValue('g:ecOptions.merchantOptions.options.saveCustomerAllowed', context, '');
    const saveCustomer = isString(saveCustomerAllowed) && !isEmptyString(saveCustomerAllowed)
      ? saveCustomerAllowed === 'true'
      : isBoolean(saveCustomerAllowed) ? saveCustomerAllowed : true
    setValue('g:object.saveCustomer', saveCustomer, context);
    const user = getWritableValue('ss:user', context, '') || '';
    if (user) {
      this.onSetUser(context)
    }

    const item = {...(object.item || {})};
    if (isObject(item) && !isEmptyObject(item)) {
      this.setState({item: {...item}});
    }
    const usePatientsForm = getValue(props, 'usePatientsForm', context, false);
    if (isValue(usePatientsForm)) {
      setValue('g:usePatientsForm', usePatientsForm, context);
    }
    if (window.CAPTCHA_SITE_KEY) {
      setValue('g:captchaSiteKey', window.CAPTCHA_SITE_KEY, context);
    }

    const isMandatoryBillingInformation = getValue(props, 'isMandatoryBillingInformation', context, false);
    setValue('g:isMandatoryBillingInformation', isMandatoryBillingInformation, context);
    const showBillingInfo = getValue(props, 'useBillingInformation', context, true);
    const showBillingInfoForce = getWritableValue('g:useBillingInformationForce', context);
    if (isValue(showBillingInfoForce)) {
      setValue('g:useBillingInformation', showBillingInfoForce, context);
    } else if (isValue(showBillingInfo)) {
      setValue('g:useBillingInformation', showBillingInfo, context);
    }
    const useTerms = getValue(props, 'useTerms', context, true);
    if (isValue(useTerms)) {
      setValue('g:useTerms', useTerms, context);
      if (!useTerms) {
        setValue('g:object.agree', true, context);
      }
    }
    const usePaymentRecurring = getValue(props, 'usePaymentRecurring', context, false);
    if (isValue(usePaymentRecurring)) {
      setValue('g:usePaymentRecurring', usePaymentRecurring, context);
    }

    const showShippingInfo = getValue(props, 'useShippingInformation', context, false);
    if (isValue(showShippingInfo)) {
      setValue('g:useShippingInformation', showShippingInfo, context);
    }

    const invoice = getWritableValue('docs:invoice', context, {}) || {};
    if (isObject(invoice) && !isEmptyObject(invoice)) {
      this.handlerInvoice(invoice, context);
    }
  }

  componentDidMount() {
    this.clearChromeAutocomplete();
    document.addEventListener('keyup', this.onKeyPress);
  }

  clearChromeAutocomplete = () => {
    if (navigator.userAgent.toLowerCase().indexOf('chrome') >= 0) {
      const list = document.getElementsByTagName('input');
      for (let i = 0; i < list.length; i++) {
        list[i].setAttribute('autoComplete', 'none');
      }
    }
  };

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyPress);
  }

  onKeyPress = () => {
    const {context} = this.props;
    const isAgree = getWritableValue('g:object.agree', context);
    const activeElement = document.activeElement.tagName.toLowerCase();
    if (['a', 'area', 'input', 'object', 'select', 'textarea'].includes(activeElement) || isAgree) {
      this.setState({keyPressed: true, canForceCleanForm: true});
      Debounced.start('onValidation', this.onValidation);
    }
  };

  onCleanForm = (context, onClean) => {
    const {newOrder: {forceCleanForm} = {}} = context;
    const {canForceCleanForm} = this.state;
    if (isValue(forceCleanForm) && forceCleanForm && onClean && canForceCleanForm) {
      this.setState({canForceCleanForm: false})
      Debounced.start('clear', () => {
        setValue('g:object', {}, context);
        setValue('g:validation', {}, context);
        setValue('g:docId', '', context);
        setValue('docs:doc', '', context);
        setValue('g:object.payment.cardToken', '', context);
        setValue('g:invoiceId', '', context);
        setValue('g:captchaSiteKey', '', context);
        setValue('docs:invoice', {}, context);
        setValue('g:helpers', {}, context);

        triggerAction({actions: {dispatch: [{type: 'ORDER_CLEAN', payload: false}]}}, context);
        triggerAction(onClean, context);

        const hasCustomFields = getWritableValue('g:object.customFields', context);
        const hasPaymentType = getWritableValue('g:object.paymentType', context);

        if (hasCustomFields) {
          const storeName = getWritableValue('g:store', context, '') || '';
          const secureList = getWritableValue('docs:secureList', context, []) || [];
          const publicList = getWritableValue('docs:publicList', context, []) || [];
          const optionStores = isArray(secureList) && !isEmptyArray(secureList) ? secureList : publicList;
          const storeObject = isArray(optionStores) ? optionStores.find(o => o.name === storeName) : {};

          const customFields = storeObject ? storeObject.customFields || [] : [];
          const pureCustomFields = customFields.reduce((res, curr) => {
            res.push({...curr, field: ''});
            return res;
          }, []);
          setValue('g:object.customFields', pureCustomFields, context);
          const usePatientsForm = getWritableValue('g:usePatientsForm', context);
          if (usePatientsForm) {
            setValue('g:object.patients', [{...defaultPatient}], context);
          }
          setValue('g:object.customer.customFields', [], context);
        }

        if (hasPaymentType) {
          setValue('g:object.paymentType.type', '', context);
          setValue('g:object.paymentType.schedule', '', context);
          setValue('g:object.paymentType.startDate', '', context);
          setValue('g:object.paymentType.timesToRecur', '', context);
        }
      }, 10);
    }
  };

  onToggleBillingInformation = (context, props) => {
    const {props: prevProps, context: prevContext} = this.props;
    const showShippingInfo = getValue(props, 'useShippingInformation', context, false);
    const prevShippingInfo = getValue(props, 'useShippingInformation', prevContext, false);
    const showBillingInfo = getValue(props, 'useBillingInformation', context, true);
    const prevBillingInfo = getValue(prevProps, 'useBillingInformation', prevContext, true);
    const showBillingInfoForce = getWritableValue('g:useBillingInformationForce', context);
    const prevBillingInfoForce = getWritableValue('g:useBillingInformationForce', prevContext);

    if (isValue(showBillingInfoForce) && showBillingInfoForce !== prevBillingInfoForce) {
      setValue('g:useBillingInformation', showBillingInfoForce, context);
    } else if (isValue(showBillingInfo) && (showBillingInfo !== prevBillingInfo)) {
      setValue('g:useBillingInformation', showBillingInfo, context);
    }

    if (isValue(showShippingInfo) && (showShippingInfo !== prevShippingInfo)) {
      setValue('g:useShippingInformation', showShippingInfo, context);
    }
  };

  checkCard = (context, props) => {
    const customer = getWritableValue('g:object.customer', context);
    const customerNewObj = getWritableValue('newOrder:customer', context);

    const storeName = getWritableValue('g:store', context, '') || '';
    const secureList = getWritableValue('docs:secureList', context, []) || [];
    const publicList = getWritableValue('docs:publicList', context, []) || [];
    const optionStores = isArray(secureList) && !isEmptyArray(secureList) ? secureList : publicList;
    const storeObject = isArray(optionStores) ? optionStores.find(o => o.name === storeName) : {};
    const storeNewOrder = getWritableValue('newOrder:store', context);
    if (isObject(customer) && !isEmptyObject(customer)
      && !shallowEqualsObj(customer, customerNewObj, shallowEqualsObj) && !customerNewObj._id && !this.state.stop) {
      triggerAction({actions: {dispatch: [{type: 'ORDER_SET_CUSTOMER', payload: customer}]}}, context);
    }
    if (isObject(storeObject) && !isEmptyObject(storeObject)
      && !shallowEqualsObj(storeObject, storeNewOrder, shallowEqualsObj)) {
      triggerAction({actions: {dispatch: [{type: 'STORE_SET', payload: storeObject}]}}, context);
    }
  };

  onToggleTerms = (context, props) => {
    const {props: prevProps, context: prevContext} = this.props;
    const useTerms = getValue(props, 'useTerms', context, true);
    const prevUsedTerms = getValue(prevProps, 'useTerms', prevContext, true);
    if (isValue(useTerms) && (useTerms !== prevUsedTerms)) {
      setValue('g:useTerms', useTerms, context);
      if (!useTerms) {
        setValue('g:object.agree', true, context);
      }
    }
  };

  onToggleCustomFields = (context) => {
    const depName = getWritableValue('g:department', context, '') || '';
    const prevDepName = getWritableValue('g:department', this.props.context, '') || '';
    if (depName !== prevDepName) {
      const storeName = getWritableValue('g:store', context, '') || '';
      const secureList = getWritableValue('docs:secureList', context, []) || [];
      const publicList = getWritableValue('docs:publicList', context, []) || [];
      const optionStores = isArray(secureList) && !isEmptyArray(secureList) ? secureList : publicList;
      const storeObject = isArray(optionStores) ? optionStores.find(o => o.name === storeName) : {};
      const customFields = storeObject ? storeObject.customFields || [] : [];
      setValue('g:object.customFields', customFields, context);
      setValue('g:object.customer.customFields', [], context);
    }
  };

  onToggleConvenienceFee = (context) => {
    const applyDepartmentFee =
      getWritableValue('g:ecOptions.merchantOptions.onlineForm.applyConvenienceFee', context, '');
    const depName = getWritableValue('g:department', context, '') || '';
    const prevDepName = getWritableValue('g:department', this.props.context, '') || '';
    if (applyDepartmentFee && (depName !== prevDepName)) {
      const storeName = getWritableValue('g:store', context, '') || '';
      const secureList = getWritableValue('docs:secureList', context, []) || [];
      const publicList = getWritableValue('docs:publicList', context, []) || [];
      const optionStores = isArray(secureList) && !isEmptyArray(secureList) ? secureList : publicList;
      const storeObject = isArray(optionStores) ? optionStores.find(o => o.name === storeName) : {};
      const convenienceFee = storeObject ? storeObject.convenienceFee || {} : {};

      const {convenienceFeePercentage = 0, convenienceFeeDollar = 0} = convenienceFee;
      if (convenienceFeePercentage > 0 || convenienceFeeDollar > 0) {
        setValue('g:departmentConvenienceFee', convenienceFee, context);
      } else {
        setValue('g:departmentConvenienceFee', {}, context);
      }
      this.setState({updated: new Date()}, () => {
        const invoice = getWritableValue('docs:invoice', context, {}) || {};
        if (isObject(invoice) && !isEmptyObject(invoice)) {
          this.handlerInvoice(invoice, this.props.context);
        }
      })
    }
  };

  onSetUser = (context) => {
    const {user: prevUser} = this.state;
    const user = getWritableValue('ss:user', context, '') || '';
    const secureList = getWritableValue('docs:secureList', context, []) || [];
    const prevSecureList = getWritableValue('docs:secureList', this.props.context, []) || [];
    const isSecure = isString(user) && !isEmptyString(user) && isArray(secureList) && !isEmptyArray(secureList);
    if (isSecure) {
      const isAgree = getWritableValue('g:object.agree', context);
      if (!isAgree) {
        setValue('g:object.agree', true, context);
      }
    }
    if (isString(user) && !isEmptyString(user) && ((user !== prevUser)
        || !shallowEqualsObj(prevSecureList, secureList, shallowEqualsObj))) {
      this.setState({user});
      onTriggerAction(Users.fetchOne(user), context);
      setValue('g:loadingLocation', true, context);
      onTriggerAction(Stores.fetchOne(user), context).then((resp) => {
        setValue('g:loadingLocation', false, context);
      });
      setValue('g:useTerms', false, context);
    }
  };

  checkInvoice = (context) => {
    const invoice = getWritableValue('docs:invoice', context, {}) || {};
    const prevInvoice = getWritableValue('docs:invoice', this.props.context, {}) || {};
    if (!(shallowEqualsObj(invoice, prevInvoice, shallowEqualsObj))) {
      this.handlerInvoice(invoice, context);
    }
  };

  handlerInvoice = (invoice, context) => {
    if (isObject(invoice) && !isEmptyObject(invoice) && invoice.type === 'invoice' && invoice.status === 'unpaid') {
      const {_id, items, totals, store, station, customer, invoiceComment, invoiceNum, invoiceRef} = invoice;
      items.forEach(item => {
        item.price = fixedTo2(item.price);
      });
      Object.keys(totals).forEach(key => {
        totals[key] = fixedTo2(totals[key]);
      });
      const {grandTotal = 0, amountDue = 0} = totals;
      const safeValue = +(amountDue || grandTotal);
      setValue('g:object.items', items, context);
      triggerAction({actions: {dispatch: [{type: 'ORDER_ITEMS_SET', payload: items}]}}, context);
      setValue('g:store', store ? store.name || '' : '', context);
      setValue('g:urlParam', store ? store.name || '' : '', context);
      setValue('g:station', station ? station.stationName || '' : '', context);
      setValue('g:object.customer', customer, context);
      setValue('g:object.notes.invoiceId', _id || '', context);
      setValue('g:object.notes.invoiceNum', invoiceNum ? `Invoice #${invoiceNum}` : '', context);
      setValue('g:object.notes.invoiceRef', invoiceRef ? `Invoice Ref ${invoiceRef}` : '', context);
      setValue('g:object.notes.description', invoiceComment, context);

      const convenienceFeeParams = getWritableValue('g:departmentConvenienceFee', context) || {};
      const { convenienceFeePercentage, convenienceFeeDollar, minTotal = 0, maxTotal } = convenienceFeeParams;
      if (useConvenienceFee(safeValue, minTotal, maxTotal)) {
        const fee = getConvenienceFee(safeValue, convenienceFeeDollar, convenienceFeePercentage);
        const totalAmount = parseFloat(safeValue) + parseFloat(fee);
        totals.convenienceFee = fixedTo2(fee);
        totals.grandTotal = fixedTo2(totalAmount);
        setValue('g:object.totals', totals, context);
        triggerAction({actions: {dispatch: [{type: 'ORDER_TOTALS_SET', payload: totals}]}}, context);
        setValue('g:object.payment.convenienceFee', +fixedTo2(fee), context);
        setValue('g:object.payment.amount', +fixedTo2(totalAmount), context);
      } else {
        totals.convenienceFee = 0;
        setValue('g:object.totals', totals, context);
        triggerAction({actions: {dispatch: [{type: 'ORDER_TOTALS_SET', payload: totals}]}}, context);
        setValue('g:object.payment.convenienceFee', 0, context);
        setValue('g:object.payment.amount', +safeValue, context);
      }
      this.setState({update: new Date()})
    }
  };

  onHeartbeat = (context) => {
    const user = getWritableValue('ss:user', context, '') || '';
    if (!user) {
      Debounced.start('heartbeat', () => {
        const {user: nextUser} = this.state;
        if (!nextUser) {
          const merchantId = getWritableValue('g:ecOptions.merchantId', context) || '';
          Heartbeat.ping(merchantId);
          setInterval(() => Heartbeat.ping(merchantId), 30 * 1000);
        }
      }, TIMEOUT);
    }
  };

  onValidation = () => {
    const {context, props} = this.props;
    const isMandatoryCustomer = getValue(props, 'isMandatoryCustomer', context, true);
    const showBillingInfo = getValue(props, 'useBillingInformation', context, true);
    // const isMandatoryBillingInformation = getValue(props, 'isMandatoryBillingInformation', context, false);
    const object = getWritableValue('g:object', context);
    const isMandatoryBillingInformation = getWritableValue('g:isMandatoryBillingInformation', context) || false;
    const isMandatoryEmail = getWritableValue('g:isMandatoryEmail', context);
    const prevWarnings = {...getWritableValue('g:validation', context) || {}};
    const isMandatoryLocation = getWritableValue('g:isMandatoryLocation', context);
    const warnings = Object.keys(prevWarnings).reduce((res, key) => {
      if (!key.includes('patients')) {
        return {...res, [key]: prevWarnings[key]};
      }
      return res;
    }, {});
    const reg = /\w+/g

    const hasToken = getWritableValue('g:object.customer.captcha-response', context);
    if (window.CAPTCHA_SITE_KEY && !hasToken) {
      warnings.captcha = "Please use hCaptcha";
    } else {
      warnings.captcha = "";
    }

    if (isMandatoryCustomer) {
      const {customer = {}} = object;
      const {firstName, lastName} = customer || {};
      if (!(isString(firstName) && !isEmptyString(firstName))) {
        const m = "Please provide 'First Name'"
        warnings.customerFirstName = m;
      } else if (isDigit(firstName)) {
        const m = "Value 'First Name' shouldn't contain only digits";
        warnings.customerFirstName = m;
      } else if (!firstName.match(reg)) {
        const m = "Is it a good value for 'First Name'?";
        warnings.customerFirstName = m;
      } else {
        warnings.customerFirstName = '';
      }

      const hasCustomerLastName = document.getElementsByClassName('id-input-customerLastName');
      if (hasCustomerLastName && hasCustomerLastName.length) {
        if (!(isString(lastName) && !isEmptyString(lastName))) {
          const m = "Please provide 'Last Name'";
          warnings.customerLastName = m;
        } else if (isDigit(lastName)) {
          const m = "Value 'Last Name' shouldn't contain only digits";
          warnings.customerLastName = m;
        } else if (!lastName.match(reg)) {
          const m = "Is it a good value for 'Last Name'?";
          warnings.customerLastName = m;
        } else {
          warnings.customerLastName = '';
        }
      }
    }
    const hasTsep = document.getElementById('tsep-cardNumDiv');
    if (hasTsep) {
      const cardNumber = getWritableValue('g:object.payment.cardNumber', context, '') || '';
      const cvv = getWritableValue('g:object.payment.cvv', context, '') || '';
      const expirationDate = getWritableValue('g:object.payment.expirationDate', context, '') || '';
      if (!cardNumber) {
        warnings.cardNumber = `Please provide valid 'cardNumber'`;
      } else {
        warnings.cardNumber = '';
      }
      if (!cvv) {
        warnings.cvv = `Please provide valid 'cvv'`;
      } else {
        warnings.cvv = '';
      }
      if (!expirationDate) {
        warnings.expirationDate = `Please provide valid 'expirationDate'`;
      } else {
        warnings.expirationDate = '';
      }
    } else {
      warnings.cardNumber = '';
      warnings.cvv = '';
      warnings.expirationDate = '';
    }
    const line1 = getWritableValue('g:object.customer.address.line1', context, '') || '';
    const city = getWritableValue('g:object.customer.address.city', context, '') || '';
    const state = getWritableValue('g:object.customer.address.state', context, '') || '';
    const zip = getWritableValue('g:object.customer.address.zip', context, '') || '';
    const emails = getWritableValue('g:object.customer.emails', context, '') || '';
    if (isMandatoryBillingInformation && showBillingInfo) {
      if (!(isString(line1) && !isEmptyString(line1)) || isDigit(line1) || line1.includes('*')) {
        warnings.line1 = "Please provide valid 'Address Line 1'";
      } else {
        warnings.line1 = '';
      }
      if (!(isString(city) && !isEmptyString(city)) || isDigit(city) || city.includes('*')) {
        warnings.city = "Please provide valid 'Address City'";
      } else {
        warnings.city = '';
      }
      if (!(isString(state) && !isEmptyString(state)) || isDigit(state)) {
        warnings.state = "Please provide valid 'Address State'";
      } else {
        warnings.state = '';
      }
      if (!(isString(zip) && !isEmptyString(zip)) || zip.includes('*') || zip.includes('-')) {
        warnings.zip = "Please provide valid 'Address Zip'";
      } else {
        warnings.zip = '';
      }
      if (isMandatoryEmail && !(isString(emails) && !isEmptyString(emails))) {
        warnings.emails = "Please provide valid 'Emails'";
      } else {
        warnings.emails = '';
      }
    } else {
      if (isString(line1) && !isEmptyString(line1) && (isDigit(line1) || line1.includes('*'))) {
        warnings.line1 = 'Please provide valid Address Line 1';
      } else {
        warnings.line1 = '';
      }
      if (isString(city) && !isEmptyString(city) && (isDigit(city) || city.includes('*'))) {
        warnings.city = 'Please provide valid Address City';
      } else {
        warnings.city = '';
      }
      if (isString(state) && !isEmptyString(state) && isDigit(state)) {
        warnings.state = 'Please provide valid Address State';
      } else {
        warnings.state = '';
      }
      if (isString(zip) && !isEmptyString(zip) && (zip.includes('*') || zip.includes('-'))) {
        warnings.zip = 'Please provide valid Zip';
      } else {
        warnings.zip = '';
      }
      if (isMandatoryEmail && !(isString(emails) && !isEmptyString(emails))) {
        warnings.emails = "Please provide valid 'Emails'";
      } else {
        warnings.emails = '';
      }
    }

    if (!!isMandatoryLocation) {
      const publicList = getWritableValue('docs:publicList', context, []) || [];
      const secureList = getWritableValue('docs:secureList', context, []) || [];
      const options = isArray(secureList) && !isEmptyArray(secureList) ? secureList : publicList;
      const department = getWritableValue('g:department', context, '');
      warnings.department = isArray(options) && !isEmptyArray(options) && !department
        ? "Please provide 'Location'" : '';
    }

    const customFields = getWritableValue('g:object.customFields', context, []) || [];
    if (isArray(customFields) && !isEmptyArray(customFields)) {
      customFields.reduce((res, v) => {
        if (v.mandatory) {
          if (!isString(v.field) || isEmptyString(v.field)) {
            res[`customFields${capitalizeTxt(v.id)}`] = `Please provide '${v.label}'`;
          } else if (!v.field.match(reg)) {
            const m = `Is it a good value for '${v.label}'?`;
            res[`customFields${capitalizeTxt(v.id)}`] = m;
          } else {
            res[`customFields${capitalizeTxt(v.id)}`] = '';
          }
        }
        return res;
      }, warnings);
    } else {
      Object.keys(warnings).reduce((res, key) => {
        if (key.indexOf('customFields') === 0) {
          warnings[key] = ''
        }
        return res;
      }, '');
    }

    const patients = getWritableValue('g:object.patients', context, []) || [];
    if (isArray(patients) && !isEmptyArray(patients)) {
      patients.reduce((res, v, i) => {
        if (!isString(v.account) || isEmptyString(v.account)) {
          res[`patients${i}`] = `Please provide for Patient ${i + 1} 'Account #'`;
        } else if (!v.account.match(reg)) {
          const m = `Is it a good value for 'Account'?`;
          res[`patients${i}`] = m;
        } else {
          res[`patients${i}`] = '';
        }
        if (isString(res[`patients${i}`]) && !isEmptyString(res[`patients${i}`])) {
          return res;
        }
        if (!(isString(v.firstName) && !isEmptyString(v.firstName))) {
          const m = `Please provide for Patient ${i + 1} 'First Name'`
          res[`patients${i}`] = m;
        } else if (isDigit(v.firstName)) {
          const m = `Value 'First Name' for Patient ${i + 1} shouldn't contain only digits`;
          res[`patients${i}`] = m;
        } else if (!v.firstName.match(reg)) {
          const m = `Is it a good value 'First Name' for Patient ${i + 1}?`;
          res[`patients${i}`] = m;
        } else {
          res[`patients${i}`] = '';
        }
        if (isString(res[`patients${i}`]) && !isEmptyString(res[`patients${i}`])) {
          return res;
        }
        if (!(isString(v.lastName) && !isEmptyString(v.lastName))) {
          const m = `Please provide 'Last Name' for Patient ${i + 1}`;
          res[`patients${i}`] = m;
        } else if (isDigit(v.lastName)) {
          const m = `Value 'Last Name' for Patient ${i + 1} shouldn't contain only digits`;
          res[`patients${i}`] = m;
        } else if (!v.lastName.match(reg)) {
          const m = `Is it a good value 'Last Name' for Patient ${i + 1}?`;
          res[`patients${i}`] = m;
        } else {
          res[`patients${i}`] = '';
        }
        if (isString(res[`patients${i}`]) && !isEmptyString(res[`patients${i}`])) {
          return res;
        }
        if (!(isDigit(v.amount) && +v.amount > 0)) {
          res[`patients${i}`] = `Please provide 'Amount' for Patient ${i + 1}`;
        } else {
          res[`patients${i}`] = '';
        }
        return res;
      }, warnings);
    }

    const isFree = getWritableValue('g:object.payment.isFree', context, false) || false;
    if (isFree) {
      warnings.amount = '';
    } else {
      const showVisa = getWritableValue('g:showVisa', context, '') || '';
      const showDiscover = getWritableValue('g:showDiscover', context, '') || '';
      const showMastercard = getWritableValue('g:showMastercard', context, '') || '';
      const showAmex = getWritableValue('g:showAmex', context, '') || '';

      const cardNumber = getWritableValue('g:object.payment.cardNumber', context, '') || '';
      const amount = getWritableValue('g:object.payment.amount', context, '') || '';
      if (!(isDigit(amount) && +amount > 0)) {
        warnings.amount = "Please provide 'Amount'";
      } else if (+cardNumber === +amount) {
        warnings.amount = "Please provide valid 'Amount'";
      } else {
        warnings.amount = '';
      }

      if (!showVisa && cardNumber.indexOf('4') === 0) {
        warnings.cardNumber = "Sorry Visa card is not accepted";
      } else if (!showMastercard && cardNumber.indexOf('5') === 0) {
        warnings.cardNumber = "Sorry Mastercard card is not accepted";
      } else if (!showAmex && cardNumber.indexOf('3') === 0) {
        warnings.cardNumber = "Sorry Amex card is not accepted";
      } else if (!showDiscover && cardNumber.indexOf('6') === 0) {
        warnings.cardNumber = "Sorry Discover card is not accepted";
      } else {
        warnings.cardNumber = "";
      }
    }

    const {canSubmit, ...obj} = warnings;
    if (isObject(obj) && !isEmptyObject(obj) && Object.keys(obj).some(s => obj[s])) {
      warnings.canSubmit = false;
    } else {
      warnings.canSubmit = true;
    }
    if (!shallowEqualsObj(prevWarnings, warnings, shallowEqualsObj)) {
      setValue('g:validation', warnings, context);
      setValue('g:errorMessage', warnings.amount, context);
    }
  };

  shouldValidateObject = (context) => {
    const object = getWritableValue('g:object', context) || {};
    const cardToken = getWritableValue('g:object.payment.cardToken', context, '') || '';

    if (!shallowEqualsObj(object, this.state.object, shallowEqualsObj) && (this.state.keyPressed || cardToken)) {
      this.onValidation();
    }
  };

  componentWillReceiveProps(nextProps) {
    const {props, context} = nextProps;
    const {onResponseReceived, onClean} = props;
    const {newOrder: {forceCleanForm} = {}} = context;
    this.onHeartbeat(context);

    this.onToggleConvenienceFee(context);
    this.onSetUser(context);
    this.checkInvoice(context);

    this.onToggleTerms(context, props);
    this.onToggleCustomFields(context);
    this.onToggleBillingInformation(context, props);
    this.shouldValidateObject(context);
    this.checkCard(context, props);
    const docId = getValue(props, 'submitted', context);
    if (isValue(forceCleanForm) && forceCleanForm && onClean) {
      this.onCleanForm(context, onClean);
    } else if (isValue(docId) && isString(docId) && !isEmptyString(docId) && onResponseReceived) {
      Debounced.start('submitted', () => {
        if (docId) {
          triggerAction(onResponseReceived, context);
          setValue('g:docId', '', context);
        }
      }, TIMEOUT);
    }

    const captchaSiteKey = getWritableValue('g:captchaSiteKey', context);
    if (!captchaSiteKey && window.CAPTCHA_SITE_KEY) {
      setValue('g:captchaSiteKey', window.CAPTCHA_SITE_KEY, context);
    }

    const prevSaveCustomer = getWritableValue('g:object.saveCustomer', context);
    const saveCustomerAllowed = getWritableValue('g:ecOptions.merchantOptions.options.saveCustomerAllowed', context, '');
    const saveCustomer = isString(saveCustomerAllowed) && !isEmptyString(saveCustomerAllowed)
      ? saveCustomerAllowed === 'true'
      : isBoolean(saveCustomerAllowed) ? saveCustomerAllowed : true
    if (prevSaveCustomer !== saveCustomer) {
      setValue('g:object.saveCustomer', saveCustomer, context);
    }
  }

  render() {
    const {index, props, context, pos, childIndex} = this.props;
    const sp = {props, context, pos, childIndex};
    const optional = ['container', 'repeatable', 'position', '',
      'useBillingInformation', 'useTerms', 'usePaymentRecurring', 'usePatientsForm', 'useShippingInformation',
      'submitted', 'onResponseReceived', 'onClean', 'isMandatoryCustomer', 'isMandatoryBillingInformation'];
    const {styles, classes} = getStyling({...sp, optional, styling: ['Block', 'Visibility']});
    if (styles === false) return null;
    const children = props.container ? renderChildren({items: props.container, props, context}) : false;
    return (
      <div key={index} className={classes.join(' ')} style={styles}>
        {children}
      </div>
    );
  }
}

export default PaymentFormContainer;
