import React, {Component} from 'react';
import ec from 'ec-react15-lib';
import styled from 'styled-components';
import Carousel from '../controls/Carousel/index';
import * as variableType from '../../../services/VariableType';

const { Logger, getWritableValue, getStyling, triggerAction } = ec;
const { isArray, isEmptyArray } = variableType;
const styleItemList = {display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'};


const colorDefault = {
  white: {
    accent1: '#0275d8',
    accent2: '#f0ad4e',
    background: '#fff',
    gradient: 'linear-gradient( 90deg, #0275d8,  #93A8DE, #0275d8)'
  },
  tan: {
    accent1:  "#007560",
    accent2: "#f0ad4e",
    background: '#F8eee7',
    gradient: 'linear-gradient( 90deg,   #000, #007560, #000)'
  },
  grey: {
    accent1: 'rgba(41,102,223,1)',
    accent2:  'rgb(1,165,159)',
    background: '#e8e9f0',
    gradient: 'linear-gradient( 0deg, rgba(41,102,223,1) 0% , rgba(1,165,159,1) 50%, rgba(41,102,223,1) 100% )'
  }
};

const Header = styled.h2`
  text-align: center;
  font-family: 'Rubik', sans-serif;
  font-family: 'Varela Round', sans-serif;
 
  font-size: 40px;
  font-weight: 100;
  line-height: 50px;
  letter-spacing: 1px;
  padding: 0 0 10px;
  border-bottom: double #555;
`;


class ProductsBrowse extends Component {

  onClickItem = (object) => {
    const {context, props: {onSelect}} = this.props;
    const {itemPreviewAllowed = ''} = getWritableValue('g:ecOptions.merchantOptions.options', context, '');
    if (itemPreviewAllowed || itemPreviewAllowed === 'true') {
      triggerAction({
        actions: {
          dispatch: [
            {
              payload: object,
              type: 'ORDER_PRODUCT_PREVIEW_ADD'
            }
          ],
          go: [
            {
              to: `/product/${object._id}`
            }
          ]
        }
      }, context)
        .then(_ => {
          triggerAction(onSelect, context);
        });
    } else {
      triggerAction({
        actions: {
          dispatch: [
            {
              payload: object,
              type: 'ORDER_PRODUCT_ADD'
            }
          ],
          go: [
            {
              to: `/cart`
            }
          ]
        }
      }, context)
        .then(_ => {
          triggerAction(onSelect, context);
        });
    }
  };

  render() {
    const {index, props, context, pos, section, childIndex} = this.props;
    Logger.of('render.html.CategoriesBrowse').info('section', section, 'index', index, 'props', props, 'pos', pos);
    const sp = {props, context, pos, childIndex};

    // const url = context.globals.ecOptions.merchantOptions.backgroundImage || '';
    const theme = context.globals.ecOptions.merchantOptions.theme || 'white';
    const colors = context.globals.ecOptions.merchantOptions.color || colorDefault;
    const color = colors[theme] || colors['white'];

    const {styles, classes} = getStyling({
      ...sp, mandatory: ['onSelect'], optional: [], styling: ['Block', 'Visibility']
    });
    if (styles === false) return false;
    const listFull = getWritableValue('docs:products.rows', context, []) || [];
    const searchResult = getWritableValue('g:searchProduct', context, []) || [];
    const searchQuery = getWritableValue('g:searchQuery', context, '') || '';
    const shippingOptions = getWritableValue('g:ecOptions.merchantOptions.shipping', context, {})
    const {enabled = false} = shippingOptions || {};
    const list = (searchQuery ? searchResult : listFull)
      .filter(d => !d.hideFromOnlineStore && (enabled ? (d.weightValue && +d.weightValue > 0) : true));
    const loadingStatus = getWritableValue('g:loadingStatus', context, '') || '';
    if (loadingStatus && loadingStatus === 'in_progress') return null;
    return (
        <div key={index} className={classes.join(' ')} style={styles}>
          {!(isArray(list) && !isEmptyArray(list)) ? (
            <div className='text-sm-center'>Products by {searchQuery ? `'${searchQuery}'` : 'Category' } not found</div>
          ) : (
            <div >
              <Header  className='slide-in-blurred-left' style={{color: color.accent1}}>
                {
                  searchQuery
                    ? (<span>Search</span>)
                    : (<span>{context.globals.category ? context.globals.category.toUpperCase() : ''}</span>)
                }
              </Header>
              <div style={styleItemList}>
                {list.map((doc, index) => {
                  if (!doc) return null;
                  /* eslint-enable */
                  const key = `${doc._id}-${index}`;
                  const {images = []} = doc;
                  return (
                    <Carousel
                      key={key}
                      object={doc}
                      data-aos='zoom-out'
                      slides={[doc.image, ...images]}
                      onClickItem={() => this.onClickItem(doc)}
                    />
                  )
                })}
              </div>
            </div>
          )}
        </div>
    )
  }
}

export default ProductsBrowse;
