import ec from 'ec-react15-lib';
import ecPluginPdfmake from 'ec-plugin-pdfmake';
import html from './html';
import reducers from './reducers';
import pdfmake from './pdf';
import {version} from './../package.json';

import './html/index.css';

const {start, Logger} = ec;


/* eslint-disable */
const __n = navigator;
const __d = document;
const __w = window;
const ua = __n.userAgent;
const alias = __w.EnormousCloudApp;

const elemRoot = __d.getElementById('root');
console.log("Package Version: ", version);

const htmlLink = (href, anchor) => {
  return `<a href="${href}">${anchor}</a>`;
};
const showError = (errorMessage) => {
  if (elemRoot) {
    elemRoot.innerHTML = `
      <div>
        <div class="alert alert-danger" style='text-align:center; max-width: 500px; margin: 0 auto; margin-top: 15px'>
            ${errorMessage}
        </div>
      </div>
    `;
    elemRoot.style.display = 'block';
  }
};

if (alias) {
  const q = window[alias].q || [];

  const arrOptions = {};
  if (q && q.length) {
    q.forEach((args) => {
      arrOptions[args[0]] = args[1];
    });
  }
  arrOptions.baseUrl = arrOptions.baseUrl || '/';
  arrOptions.requestParams = {mode: 'cors', redirect: 'follow'};
  arrOptions.reducers = reducers;
  arrOptions.html = html;

  arrOptions.plugins = [{...ecPluginPdfmake, pdfmake}];

  //const defaultHeaders = arrOptions.accountId ? { 'X-Merchant-Id': arrOptions.merchantId} : {};
  const defaultHeaders = arrOptions.merchantId ? {'X-Merchant': arrOptions.merchantId} : {};
  arrOptions.requestHeaders = arrOptions.requestHeaders || defaultHeaders;

  const isEdge = ua.indexOf('Edge') != -1
  const isIE = ua.indexOf('Trident') != -1 && !isEdge
  arrOptions.invalidBrowser = isIE || isEdge;
  arrOptions.userAgentAndroid = ua.toLowerCase().indexOf('android') > -1;
  if (!isIE) {
    Logger.of('Application.Start').warn('Starting application, options=', arrOptions);
    start(arrOptions);
  } else {
    const chromeLink = htmlLink('https://www.google.com/chrome/browser/desktop/', 'Chrome');
    const firefoxLink = htmlLink('https://www.mozilla.com/firefox/', 'Firefox');
    const more = `<br /><br />Please consider using ${chromeLink} or ${firefoxLink}`;
    showError(`
      <b>Fully supported browsers:
      <br />current versions of Chrome, Firefox, and Safari. 
      <br />*Internet Explorer is not a secure browser.</b>
      ${more}
    `);
  }
} else {
  Logger.of('Application.Error').error('No starter instructions');
}
