import React, { Component } from 'react';
import ec from 'ec-react15-lib';
import moment from 'moment';
import ReactDatetime from 'react-datetime';
import { Label, Value } from '../controls/StyledDiv';
import { isDigit } from '../../../services/GeneralHelpers';
import { getOptions, getStartDate, paymentType, paymentSchedule } from '../../../services/PaymentTypeHelpers';
import '../controls/css/react-datetime.css';

const { TextInput, Dropdown, setValue, Logger, getStyling, getWritableValue, getValue, Debounced } = ec;

class PaymentType extends Component {
  state = {
    type: '',
    schedule: '',
    timesToRecur: '',
    startDate: ''
  };

  componentWillMount() {
    const { props, context } = this.props;
    if (!props || !context) return null;
    const object = getValue(props, 'value', context);
    if (object && object !== this.state) {
      this.setState({ ...this.state, ...object });
    }
  }
  /* eslint-disable */
  componentWillReceiveProps(nextProps) {
    const { props, context } = nextProps;
    if (!props || !context) return null;
    const object = getValue(props, 'value', context);
    if (object && (object !== this.state)) {
      this.setState({ ...this.state, ...object });
    }
  }

  /* eslint-enable */
  onChange = (field, value) => {
    const { props, context } = this.props;
    if (!props || !context) return null;
    const target = props.value || props['@value'];

    Debounced.start(`update-${target}-${field}`, () => {
      const streamName = `${target}.${field}`;
      if (field === 'timesToRecur' && !isDigit(value)) {
        setValue(streamName, '', context);
        this.setState({ [field]: '' });
      } else {
        setValue(streamName, value, context);
        this.setState({ [field]: value });
        if (field === 'type') {
          const type = value === 'Recurring' ? 'subscription' : 'order';
          setValue('g:object.type', type, context);
        }
      }
    }, 250);
  };
  /* eslint-enable */

  isValidDate = current => current.isAfter(moment());

  render() {
    const { section, index, props, context, pos, childIndex } = this.props;
    Logger.of('render.PaymentType').info('section', section, 'index', index, 'props', props, 'pos=', pos);
    const sp = { props, context, pos, childIndex };
    const optional = ['actions', 'value'];
    const { styles, classes } = getStyling({
      ...sp, mandatory: [], optional, styling: ['Block', 'Text', 'Visibility']
    });
    if (styles === false) return false;

    const title = getWritableValue('g:ecOptions.merchantOptions.title', context) || '';
    const typeOptions = getOptions(paymentType);
    const scheduleOptions = getOptions(paymentSchedule);
    const { type, schedule, timesToRecur, startDate } = this.state;
    if (!schedule) {
      this.onChange('schedule', scheduleOptions[0].value);
      return null;
    }
    if (!startDate) this.onChange('startDate', getStartDate(moment().add(1, 'day')));
    return (
      <div className={classes}>
        <div className='form-group' style={{ display: 'flex' }}>
          <Label>
            Payment type:&nbsp;
          </Label>
          <Value>
            <Dropdown
              value={type}
              options={typeOptions}
              onChange={value => this.onChange('type', value)}
            />
          </Value>
        </div>
        {
          type === 'Recurring' &&
            <div>
              <div className='form-group' style={{ display: 'flex' }}>
                <Label>
                  Schedule:&nbsp;
                </Label>
                <Value>
                  <Dropdown
                    value={schedule}
                    options={scheduleOptions}
                    onChange={value => this.onChange('schedule', value)}
                  />
                </Value>
              </div>
              <div className='form-group' style={{ display: 'flex' }}>
                <Label>
                  # of times to recur:&nbsp;
                </Label>
                <Value>
                  <TextInput
                    value={timesToRecur}
                    onChange={value => this.onChange('timesToRecur', value)}
                  />
                </Value>
              </div>
              <div className='form-group' style={{ display: 'flex' }}>
                <Label>
                  Start Date:&nbsp;
                </Label>
                <Value>
                  <ReactDatetime
                    timeFormat={false}
                    closeOnSelect={true}
                    value={moment(startDate)}
                    isValidDate={this.isValidDate}
                    inputProps={{ placeholder: 'Start date...' }}
                    onChange={value => this.onChange('startDate', getStartDate(value))}
                  />
                  <span className='input-group-addon'>
                    <i className='fa fa-calendar'> </i>
                  </span>
                </Value>
              </div>
              <div className='form-group' style={{ display: 'flex', justifyContent: 'center' }}>
                This Transaction Will Appear as: {title}
              </div>
            </div>
        }
      </div>
    );
  }
}

export default PaymentType;
