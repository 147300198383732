import ec from 'ec-react15-lib';
import { isString, isEmptyString, isValue } from './../VariableType';
import { isDigit } from './../GeneralHelpers';

const { setValue, getWritableValue } = ec;

export const doBillingInfoValidation = (address) => {
  const validation = { line1: '', city: '', state: '' };
  const { line1, city, state } = address || {};
  if (isString(line1) && !isEmptyString(line1) && isDigit(line1)) {
    validation.line1 = 'Please provide valid Address Line 1';
  }
  if (isString(city) && !isEmptyString(city) && isDigit(city)) {
    validation.city = 'Please provide valid Address City';
  }
  if (isString(state) && !isEmptyString(state) && isDigit(state)) {
    validation.state = 'Please provide valid Address State';
  }
  return validation;
};

export const doCardValidator = (card) => {
  const validation = { cardNumber: '', expMonth: '', expYear: '', cardHolder: '', cvv: '' };
  const { cardNumber, cardHolder, cvv } = card || {};
  if (isValue(cvv) && isString(cvv) && !isEmptyString(cvv)) {
    if (!isDigit(cvv)) {
      validation.cvv = 'CVV/CID must be 3-digit or 4-digit code';
    }
    if (![3, 4].includes(cvv.length)) {
      validation.cvv = 'Unexpected numbers of digits';
    }
  }
  if (isValue(cardHolder) && isString(cardHolder) && !isEmptyString(cardHolder) && isDigit(cardHolder)) {
    validation.cardHolder = 'Please provide valid Card Holder';
  }
  if (isValue(cardNumber) && isString(cardNumber) && !isEmptyString(cardNumber)) {
    if (!isDigit(cardNumber)) {
      validation.cardNumber = 'Please provide digits';
    }
    if (!(cardNumber.toString().length >= 13 && cardNumber.toString().length <= 19)) {
      validation.cardNumber = 'Unexpected numbers of digits';
    }
  }
  return validation;
};

export const doPaymentCardValidation = (context) => {
  const useTerms = getWritableValue('g:useTerms', context);
  const isAgree = getWritableValue('g:object.agree', context, false) || false;

  const cardValidation = getWritableValue('g:cardValidation', context, {}) || {};

  const billingValidation = getWritableValue('g:billingValidation', context, {}) || {};
  const hasWarningOnBillingInfo = Object.keys(billingValidation).some(o => billingValidation[o]);
  const hasWarningOnCard = Object.keys(cardValidation).some(o => cardValidation[o]);
  const hasWarning = hasWarningOnBillingInfo || hasWarningOnCard;
  if (hasWarning) {
    const payment = getWritableValue('g:object.payment', context, {}) || {};
    const isEmptyPayment = Object.values(payment).every(v => !v);
    if (!isEmptyPayment && isAgree) {
      setValue('g:object.agree', false, context);
    }
  } else if (!useTerms && !isAgree) {
    setValue('g:object.agree', true, context);
  }
};