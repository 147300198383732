import { generateTableElement, getObjectOfTextElements } from './PdfElements';
import { getStyles } from '../PaymentTypeHelpers';

const getTotalsObject = (pointObj) => {
  const { addedPoints, usedPoints, totalPoints, pointsBeforePurchase } = pointObj;

  const labels = {
    pointsBeforePurchase: 'Points Before Purchase:',
    pointsRedeemed: 'Points Redeemed:',
    pointsEarned: 'Points Earned:',
    totalPoints: 'Available Points:'
  };
  const values = {
    pointsBeforePurchase,
    pointsRedeemed: usedPoints,
    pointsEarned: addedPoints,
    totalPoints
  };
  return { labels, values };
};

export const getElement = (pointObj, isTplLarge, styles) => {
  if (pointObj) {
    const stylesTotals = getStyles(styles, isTplLarge).totals;
    const { labels = {}, values = {} } = getTotalsObject(pointObj) || {};

    const specLabels = ['totalPoints'];
    const stylesSpec = { bold: true };

    const stylesLabels = Object.keys(labels).reduce((prev, curr) => {
      if (specLabels.includes(curr)) return { ...prev, [curr]: { ...stylesTotals.label, ...stylesSpec } };
      return { ...prev, [curr]: stylesTotals.label };
    }, {});

    const stylesValues = Object.keys(values).reduce((prev, curr) => {
      if (specLabels.includes(curr)) return { ...prev, [curr]: { ...stylesTotals.value, ...stylesSpec } };
      return { ...prev, [curr]: stylesTotals.value };
    }, {});

    const objectLabels = getObjectOfTextElements(labels, stylesLabels);
    const objectValues = getObjectOfTextElements(values, stylesValues);

    const params = {
      headers: objectLabels,
      values: objectValues,
      ...stylesTotals.table
    };

    return generateTableElement(params);
  }
  return '';
};
