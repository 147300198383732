import { hardRequirementError } from './ValidatorHelpers';
import { isValue, isString } from './VariableType';

export const listRental = [
  { value: '', label: 'Inventory' },
  { value: 'days', label: 'Daily Rentals' },
  { value: 'months', label: 'Monthly Rentals' }
];

export const getRentalName = (value) => {
  const item = listRental.find(obj => obj.value === value) || {};
  return item ? item.label || '' : '';
};

export const getTotalsRentalDays = ({ rentalSerials }) => {
  if (hardRequirementError(rentalSerials)) return 0;
  return rentalSerials.reduce((prev, curr) => {
    const { dates = [] } = curr;
    return prev + dates.length;
  }, 0);
};

export const isRental = (el) => {
  if (!isValue(el)) return false;
  const rental = isString(el) ? el : el.rental;
  return rental && rental !== 'inventory';
};
