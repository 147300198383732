/* eslint-disable */
import { getWritableValue } from 'ec-react15-lib';
import * as pdfElements from '../../services/pdf';
import { getStyles } from '../../services/PaymentTypeHelpers';
import { getCustomFields } from '../../services/OrderHelpers';
import { getLine, getSpace } from '../../services/pdf/PdfElements';
import { isArray, isEmptyArray } from '../../services/VariableType';

const PdfDetails = ({ gen, props, context }) => {
  const defaultTpl = getWritableValue('g:ecOptions.merchantOptions.receipt.preview', context) || false;
  const isTplLarge = defaultTpl === 'A4' || !defaultTpl;
  const { showDonation = false } = props; // default show Totals

  const styles = gen.getStyling(props, context);
  if (!styles) return false;

  const { _id = false, totals = false, customFields = false, paymentType = false } = context.docs.doc;

  if (customFields && pdfElements['CustomFields']) {
    const customFields = getCustomFields({ ...context.docs.doc });
    if (isArray(customFields) && !isEmptyArray(customFields)) {
      if (!isTplLarge) {
        gen.addElement(getSpace());
        gen.addElement(getLine(styles, context));
        gen.addElement(getSpace());
      }
      const customFieldsTable = pdfElements['CustomFields'].getElement({customFields, styles, isTplLarge});
      gen.addElement(customFieldsTable);
    }
  }

  const stylesPaymentType = getStyles(styles, isTplLarge).paymentType;
  if (pdfElements['PaymentType']) {
    const paymentTypeElement = pdfElements['PaymentType'].getElement({...paymentType, stylesPaymentType});
    gen.addElement(paymentTypeElement);
  }

  if (pdfElements['Totals'] && totals) {
    gen.addElement(getSpace('0.5'));
    const totalsElement = pdfElements['Totals'].getElement({ totals, showDonation, styles, isTplLarge });
    gen.addElement(totalsElement);
  }

  if (pdfElements['AmountDue']) {
    gen.addElement(getSpace());
    gen.addElement(getLine(styles, context));
    gen.addElement(getSpace());

    const totalsElement = pdfElements['AmountDue'].getElement({ showDonation, styles, isTplLarge });
    gen.addElement(totalsElement);
    gen.addElement(getSpace(1));

    if (pdfElements['QR']) {
      const size = 40;
      const unit = 'mm';
      const element = pdfElements['QR'].getElement({ data: _id, isTplLarge, size, unit });
      gen.addElement(element);
      gen.addElement(getSpace(1));
    }
  }

  return false;
};

export default PdfDetails;
/* eslint-enable */
