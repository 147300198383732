import { isValue } from './../services/VariableType';

const defaultPatient = {
  firstName: '',
  lastName: '',
  account: '',
  amount: '',
  index: 0,
  key: 1
};

const defaultState = {
  count: 1,
  patients: [{ ...defaultPatient }]
};

export default (state = defaultState, action) => {
  if (action.type === 'PATIENTS_INIT') {
    const { patients } = state;
    const count = +action.payload;
    const length = patients.length;

    if (isValue(count) && (length !== count)) {
      if (length > count) {
        const newPatients = patients.slice(count - 1);
        const newLength = newPatients.length;
        return Object.assign({}, state, { patients: newPatients, count: newLength });
      }
      const extraLength = Math.trunc(count - length);
      const newPatients = [...new Array(extraLength).fill(defaultPatient)]
        .reduce((res, curr) => {
          res.push({ ...curr, index: res.length, key: res.length + 1 });
          return res;
        }, [...patients]);
      const newLength = newPatients.length;
      return Object.assign({}, state, { patients: newPatients, count: newLength });
    }
  }

  if (action.type === 'PATIENTS_CLEAR') {
    return defaultState;
  }

  if (action.type === 'PATIENTS_DECREASE') {
    const { patients } = state;
    const newPatients = patients.slice(0, -1);
    const length = newPatients.length;
    return Object.assign({}, state, { patients: newPatients, count: length });
  }

  if (action.type === 'PATIENTS_INCREASE') {
    const patients = [...state.patients];
    const length = patients.length;
    patients.push({ ...defaultPatient, index: length, key: length + 1 });
    return Object.assign({}, state, { patients, count: patients.length });
  }

  return state;
};
