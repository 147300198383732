import React, { Component } from 'react';
import ec from 'ec-react15-lib';

import Button from '../controls/Button';
import TextInput from '../controls/TextInput';
import * as variableType from '../../../services/VariableType';
import { isNumberType } from '../../../services/ValidatorHelpers';
import { Addon, Header, Label, Value } from '../controls/StyledDiv';
import { getSafeAmount, onAmountInput, toFixedFloat2 } from '../../../services/GeneralHelpers';
import { getConvenienceFee, useConvenienceFee } from '../../../services/ConvenienceFeeHelpers';

const { getStyling, setValue, getWritableValue } = ec;
const { isObject, isEmptyObject, isValue, isArray, isEmptyArray } = variableType;

const stylesList = {
  hint: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' },
  warn: { border: '1px solid red' }
};

const defaultPatient = {
  firstName: '',
  lastName: '',
  account: '',
  amount: ''
};


const calcAmount = (newPatients) => {
  return newPatients.reduce((sum, curr) => {
    if (isObject(curr) && !isEmptyObject(curr) && isValue(curr.amount)
      && isNumberType(curr.amount)
      && parseFloat(curr.amount) > 0) {
      const safeValue = getSafeAmount(curr.amount || 0);
      return sum + parseFloat(safeValue);
    }
    return sum;
  }, 0);
};

class PatientsContainer extends Component {

  componentWillMount() {
    const { context } = this.props;
    setValue('g:object.patients', [{ ...defaultPatient }], context);
  }

  onChange = (index, field, value) => {
    const { context } = this.props;
    const patients = getWritableValue('g:object.patients', context, []) || [];
    if (isArray(patients) && !isEmptyArray(patients) && index > -1) {
      const object = patients[index];
      if (field === 'amount') {
        const safeValue = getSafeAmount(value || 0);
        object[field] = safeValue;
      } else {
        object[field] = value;
      }
      const newPatients = [...patients];
      newPatients[index] = {...object};
      setValue('g:object.patients', newPatients, context);
      const total = calcAmount(newPatients);
      this.onChangeTotals(total);
    }
  };

  onChangeTotals = (value) => {
    const { context } = this.props;
    const applyFee = getWritableValue('g:useConvenienceFee', context, 0) || 0;
    const convenienceFeePercentage = getWritableValue('g:convenienceFeePercentage', context, 0);
    const convenienceFeeDollar = getWritableValue('g:convenienceFeeDollar', context, 0);
    const convenienceMinTotal = getWritableValue('g:convenienceMinTotal', context, 0);
    const convenienceMaxTotal = getWritableValue('g:convenienceMaxTotal', context, 0);

    if (applyFee) {
      if (useConvenienceFee(value, convenienceMinTotal, convenienceMaxTotal)) {
        const fee = getConvenienceFee(value, convenienceFeeDollar, convenienceFeePercentage);
        const totalAmount = parseFloat(value) + parseFloat(fee);
        setValue('g:object.payment.convenienceFee', +toFixedFloat2(fee), context);
        setValue('g:object.payment.amount', +toFixedFloat2(totalAmount), context);
      } else {
        setValue('g:object.payment.convenienceFee', 0, context);
        setValue('g:object.payment.amount', +toFixedFloat2(value), context);
      }
      setValue('g:object.payment.inputAmount', +toFixedFloat2(value), context);
    } else {
      setValue('g:object.payment.amount', +toFixedFloat2(value), context);
    }
  };

  onAdd = () => {
    const { context } = this.props;
    const patients = getWritableValue('g:object.patients', context, []) || [];
    setValue('g:object.patients', [...patients, { ...defaultPatient }], context);
  };

  onDelete = (index) => {
    const { context } = this.props;
    const patients = getWritableValue('g:object.patients', context, []) || [];
    const newPatients = patients.map((o, i) => (i !== index) ? o : '').filter(o => o) || []
    setValue('g:object.patients', newPatients, context);
  };

  render() {
    const { index, props, context, pos, childIndex } = this.props;
    const sp = { props, context, pos, childIndex };
    const optional = ['container', 'position', 'patientsCount'];
    const { styles, classes } = getStyling({ ...sp, optional, styling: ['Block', 'Visibility'] });
    if (styles === false) return null;
    const patients = getWritableValue('g:object.patients', context, []) || [];
    const validation = getWritableValue('g:validation', context, {}) || {};
    const bgHeader = getWritableValue('g:bgHeader', context, '') || '';

    return (
      <div key={index} className={classes.join(' ')} style={styles}>
        {
          patients.map((o, i) => {
            const { firstName = '', lastName = '', account = '', amount = 0 } = o || {};
            return (
              <div key={`patient-${i}`} className='form-group'>
                <Header style={{ paddingTop: 5 }} background={bgHeader}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ flex: 1 }} />
                    <div style={{ flex: 1 }}>Patient {i + 1}</div>
                    <div style={{ flex: 1, textAlign: 'right' }}>
                      {i === patients.length - 1 ? (
                        <Button
                          icon='plus'
                          type='default'
                          outlines={false}
                          onClick={this.onAdd}
                          style={{ marginRight: '5px' }}
                        />
                      ) : null}
                      {patients.length > 1 ? (
                        <Button
                          icon='close'
                          type='danger'
                          outlines={false}
                          style={{ marginRight: '5px' }}
                          onClick={() => this.onDelete(i)}
                        />
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                </Header>
                <div style={{ display: 'flex', marginBottom: '15px' }}>
                  <Label style={stylesList.hint}>
                    <span>Account #:&nbsp;</span>
                  </Label>
                  <Value>
                    <div style={{ width: '100%' }}>
                      <TextInput
                        className={`account-${i}`}
                        value={account}
                        placeholder='Account #'
                        onChange={value => this.onChange(i, 'account', value)}
                      />
                    </div>
                  </Value>
                </div>
                <div style={{ display: 'flex', marginBottom: '15px' }}>
                  <Label style={stylesList.hint}>
                    <span>Patient First Name:&nbsp;</span>
                  </Label>
                  <Value>
                    <div style={{ width: '100%' }}>
                      <TextInput
                        className={`firstName-${i}`}
                        value={firstName}
                        placeholder='First Name'
                        onChange={value => this.onChange(i, 'firstName', value)}
                      />
                    </div>
                  </Value>
                </div>
                <div style={{ display: 'flex', marginBottom: '15px' }}>
                  <Label style={stylesList.hint}>
                    <span>Patient Last Name:&nbsp;</span>
                  </Label>
                  <Value>
                    <div style={{ width: '100%' }}>
                      <TextInput
                        className={`lastName-${i}`}
                        value={lastName}
                        placeholder='Last Name'
                        onChange={value => this.onChange(i, 'lastName', value)}
                      />
                    </div>
                  </Value>
                </div>
                <div style={{ display: 'flex', marginBottom: '15px' }}>
                  <Label style={stylesList.hint}>
                    <span>Payment Amount:&nbsp;</span>
                  </Label>
                  <Value>
                    <div style={{ width: '100%' }}>
                      <Value>
                        <Addon className='input-group-addon'>$</Addon>
                        <TextInput
                          placeholder='0'
                          id='amount'
                          value={amount}
                          className='text-right'
                          replace={onAmountInput}
                          onChange={value => this.onChange(i, 'amount', value)}
                        />
                      </Value>
                    </div>
                  </Value>
                </div>
                {validation[`patients${i}`] ? (
                  <div
                    style={{ textAlign: 'right' }}
                    className='small text-danger'
                  >
                    {validation[`patients${i}`]}
                  </div>
                ) : null}
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default PatientsContainer;
