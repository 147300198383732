import { isObject, isEmptyObject, isString, isEmptyString } from './VariableType';

const getDepartmentDetails = (object) => {
  if (isObject(object) && !isEmptyObject(object)) {
    const { name = '', department = '', station } = object;
    const hasStation = station && isObject(station) && !isEmptyObject(station);
    const stationName = hasStation && isString(station.stationName) && !isEmptyString(station.stationName)
      ? station.stationName : '';
    return { ...object, stationName, store: name, department };
  }
  return {};
};

export { getDepartmentDetails }