import moment from 'moment';
import { getLocalFormat } from './DateHelpers';
import { getJoinObject, toFixedFloat2 } from './GeneralHelpers';
import { softRequirementError, hardRequirementError, isNumberType } from './ValidatorHelpers';

export const getOptions = arr => arr.map(obj => ({ label: obj, value: obj }));
export const getStartDate = date => moment.utc(date).startOf('day').format();

export const paymentType = ['One time', 'Recurring'];
export const paymentSchedule = ['daily', 'weekly', 'biweekly', 'monthly', 'bimonthly', 'quarterly', 'biannually',
  'annually'];

export const nextSchedule = (startDate, period, num = 1) => {
  const number = period && period.indexOf('bi') === 0 ? num * 2 : num;
  const date = moment(startDate);
  switch (period) {
    case 'daily':
      return date.add(number, 'days');
    case 'quarterly':
      return date.add(number, 'quarters');
    case 'weekly':
    case 'biweekly':
      return date.add(number, 'weeks');
    case 'monthly':
    case 'bimonthly':
      return date.add(number, 'months');
    case 'annually':
    case 'biannually':
      return date.add(number, 'years');
    default:
      return date.add(number, 'days');
  }
};

export const getScheduleDates = ({ timesToRecur = 0, schedule, startDate, type }) => {
  if (timesToRecur < 1 || !paymentSchedule.includes(schedule)) return [];
  const localStartDate = getLocalFormat(startDate);
  if (type !== 'Recurring') return [localStartDate];

  const count = timesToRecur - 1;
  const arrIterations = [...Array(count).keys()];
  return arrIterations.reduce((prev, curr, i) => {
    const nextDate = nextSchedule(prev[i], schedule);
    prev.push(getLocalFormat(nextDate));
    return prev;
  }, [localStartDate]);
};

export const getStyles = (styles, isTplLarge) => {
  const stylesTotalCommon = { ...styles, border: [false, false, false, false] };
  return ({
    paymentType: {
      table: {
        margin: isTplLarge ? [15, 10, 15, 10] : [0, 0, 0, 0],
        widths: ['auto', '*'],
        layout: 'noBorders'
      },
      label: { ...styles, bold: false },
      value: isTplLarge ? { ...styles, bold: true } : { ...styles, bold: true, alignment: 'right' }
    },
    totals: {
      table: {
        pullRight: isTplLarge,
        margin: [0, 0, 0, 0],
        widths: isTplLarge ? ['*', 'auto', 'auto'] : ['auto', '*'],
        layout: ''
      },
      label: { ...styles, ...stylesTotalCommon, bold: false },
      value: { ...styles, ...stylesTotalCommon, bold: true, alignment: 'right' }
    },
    header: {
      label: { ...styles, bold: false },
      value: isTplLarge ? { ...styles, bold: true } : { ...styles, bold: true, alignment: 'right' },
      table: {
        margin: isTplLarge ? [15, 10, 15, 10] : [0, 0, 0, 0],
        widths: ['auto', '*'],
        layout: 'noBorders'
      }
    },
    items: {
      header: {
        bold: true,
        alignment: 'center',
        border: [false, true, false, true]
      },
      table: { bold: true, alignment: 'center' }
    },
    transactionDetails: {
      list: {
        fontSize: styles.fontSize,
        separator: ')',
        bold: true,
        width: 'auto'
      }
    },
    transactionPaymentType: {
      table: {
        pullRight: isTplLarge,
        margin: [0, 0, 0, 0],
        widths: isTplLarge ? ['auto', '*', 'auto'] : ['*', 'auto'],
        layout: ''
      },
      label: { ...styles, ...stylesTotalCommon, bold: true },
      value: { ...styles, ...stylesTotalCommon, bold: true, alignment: 'right' }
    }
  });
};

export const getMoneyString = (value, canZero = true, defaultValue = '$0.00') => {
  if (!canZero && (!isNumberType(value) || !parseFloat(value))) return '';
  const amount = parseFloat(value) >= 0 ? `$${toFixedFloat2(value)}` : ` ($${toFixedFloat2(Math.abs(value))})`;
  return isNumberType(value) ? amount : defaultValue;
};

export const getPercentString = (value, canZero = true, defaultValue = '0%') => {
  if (!canZero && (!isNumberType(value) || !parseFloat(value))) return '';
  return isNumberType(value) ? `${toFixedFloat2(value)}%` : defaultValue;
};

export const fixLengthText = (value) => {
  if (!value || typeof value !== 'string') return '';
  const { length } = String(value);
  const maxLength = 5;
  if (maxLength < length) {
    const result = value.match(/.{1,5}/g);
    return result;
  }
  return value;
};

export const getCustomerString = (object) => {
  if (softRequirementError(object)) return '';

  const { firstName = false, lastName = false, businessName = false, address = false, emails = false,
    phones = false } = object;
  const fullNameObj = { firstName, lastName };

  const separator = '\n';

  const stringBusinessName = !hardRequirementError(businessName) ? businessName : '';
  const stringFullName = !softRequirementError(fullNameObj) ? getJoinObject(fullNameObj) : '';
  const stringPhones = !softRequirementError(phones) ? phones.join(separator) : '';
  const stringEmails = !softRequirementError(emails) ? emails.join(separator) : '';
  const stringAddress = !softRequirementError(address) ? getJoinObject(address) : '';

  const customerObject = { stringFullName, stringBusinessName, stringAddress, stringPhones, stringEmails };
  const result = getJoinObject(customerObject, separator);

  return result;
};

export const getNameString = (object) => {
  if (softRequirementError(object)) return '';
  const { firstName = '', lastName = '', name = '' } = object;
  if (name && name.trim().length) return name.trim();
  const fullNameObj = { firstName, lastName };
  return !softRequirementError(fullNameObj) ? getJoinObject(fullNameObj) : '';
};

export const getCompanyString = (object) => {
  if (softRequirementError(object)) return '';
  const { businessName = false } = object;
  return !hardRequirementError(businessName) ? businessName : '';
};

export const getPhonesString = (object) => {
  if (softRequirementError(object)) return '';
  const { phones = false } = object;
  const separator = '\n';
  return !softRequirementError(phones) ? phones.join(separator) : '';
};

export const getEmailsString = (object) => {
  if (softRequirementError(object)) return '';
  const { emails = false } = object;
  const separator = '\n';
  return !softRequirementError(emails) ? emails.join(separator) : '';
};

export const getAddressString = (object) => {
  if (softRequirementError(object)) return '';
  const { address = false } = object;
  return !softRequirementError(address) ? getJoinObject(address) : '';
};

export const refundAliases = ['refundcash', 'refund', 'payrolldeductrefund'];
export const noChargeAlises = ['nocharge', 'no charge', '*NO CHARGE*'];
