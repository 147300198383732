import React, { Component } from 'react';
import ec from 'ec-react15-lib';

import AddressForm from './AddressForm';
import { TextInput } from '../controls';
import { Header, Label, Value } from '../controls/StyledDiv';
import * as variableType from '../../../services/VariableType';
import shallowEqualsObj from '../../../services/ShallowEqualsObj';
import { doBillingInfoValidation } from '../../../services/validations/form';


const { getStyling, getWritableValue, setValue, Checkbox } = ec;
const { isString, isEmptyString } = variableType;
const formStyles = {display: 'flex', flexWrap: 'wrap', alignItems: 'baseline', justifyContent: 'center' }


class ShippingInformationContainer extends Component {

  state = { validation: {}, isSameAsBillingAddress: true };

  componentWillReceiveProps(nextProps) {
    const { context } = nextProps;
    if (this.state.isSameAsBillingAddress) {
      const object = getWritableValue('g:object', context, {}) || {};
      const { customer = {}, shipping = {} } = object;
      const { shipmentResults, ...shippingInf } = shipping;
      if (!shallowEqualsObj(shippingInf, customer, shallowEqualsObj)) {
        const newObject = { ...object, shipping: { ...customer, shipmentResults } };
        setValue('g:object', newObject, context);
      }
    }
  }

  getValues = (field, value = '') => {
    const { context } = this.props;
    const line1 = getWritableValue('g:object.shipping.address.line1', context, '') || '';
    const city = getWritableValue('g:object.shipping.address.city', context, '') || '';
    const state = getWritableValue('g:object.shipping.address.state', context, '') || '';
    const zip = getWritableValue('g:object.shipping.address.zip', context, '') || '';
    const phones = getWritableValue('g:object.shipping.phones', context, '') || '';
    const emails = getWritableValue('g:object.shipping.emails', context, '') || '';
    const firstName = getWritableValue('g:object.shipping.firstName', context, '') || '';
    const lastName = getWritableValue('g:object.shipping.lastName', context, '') || '';
    const businessName = getWritableValue('g:object.shipping.businessName', context, '') || '';
    const customFields = getWritableValue('g:object.shipping.customFields', context, '') || '';
    const props = { line1, city, state, zip, phones, emails, firstName, lastName, businessName, customFields };
    return (isString(field) && !isEmptyString(field)) ? { ...props, [field]: value } : props;
  };

  onChangeCustomer = (field, value) => {
    const { context } = this.props;
    const object = getWritableValue('g:object', context, {}) || {};
    const { shipping = {} } = object;
    const newCustomer = { ...shipping, [field]: value };
    const newObject = { ...object, shipping: newCustomer };
    setValue('g:object', newObject, context);

    this.onCheckCustomer(field, value);
  };

  onChangeAddress = (field, value) => {
    const { context } = this.props;
    const object = getWritableValue('g:object', context, {}) || {};
    const { shipping: { address = {} } = {} } = object;
    const newAddress = { ...address, [field]: value };
    const newObject = { ...object, shipping: { ...object.shipping, address: newAddress } };
    setValue('g:object', newObject, context);
    this.onCheckCustomer(field, value);
  };

  onChangeState = (obj) => {
    const { value = '', country = '' } = obj;
    const { context } = this.props;
    const object = getWritableValue('g:object', context, {}) || {};
    const { shipping: { address = {} } = {} } = object;
    const newAddress = { ...address, state: value, country };
    const newObject = { ...object, shipping: { ...object.shipping, address: newAddress } };
    setValue('g:object', newObject, context);
  };

  onCheckCustomer = (field, value) => {
    const { context } = this.props;
    const { ...shipping } = this.getValues(field, value);

    const shippingValidation = doBillingInfoValidation(shipping);
    this.setState({ validation: shippingValidation });
    setValue('g:shippingValidation', shippingValidation, context);
  };


  onToggle = () => {
    const { context } = this.props;
    const value = !this.state.isSameAsBillingAddress;
    this.setState({
      isSameAsBillingAddress: value
    });
    if (value) {
      const object = getWritableValue('g:object', context, {}) || {};
      const { customer = {} } = object;
      const newObject = { ...object, shipping: { ...object.shipping, ...customer } };
      setValue('g:object', newObject, context);
    }
  };

  render() {
    const { index, props, context, pos, childIndex } = this.props;
    const sp = { props, context, pos, childIndex };
    const optional = ['container', 'position'];
    const { styles, classes } = getStyling({ ...sp, optional, styling: ['Block', 'Visibility'] });
    if (styles === false) return null;

    const { onChangeAddress, onChangeCustomer, onChangeState } = this;
    const { isSameAsBillingAddress } = this.state;
    const object = getWritableValue('g:object', context, {}) || {};
    const { customer = {}, shipping = {} } = object;

    const values = isSameAsBillingAddress ? { ...customer } : { ...shipping };
    const { validation = {} } = this.state;
    const { address = {}, firstName, lastName, businessName, phones, emails } = values;
    const { line1, city, state, zip } = address;

    return (
      <div key={index} className={classes.join(' ')} style={styles}>
        <Header>
          Shipping Information
        </Header>
        <div className='form-group'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Label />
            <Value>
              <Checkbox
                label='Is Same As Billing Address'
                value={isSameAsBillingAddress}
                onChange={this.onToggle}
              />
            </Value>
          </div>
        </div>
        {
          !isSameAsBillingAddress ? (
            <div>
              <div className='form-group'>
                <div style={formStyles }>
                  <Label>
                    First Name:&nbsp;
                  </Label>
                  <Value>
                    <TextInput
                      value={firstName}
                      placeholder='First Name'
                      onChange={value => onChangeCustomer('firstName', value)}
                    />
                  </Value>
                </div>
                {validation['firstName'] ? (
                  <div
                    style={{ textAlign: 'right' }}
                    className='small text-danger'
                  >
                    {validation['firstName']}
                  </div>
                ) : null}
              </div>
              <div className='form-group'>
                <div style={ formStyles }>
                  <Label>
                    Last Name:&nbsp;
                  </Label>
                  <Value>
                    <TextInput
                      value={lastName}
                      placeholder='Last Name'
                      onChange={value => onChangeCustomer('lastName', value)}
                    />
                  </Value>
                </div>
                {validation['lastName'] ? (
                  <div
                    style={{ textAlign: 'right' }}
                    className='small text-danger'
                  >
                    {validation['lastName']}
                  </div>
                ) : null}
              </div>
              <div className='form-group'>
                <div style={ formStyles }>
                  <Label>
                    Business Name:&nbsp;
                  </Label>
                  <Value>
                    <TextInput
                      value={businessName}
                      placeholder='Business Name'
                      onChange={value => onChangeCustomer('businessName', value)}
                    />
                  </Value>
                </div>
                {validation['businessName'] ? (
                  <div
                    style={{ textAlign: 'right' }}
                    className='small text-danger'
                  >
                    {validation['businessName']}
                  </div>
                ) : null}
              </div>
              <AddressForm
                {...{
                  validation, line1, city, state, zip, phones, emails,
                  onChangeAddress, onChangeCustomer, onChangeState
                }}
              />
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default ShippingInformationContainer;
