import { getTotalsRentalDays, isRental } from './RentalHelpers';
import { getTransactionProperties } from './TransactionProperties';
import { formatString } from './GeneralHelpers';
import { isArray, isEmptyArray, isObject, isEmptyObject, isValue } from './../services/VariableType';

export const checkItems = (items) => {
  if (items && items.length > 0) {
    return items.every(item => item.name && item.price > -1);
  }
  return false;
};

export const checkPayments = (items) => {
  if (items && items.length > 0) {
    const validItems = items.every(item => item.type || (item.transaction && item.transaction.length > 0));
    return validItems;
  }
  return false;
};

export const checkPaymentDetails = (payment) => {
  const { transactions = false } = payment || {};
  if (transactions && transactions.length) {
    const arrTypeProps = transactions.map(getTransactionProperties(transactions));
    return arrTypeProps.length;
  }
  return false;
};

export const fixedFloat2 = value => !isNaN(+parseFloat(value).toFixed(2)) ? parseFloat(value).toFixed(2) : '0.00';

export const getQTYCoef = item => isRental(item) ? getTotalsRentalDays(item) : item.quantity;

export const getCustomFields = ({ customFields }) => {
  if (isArray(customFields) && !isEmptyArray(customFields)) return customFields;
  if (isObject(customFields) && !isEmptyObject(customFields)) {
    return Object.keys(customFields).reduce((result, key) => {
      if (isValue(customFields[key])) {
        const obj = {};
        obj.field = customFields[key];
        obj.label = formatString(key);
        result.push(obj);
      }
      return result;
    }, []);
  }
};
