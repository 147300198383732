import { Component } from 'react';
import ec from 'ec-react15-lib';

const { triggerAction, getWritableValue } = ec;

class ButtonUseCoupon extends Component {
  componentWillReceiveProps(nextProps) {
    const { availableDiscount = {} } = nextProps.context.newOrder;
    const couponObject = getWritableValue('g:couponObject', nextProps.context);
    const prevCouponObject = getWritableValue('g:couponObject', this.props.context);
    const prevDiscount = (this.props.context.newOrder.availableDiscount) || {};
    if (JSON.stringify(availableDiscount) !== JSON.stringify(prevDiscount)) {
      if (availableDiscount.coupon) {
        triggerAction(nextProps.props.onSaveCoupon, nextProps.context);
      }
    }
    if (JSON.stringify(couponObject) !== JSON.stringify(prevCouponObject)) {
      triggerAction(nextProps.props.onSave, nextProps.context);
    }
  }

  render() {
    return null;
  }
}

export default ButtonUseCoupon;
