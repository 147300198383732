import React, { Component } from 'react';
import ec from 'ec-react15-lib';

import * as types from './../../../services/VariableType';
import { compareLocationValues } from '../../../services/GeneralHelpers';
import shallowEqualsObj from './../../../services/ShallowEqualsObj';

const { TextInput } = ec;
const { isObject, isEmptyObject, isArray, isEmptyArray, isEmptyString, isString, isValue } = types;

class DropdownLocation extends Component {
  input = null;

  state = {
    value: '',
    options: [],
    focusedIndex: 0,
    readOnly: false
  };

  componentWillMount() {
    const props = this.props;
    const { value, options, urlParam, onChange } = props;
    this.onInit(value, options, urlParam, onChange);
  }

  onInit = (selected, options, urlParam, onChange) => {
    const op = this.getOptions(options);
    if (isArray(op) && !isEmptyArray(op) && op.length > 1) {
      op.unshift({label: '- Please Select One -', value: ''})
    }
    const isValidSelected = op.find(o => o.value === selected);
    if (!isValidSelected || !selected || !isString(selected) || isEmptyString(selected)) {
      const newValue = urlParam && isString(urlParam) && !isEmptyString(urlParam)
        ? this.onFindLocationByPath(op, urlParam) : false;

      if (newValue && isObject(newValue) && !isEmptyObject(newValue)) {
        const forbidDepartmentNav = isValue(newValue.forbidDepartmentNav) ? newValue.forbidDepartmentNav : false;
        this.setState({ value: newValue.value, readOnly: forbidDepartmentNav, options: op });
        onChange(newValue);
      } else {
        const {onlineForm: {denyAutoselect = false} = {}} = this.props;
        const store = sessionStorage.getItem('store');
        const stationName = sessionStorage.getItem('station');
        const department = sessionStorage.getItem('department');


        const isValidStore = options.some(o => o.name === store);
        const isValidDepartment = options.some(o => o.department === department);
        const value = ([stationName, store, department].filter(v => v) || []).join(' - ');
        const item = op.find(o => o.value === value);
        if (!denyAutoselect && item && isValidStore && isValidDepartment &&
          isString(department) && !isEmptyString(department) && isString(store) && !isEmptyString(store)) {
          this.setState({ value, options: op });
          onChange(item);
        } else {
          this.setState({ value: op[0].value, options: op });
          onChange(op[0]);
        }
      }
    } else {
      this.setState({ value: selected, options: op });
    }
  };

  componentWillReceiveProps(nextProps) {
    const { value, options, urlParam, onChange } = nextProps;

    if (!shallowEqualsObj(value, this.props.value, shallowEqualsObj)) {
      this.setState({ value });
    }

    if (!shallowEqualsObj(options, this.props.options, shallowEqualsObj)) {
      this.onInit('', options, urlParam, onChange);
    }

    if (!shallowEqualsObj(urlParam, this.props.urlParam, shallowEqualsObj)) {
      this.onInit('', options, urlParam, onChange);
    }
  }

  onFindLocationByPath = (list, value) => {
    if (isArray(list) && !isEmptyArray(list)) {
      const objByCode = list.find(o => o.code === value);
      if (objByCode && isObject(objByCode) && !isEmptyObject(objByCode)) {
        return this.getValues(objByCode);
      }
      const objByStation = list.find(o => o.stationName === value);
      if (objByStation && isObject(objByStation) && !isEmptyObject(objByStation)) {
        return this.getValues(objByStation);
      }
      const objByStore = list.find(o => o.name === value);
      if (objByStore && isObject(objByStore) && !isEmptyObject(objByStore)) {
        return this.getValues(objByStore);
      }
    }
    return false;
  };

  getValues = (object) => {
    if (isObject(object) && !isEmptyObject(object)) {
      const { name = '', department = '', station } = object;
      const hasStation = station && isObject(station) && !isEmptyObject(station);
      const stationName = hasStation && isString(station.stationName) && !isEmptyString(station.stationName)
        ? station.stationName : '';
      const label = [stationName, name, department].filter(v => v).join(' - ');
      return { label, value: label, ...object, stationName, store: name, department };
    }
    return {};
  };

  getOptions = (op) => {
    if (isArray(op) && !isEmptyArray(op)) {
      const options = op.reduce((result, v) => {
        const values = this.getValues(v);
        if (isObject(values) && !isEmptyObject(values)) {
          result.push(values);
        }
        return result;
      }, []);
      return options.sort(compareLocationValues());
    }
    return [];
  };

  onChange = e => {
    const { options } = this.state;
    const value = e.target.value;
    const item = options.find(o => o.value === value);
    if (item && isObject(item) && !isEmptyObject(item)) {
      this.props.onChange(item);
    }
  };

  render() {
    if (!this.props.options) return null;
    const { value, options, readOnly } = this.state;
    return (
      <div style={{ width: options.length === 1 ? '100%' : 'auto' }}>
        {
          options.length > 1 ? (
            <select
              value={value}
              id={this.props.id}
              disabled={readOnly}
              onChange={this.onChange}
              className='form-control'
              style={this.props.style}
              ref={(input) => { this.input = input; }}
            >
              {options.map((item, index) => {
                const kIndex = index;
                return (
                  <option key={kIndex} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          ) : (
            <TextInput value={value} onChange={() => {}} readOnly={true} />
          )
        }
      </div>
    );
  }
}

export default DropdownLocation;
