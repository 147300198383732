import React, {Component} from 'react';
import ec from 'ec-react15-lib';

import TextInput from '../controls/TextInput';
import {Products} from '../../../services/ApiData';
import {onTriggerAction} from '../../../services/ContextHelpers';

const { Logger, getWritableValue, setValue, getStyling } = ec;

const SEARCH_INPUT_ID = 'searchInput';

class ProductsSearch extends Component {

  state = {
    isMouseDown: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress);
    document.addEventListener('mousedown', this.onMouseDown);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  componentDidUpdate() {
    this.onFocusElementHandler();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress);
    document.removeEventListener('mousedown', this.onMouseDown);
    document.removeEventListener('mouseup', this.onMouseUp);
    this.removeListener();
  }

  removeListener = () => document.removeEventListener('blur', this.onFocusElementHandler, true);

  onChange = (field, value) => {
    const {context} = this.props;
    const store = getWritableValue('g:ecOptions.merchantOptions.onlineForm.store', context, '') || '';
    if (value) {
      onTriggerAction(Products.search({store, q: value}), context)
        .then(() => {
          setValue('g:searchQuery', value, context);
        });
    } else {
      const department = getWritableValue('g:ecOptions.merchantOptions.onlineForm.department', context, '') || '';
      const store = getWritableValue('g:ecOptions.merchantOptions.onlineForm.store', context, '') || '';
      onTriggerAction(Products.fetchByCategory(department, store, context.globals.category), context)
        .then(() => {
          setValue('g:searchQuery', '', context);
        });
    }
  };

  onFocusElementHandler = () => {
    const { isMouseDown } =  this.state;
    const elem = document.getElementById(SEARCH_INPUT_ID);
    const activeElement = document.activeElement.tagName.toLowerCase();
    if (elem && !['a','area','input','object','select','textarea'].includes(activeElement) && !isMouseDown) {
      elem.focus();
    }
  };

  onListenerFormControls = el => {
    if (el === null) {
      this.removeListener();
    } else {
      const el = document.getElementById(SEARCH_INPUT_ID);
      el.focus();
      document.addEventListener('blur', this.onFocusElementHandler, true);
    }
  };


  render() {
    const {index, props, context, pos, section, childIndex} = this.props;
    Logger.of('render.html.CategoriesBrowse').info('section', section, 'index', index, 'props', props, 'pos', pos);
    const sp = {props, context, pos, childIndex};
    const {styles, classes} = getStyling({ ...sp, mandatory: [], optional: [], styling: ['Block', 'Visibility'] });
    if (styles === false) return false;
    const search = getWritableValue('g:searchQuery', this.props.context, '') || '';

    return (
      <div
        key={index} className={classes.join(' ')}
        style={{ ...styles, width: '100%'}}
        ref={this.onListenerFormControls}>
        <TextInput
          field='search'
          value={search}
          id={SEARCH_INPUT_ID}
          placeholder='Search'
          style={{width: '100%'}}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

export default ProductsSearch;
