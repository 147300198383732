import moment from 'moment';

import { toLocalShortDate } from './DateHelpers';
import { isValue, isString, isEmptyString } from './VariableType';
import { hardRequirementError, isNumberType } from './ValidatorHelpers';


export const getJoinObject = (object = {}, separator = ' ') => {
  const arr = Object.keys(object).reduce((prev, curr) => {
    if (object[curr]) prev.push(object[curr]);
    return prev;
  }, []);
  return arr.join(separator);
};

export const getLocalShortDateArr = (arr = []) => {
  return arr.reduce((prev, curr) => {
    if (moment(curr).isValid()) prev.push(toLocalShortDate(curr));
    return prev;
  }, []);
};

export const getJoinDates = (arr, separator = ', ') => {
  const res = getLocalShortDateArr(arr);
  return res.join(separator);
};

export const toFixedFloat2 = (value) => {
  if (isNumberType(value)) {
    return parseFloat(value).toFixed(2);
  }
  return value;
};

export const fixedTo2 = value => (!isNaN(+parseFloat(value).toFixed(2)) ? parseFloat(value).toFixed(2) : '0.00');

export const capitalizeTxt = (txt) => {
  if (!isString(txt) || isEmptyString(txt)) return '';
  return txt.charAt(0).toUpperCase() + txt.slice(1);
};

export const splitByUpperCase = (string) => {
  if (!isString(string) || isEmptyString(string)) return [];
  return string.match(/([A-Z]?[^A-Z]*)/g).slice(0, -1);
};

export const formatString = (str) => {
  if (!isString(str) || isEmptyString(str)) return '';
  const arr = splitByUpperCase(str);
  const joinedNewStr = arr.join(' ');
  return capitalizeTxt(joinedNewStr);
};

export const getJoinArr = (arr = [], field, separate) => {
  if (hardRequirementError(arr)) return '';
  return arr.reduce((prev, curr) => {
    if (!curr) return prev;
    const object = { field, curr: `${curr}\n` };
    const result = getJoinObject(object, separate);
    prev.push(result);
    return prev;
  }, []);
};

export const addSuffix = (arr = []) => (arr.length > 1 ? 's' : '');
export const isDigit = value => !isNaN(value) ? !!/\d/.test(value) : false;
export const pad2 = x => (isDigit(x) && x.toString().length === 1 && x < 10 ? '0' + x : x);

export const partition = (arr, fn) =>
  arr.reduce(
    (acc, val, i, arr) => {
      acc[fn(val, i, arr) ? 0 : 1].push(val);
      return acc;
    },
    [[], []]
  );

export const objectPartition = (obj, fn) => {
  const [keys1, keys2] = Object.keys(obj).reduce(
    (acc, key, i, arr) => {
      const val = obj[key];
      acc[fn(val, i, arr) ? 0 : 1].push(key);
      return acc;
    },
    [[], []]);
  const obj1 = keys1.reduce((res, key) => {
    return { ...res, [key]: obj[key] };
  }, {});
  const obj2 = keys2.reduce((res, key) => {
    return { ...res, [key]: obj[key] };
  }, {});
  return [obj1, obj2];
};

export const isAlphaNumeric = value => {
  const letterNumber = /^[0-9a-zA-Z]+$/;
  if (isValue(value) && (isString(value) || isDigit(value)) && !isEmptyString(value.toString())) {
    return (value.trim().match(letterNumber));
  }
};

export const compareLocationValues = (order = 'asc') => {
  return (a, b) => {
    const { stationName: stationA, store: storeA, department: depA } = a;
    const { stationName: stationB, store: storeB, department: depB } = b;

    const result = (v1, v2) => {
      const comparison = v1.localeCompare(v2);

      return (order === 'desc') ? (comparison * -1) : comparison;
    };

    const skipDep = !isString(depA) || isEmptyString(depA) || !isString(depB) || isEmptyString(depB);
    const skipStore = !isString(storeA) || isEmptyString(storeA) || !isString(storeB) || isEmptyString(storeB);
    const skipStat = !isString(stationA) || isEmptyString(stationA) || !isString(stationB) || isEmptyString(stationB);

    if (skipDep) return -1;
    if (depA === depB) {
      if (skipStore) return -1;
      if (storeA === storeB) {
        if (skipStat) return -1;
        return result(stationA, stationB);
      }
      return result(storeA, storeB);
    }
    return result(depA, depB);
  };
};

export const sortArrObjectsByKey = (arr, key, desc = false) => {
  const arrCopy = [...arr];
  if (arrCopy && arrCopy.length && key) {
    return arrCopy.sort((a, b) => {
      if (typeof a[key] === 'undefined' || typeof b[key] === 'undefined') return 0;
      const x = a[key].toString().toUpperCase();
      const y = b[key].toString().toUpperCase();
      const ascending = x === y ? 0 : +(x > y) || -1;
      const descending = x === y ? 0 : +(x < y) || -1;
      return !desc ? ascending : descending;
    });
  }
  return arrCopy;
};

export const toFixedNoRounding = (val, n) => {
  const value = val.toString();
  if (isString(value) && !isEmptyString(value)) {
    const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + n + '})?', 'g')
    const a = value.match(reg) ? value.match(reg)[0] : '0';
    const dot = a.indexOf('.');
    if (dot === -1) {
      return a + '.' + '0'.repeat(n);
    }
    const b = n - (a.length - dot) + 1;
    return b > 0 ? (a + '0'.repeat(b)) : a;
  }
  return '0' + '0'.repeat(n);
};

export const getSafeAmount = (value) => {
  if (isValue(value)) {
    const val = value.toString();
    const myStr = val.replace(/,/g,'');
    return toFixedNoRounding(myStr, 2);
  }
  return 0;
};

export const capitalizeFirstLetter = (txt) => {
  return txt.charAt(0).toUpperCase() + txt.slice(1);
};


export const onAmountInput = (value) => {
  if (value === '.') return '0.';
  if (value === ',') return '';
  const pure = value.replace(/[^\d.,]/g,'')
    .replace(/^0+/, '0')
    .replace(/(\.)+/g, '.')
    .replace(/(,)+/g, ',')
    .replace(/(,\.)+/g, ',')
    .replace(/(\.,)+/g, '.');
  if (pure.split('.').length > 2) {
    const [prefix, ...next] = pure.split('.');
    const pureNext = next.join();
    return [prefix, pureNext].join('.');
  }
  return pure;
};


export const toAdd = (...args) => {
  const result = args.reduce((res, op, index, arr) => {
    if (isDigit(op)) {
      res = +fixedTo2(parseFloat(res) + parseFloat(op));
    } else {
      console.log(`Can't Add op = ${op} index = ${index} arr = ${arr}`);
    }
    return res;
  }, 0);
  return +fixedTo2(result);
};

export const toSubtract = (a, ...args) => {
  const result = args.reduce((res, op, index, arr) => {
    if (isDigit(op)) {
      res = +fixedTo2(parseFloat(res) - parseFloat(op));
    } else {
      console.log(`Can't Subtract op = ${op} index = ${index} arr = ${arr}`);
    }
    return res;
  }, a);
  return +fixedTo2(result);
};

export const toMultiply = (...args) => {
  const result = args.reduce((res, op, index, arr) => {
    if (isDigit(op)) {
      res = +fixedTo2(parseFloat(res) * parseFloat(op));
    } else {
      console.log(`Can't Multiply op = ${op} index = ${index} arr = ${arr}`);
    }
    return res;
  }, 1);
  return +fixedTo2(result);
};

export const toDivide = (a, ...args) => {
  const result = args.reduce((res, op, index, arr) => {
    if (isDigit(op) && parseFloat(op) !== 0) {
      res = +fixedTo2(parseFloat(res) / parseFloat(op));
    } else {
      console.log(`Can't Divide op = ${op} index = ${index} arr = ${arr}`);
    }
    return res;
  }, a);
  return +fixedTo2(result);
};

export const isSameState = (customerAddress = {}, storeAddress = {}) => {
  const customerState = customerAddress.state || '';
  const storeState = storeAddress.state || '';
  if (!customerState || !storeState) return false;
  return customerState.toLowerCase() === storeState.toLowerCase();
};

export const isAlphaNumericOrDash = value => {
  const letterNumber = /^[0-9\-a-zA-Z]+$/;
  if (isValue(value) && (isString(value) || isDigit(value)) && !isEmptyString(value.toString())) {
    return (value.trim().match(letterNumber));
  }
};

export const getUniqueArr = arr => arr.filter((v, i, a) => a.indexOf(v) === i) || [];
