import { isValue } from './VariableType';

export const fields = ['amount', 'subTotal', 'minTotal', 'maxTotal', 'convenienceFeeDollar',
  'convenienceFeePercentage'];

export const getConvenienceFee = (amount, feeDollar, feePercentage) => {
  if (feePercentage) return (amount * feePercentage) / 100;
  if (feeDollar) return feeDollar;
  return 0;
};

export const useConvenienceFee = (total, minTotal = 0, maxTotal) => {
  if (!isValue(total) || total === '' || parseFloat(total) <= 0) return false;
  if (isValue(minTotal) && isValue(maxTotal) && +minTotal <= 0 && +maxTotal <= 0) {
    if (+minTotal === 0 && +maxTotal === 0) {
      return true
    }
    return (parseFloat(total) >= parseFloat(minTotal) && parseFloat(total) <= parseFloat(maxTotal));
  }
  if (isValue(minTotal) && +minTotal >= 0) {
    return (parseFloat(total) >= parseFloat(minTotal));
  }
  if (isValue(maxTotal) && +maxTotal > 0) {
    return (parseFloat(total) <= parseFloat(maxTotal));
  }
  return false;
};

