import * as types from './VariableType';
import {
  adjustAmountToIncrements,
  discountDocument, getCouponTotal,
  getDiscountedAmount, getIndividualDiscountTotal,
  canApplyDiscountToItem, canBeUsedRewards, checkCoupon,
  getInfinityPerStore, getOnHand, getRewards, getSafeQuantity,
  isAvailOnHand, isValidQty, sumArray, getDiscountTotal, getSubTotal
} from './CartHelpers';
import { fixedTo2, isSameState, toAdd } from './GeneralHelpers';

const { isObject, isEmptyObject, isValue, isArray, isEmptyArray } = types;

export const Cart = (state) => { // eslint-disable-line
  let coupon = state.coupon;
  let discount = state.discount;
  const payments = state.payment;
  let items = state.items.slice();
  let itemsPreview = state.itemsPreview.slice();
  const certificates = state.certificates.slice();
  const discountPreference = state.discountPreference;
  const specialDiscounts = state.specialDiscounts.slice();
  const discountsWithCoupons = state.discountsWithCoupons;
  const multiDiscountsAllowed = state.multiDiscountsAllowed;
  const volumeDiscountsAllowed = state.volumeDiscountsAllowed;
  const discountsWithoutCoupons = state.discountsWithoutCoupons;

  const getPaymentFee = () => {
    const arr = payments.map(item => item.fee);
    return sumArray(arr);
  };

  const getSurcharge = () => {
    const arr = payments.map(item => item.surcharge);
    return sumArray(arr);
  };

  const getSHFee = () => {
    const arr = payments.map(item => item.SHFee);
    return sumArray(arr);
  };

  const getTaxTotal = () => {
    let taxableAmount = 0;
    let total = 0;
    const { customer, store } = state;
    if (customer && store && isObject(customer) && !isEmptyObject(customer) &&
      isObject(store) && !isEmptyObject(store)) {
      const taxable = isSameState(customer.address, store.address);
      if (isValue(store.taxRate) && taxable) {
        items.forEach((item) => {
          if (item.taxable) {
            const price =
              item.discountedPrice
                ? parseFloat(item.discountedPrice).toFixed(2)
                : parseFloat(item.price);
            taxableAmount += price * parseInt(item.quantity, 0);
          }
        });

        const taxRate = parseFloat(store.taxRate) / 100;
        total = taxRate * taxableAmount;
      }
    }

    return total.toFixed(2);
  };

  const getTotals = () => {
    const obj = {};
    const {isTaxExempt = false} = state.customer || {};
    const {taxRate = false} = state.store || {};
    const {fixedCost = 0, ShipmentCharges: { TotalCharges:
      { MonetaryValue: shipmentCharge = 0 } = {}} = {}} = state.shipping || {};
    const subTotal = getSubTotal(items);

    const individualDiscountTotal = getIndividualDiscountTotal(items, volumeDiscountsAllowed);
    obj.discountTotal = individualDiscountTotal;

    if (isObject(coupon) && !isEmptyObject(coupon) && coupon.amount) {
      obj.couponTotal = getCouponTotal(items, coupon, subTotal, specialDiscounts);
    }

    const params = {
      discounts: discountsWithoutCoupons,
      orderSubTotal: subTotal,
      multiDiscountsAllowed,
      discountPreference,
      specialDiscounts,
      items
    };

    const docsDiscountTotal = getDiscountTotal({...params});

    obj.discountTotal = toAdd(docsDiscountTotal, individualDiscountTotal);
    obj.subTotal = subTotal;
    obj.fee = getPaymentFee();
    obj.surcharge = getSurcharge();
    if (+fixedCost > 0) {
      obj.shipmentCharge = fixedTo2(fixedCost);
    } else if (+shipmentCharge > 0) {
      obj.shipmentCharge = fixedTo2(shipmentCharge);
    }

    obj.SHFee = getSHFee();

    obj.taxTotal = !isTaxExempt ? getTaxTotal(items, taxRate) : 0;
    const val = field => (obj[field] ? parseFloat(obj[field]) : 0.00);

    const unsafeGrandTotal = (
      (val('subTotal') + val('taxTotal') + val('fee') + val('surcharge') + val('SHFee') + val('shipmentCharge')) -
      val('discountTotal') - val('couponTotal')
    ).toFixed(2);

    obj.grandTotal = Math.max(0, +unsafeGrandTotal).toFixed(2);
    return obj;
  };

  const checkPoints = () => {
    let availablePoints = {};
    const { points = 0 } = state.customer;
    const subTotal = getSubTotal(items);

    const list = isArray(discountsWithoutCoupons) && !isEmptyArray(discountsWithoutCoupons)
      ? discountsWithoutCoupons : [];
    list.forEach(discountObj => {
      const found = Object.keys(availablePoints).length;
      if (!found) {
        const doc = discountObj.doc;
        const isCustomerPoints = discountDocument.isCustomerPoints(doc);
        const validPoints = points && parseFloat(points);
        const { applyTo } = doc;
        if (applyTo && applyTo.all) {
          availablePoints = doc;
        } else {
          items.forEach(item => {
            const canApplyToItem = canApplyDiscountToItem(item, doc, subTotal);
            if (canApplyToItem && validPoints && isCustomerPoints) {
              availablePoints = doc;
            }
          });
        }
      }
    });
    return availablePoints;
  };

  const getRewardsDetails = () => {
    const { points = 0 } = state.customer;
    const pointsConditions = checkPoints();
    if (!points || !Object.keys(pointsConditions).length) return {};
    const totals = getTotals();
    const { grandTotal = 0 } = totals;
    const { rewardIncrements = 0 } = pointsConditions;
    const customerTotalRewards = getRewards(points, pointsConditions);
    const adjustSaleAmount = adjustAmountToIncrements(grandTotal, rewardIncrements);
    const rewards = Math.min(adjustSaleAmount, customerTotalRewards);
    const isValidRewards = canBeUsedRewards(rewards, pointsConditions);
    return { rewards, points, customerTotalRewards, isValidRewards };
  };

  const onAdd = (items, doc, qty = 1) => {
    const hash = window.performance.now().toString().split('.').join(''); // eslint-disable-line
    let updated = false;
    const store = state.store;
    const onHand = getOnHand(doc, store);
    const infinityPerStore = getInfinityPerStore(doc, store);

    items.forEach((item) => {
      const { selected = [], options = [], quantity, infinity, price } = item;
      const isAvail = infinityPerStore || isAvailOnHand(quantity, infinity, onHand, store);
      const safeQty = getSafeQuantity(isAvail, qty);
      const isEqualPrice = fixedTo2(price) === fixedTo2(doc.price);
      const conditions = [
        item.hash === hash,
        item.id === doc._id && !selected.length && !options.length,
        item.id === doc._id && JSON.stringify(selected) === JSON.stringify(options)
      ];
      const shouldItemUpdate = conditions.some(cond => cond) && isEqualPrice;
      if ((shouldItemUpdate && isAvail)) {
        item.quantity = fixedTo2(+item.quantity + +safeQty);
      }

      if (shouldItemUpdate) {
        updated = true;
      }
    });

    if (doc.category === 'Gift Certificates') {
      const certificate = certificates.find(item => item.certificateId === doc.hash) || {};
      if (isObject(certificate) && !isEmptyObject(certificate)) {
        certificate.quantity += 1;
      }
      else {
        certificates.push({
          quantity: 1,
          name: doc.name,
          cost: +doc.cost,
          price: +doc.price,
          balance: +doc.price,
          certificateId: hash
        });
      }
    }

    if (!updated) {
      const isAvailItem = infinityPerStore || isAvailOnHand(qty, doc.infinity, onHand, store);
      const safeQtyItem = getSafeQuantity(isAvailItem, qty);
      const newItem = {
        hash,
        onHand,
        sku: doc.sku,
        upc: doc.upc,
        cost: +doc.cost,
        name: doc.name,
        infinityPerStore,
        color: doc.color,
        image: doc.image,
        images: doc.images,
        id: doc._id || hash,
        taxable: doc.taxable,
        quantity: safeQtyItem,
        infinity: doc.infinity,
        weightValue: doc.weightValue,
        width: doc.width,
        height: doc.height,
        length: doc.length,
        category: doc.category,
        hasSerial: doc.hasSerial,
        isService: doc.isService,
        rental: doc.rental || '',
        price: fixedTo2(doc.price),
        productId: doc._id || hash,
        serials: doc.serials || [],
        description: doc.description,
        modifiers: doc.modifiers || [],
        subcategories: doc.subcategories,
        formatBarcode: doc.formatBarcode,
        rentalSerials: doc.rentalSerials,
        rentalsProduct: doc.rentalsProduct || [],
        volumeDiscounts: doc.volumeDiscounts || [],
        individualDiscount: doc.individualDiscount,
        selectedSerials: doc.selectedSerials || [],
        options: doc.options ? doc.options.slice() : [],
        selected: doc.options ? doc.options.slice() : []
      };
      items.push(newItem);
    }
    if (coupon.amount) checkCoupon(coupon, items);
    return obj;
  }

  const obj = {
    addIndividualDiscount: ({hash, amount}) => {
      const product = items.find(doc => (hash === doc.hash)) || {};
      if (isValue(amount) && parseFloat(amount) > 0) {
        product.individualDiscount = amount;
        product.discountedPrice = amount ? getDiscountedAmount(product.price, amount) : '';
      } else {
        delete product.individualDiscount;
        delete product.discountedPrice;
        delete product.appliedDiscounts;
      }
      return obj;
    },
    updateQty: (hash, qty) => {
      if (!hash) return obj;
      const product = [...items, ...itemsPreview].find(doc => (hash === doc.hash));
      const negativeInventory = isObject(state.store) && !isEmptyObject(state.store)
        ? state.store.negativeInventory || false
        : false;
      const getItemQty = q => isValidQty({ qty: q, negativeInventory, ...product, storeName: state.store.name })
        ? parseInt(q, 10)
        : product.onHand;
      const newQty = getItemQty(qty);
      if (newQty <= 0) {
        items = [...items, ...itemsPreview].filter(doc => (hash !== doc.hash));
      } else {
        product.quantity = newQty;
      }
      if (product.category === 'Gift Certificates') {
        const certificate = certificates.find(item => item.certificateId === hash);
        if (certificate && certificate.quantity) certificate.quantity = newQty;
        else {
          certificates.push({
            quantity: 1,
            cost: product.cost,
            name: product.name,
            price: product.price,
            balance: product.price,
            certificateId: hash
          });
        }
      }
      return obj;
    },
    updateQtyIncrease: (hash) => {
      if (!hash) return obj;
      const product = [...items, ...itemsPreview].find(doc => (hash === doc.hash));
      const negativeInventory = isObject(state.store) && !isEmptyObject(state.store)
        ? state.store.negativeInventory || false
        : false;
      const newQty = parseFloat(product.quantity) + 1
      product.quantity = isValidQty({ qty: newQty, negativeInventory, ...product, storeName: state.store.name })
        ? newQty
        : product.quantity;
      if (product.category === 'Gift Certificates') {
        const certificate = certificates.find(item => item.certificateId === hash);
        if (certificate && certificate.quantity) certificate.quantity = newQty;
        else {
          certificates.push({
            quantity: 1,
            cost: product.cost,
            name: product.name,
            price: product.price,
            balance: product.price,
            certificateId: hash
          });
        }
      }
      return obj;
    },
    updateQtyDecrease: (hash) => {
      if (!hash) return obj;
      const product = [...items, ...itemsPreview].find(doc => (hash === doc.hash));
      const newQty = parseFloat(product.quantity) - 1
      product.quantity = newQty > 0 ? newQty : product.quantity;
      if (product.category === 'Gift Certificates') {
        const certificate = certificates.find(item => item.certificateId === hash);
        if (certificate && certificate.quantity) certificate.quantity = newQty;
        else {
          certificates.push({
            quantity: 1,
            cost: product.cost,
            name: product.name,
            price: product.price,
            balance: product.price,
            certificateId: hash
          });
        }
      }
      return obj;
    },
    updateSelectedOptions: (hash, selected) => {
      const product = [...items, ...itemsPreview].find(item => (hash === item.hash)) || {};
      product.selected = isArray(selected) && !isEmptyArray(selected) ? [...selected] : [];
      return obj;
    },
    updateModifiers: (hash, selected) => {
      const product = [...items, ...itemsPreview].find(item => (hash === item.hash)) || {};
      const {modifiers = []} = product;
      const filtered = modifiers.filter(o => (o.category !== selected.category))
      product.modifiers =  [...filtered, selected];
      return obj;
    },
    updateSelectedSerials: (hash, serials) => {
      const product = [...items, ...itemsPreview].find(item => (hash === item.hash)) || {};
      product.selectedSerials = isArray(serials) && !isEmptyArray(serials) ? [...serials] : [];
      return obj;
    },
    addCoupon: (doc) => {
      if (!(isObject(doc) && !isEmptyObject(doc))) return obj;
      else {
        const existed = discountsWithCoupons.find(val => (val.id === doc.discount)) || {};
        const existedDoc = isObject(existed) && !isEmptyObject(existed) ? existed.doc : {}
        coupon = {...existedDoc, coupon: doc};
        checkCoupon(coupon, items);
      }
      return obj;
    },
    removeCoupon: () => {
      coupon = false;
      items.forEach((item) => {
        delete item.coupon;
        delete item.appliedDiscounts;
      });
      return obj;
    },
    addPreviewItem: (doc, qty = 1) => onAdd(itemsPreview, doc, qty),
    addItem: (doc, qty = 1) => onAdd(items, doc, qty),
    get: () => {
      // reject prev result
      items.forEach((item) => {
        delete item.discountedPrice;
        delete item.appliedDiscounts;
      });
      itemsPreview.forEach((item) => {
        delete item.discountedPrice;
        delete item.appliedDiscounts;
      });

      const totals = getTotals();
      return ({
        items,
        coupon,
        totals,
        discount,
        itemsPreview,
        certificates,
        store: state.store,
        customer: state.customer,
        shipping: state.shipping,
        pointsConditions: checkPoints(),
        rewardsDetail: getRewardsDetails()
      });
    }
  };

  return obj;
};
