import React, { Component } from 'react';
import ec from 'ec-react15-lib';

import { onTriggerAction } from '../../../services/ContextHelpers';
import * as variableType from '../../../services/VariableType';
import DropdownLocation from '../controls/DropdownLocation';
import { Label, Value } from '../controls/StyledDiv';
import { Products } from '../../../services/ApiData';
import TextInput from "../controls/TextInput";

const { getStyling, setValue, getWritableValue } = ec;
const { isArray, isEmptyArray, isString, isEmptyString, isEmptyObject, isObject } = variableType;

class LocationContainer extends Component {

  componentDidMount() {
    const { context } = this.props;
    setValue('g:isMandatoryLocation', true, context);
  }

  componentWillUnmount() {
    setValue('g:isMandatoryLocation', '');
  }

  onChange = (selected) => {
    const { context } = this.props;
    const merchantLogo = getWritableValue('g:ecOptions.merchantOptions.logo', context, '');
    const { department = '', store = '', stationName = '', logo = '' } = selected;
    sessionStorage.setItem('department', department);
    sessionStorage.setItem('store', store);
    sessionStorage.setItem('station', stationName);
    setValue('g:logo', logo || merchantLogo, context);
    setValue('g:department', department, context);
    setValue('g:store', store, context);
    setValue('g:station', stationName, context);
    setValue('g:errorMessage', '', context);
    if (department && store) {
      onTriggerAction(Products.fetch(department, store), this.props.context);
    }
  };

  render() {
    const { index, props, context, pos, childIndex } = this.props;
    const sp = { props, context, pos, childIndex };
    const optional = ['container', 'position'];
    const { styles, classes } = getStyling({ ...sp, optional, styling: ['Block', 'Visibility'] });

    if (styles === false) return null;

    const onlineForm = getWritableValue('g:ecOptions.merchantOptions.onlineForm', this.props.context, {}) || {};
    const user = getWritableValue('g:user', this.props.context, {}) || {};
    const department = getWritableValue('g:department', this.props.context, '') || '';
    const storeName = getWritableValue('g:store', this.props.context, '') || '';
    const urlParam = getWritableValue('g:urlParam', this.props.context, '') || '';
    const loadingLocation = getWritableValue('g:loadingLocation', this.props.context, false) || false;
    const station = getWritableValue('g:station', this.props.context, '') || '';
    const publicList = getWritableValue('docs:publicList', this.props.context, []) || [];
    const secureList = getWritableValue('docs:secureList', this.props.context, []) || [];
    const options = isArray(secureList) && !isEmptyArray(secureList) ? secureList : publicList;
    const { identities = {} } = user || {};
    const login = isObject(identities) && !isEmptyObject(identities) &&
      isString(identities.login) && !isEmptyString(identities.login) ? identities.login : '';
    const store = isArray(options) && !isEmptyArray(options) &&
      options.some(o => o.name === storeName) ? storeName : '';
    const value = ([station, store, department].filter(v => v) || []).join(' - ');

    const paymentType = getWritableValue('g:object.paymentType', this.props.context, {}) || {};
    const { type } = paymentType || {};

    const locationOptions = type === 'Recurring'
      ? options.filter(op => op.paymentEngine === 'usaepay' && !op.station) || []
      : options;

    return (
      <div key={index} className={classes.join(' ')} style={styles}>
        {
          isString(login) && !isEmptyString(login) ? (
            <div className='form-group' style={{ display: 'flex' }}>
              <Label>
                Placing transaction under&nbsp;
              </Label>
              <Value>
                <strong>{login}</strong>
              </Value>
            </div>
          ) : null
        }
        {
          isArray(locationOptions) && !isEmptyArray(locationOptions) ? (
            <div className='form-group' style={{ display: 'flex' }}>
              <Label>
                Location:
              </Label>
              <Value>
                {
                  loadingLocation ?
                    (<TextInput value='Please Wait, Loading...' disabled={true} onChange={() => {}}/>)
                    : (
                    <DropdownLocation
                      value={value}
                      urlParam={urlParam}
                      onlineForm={onlineForm}
                      onChange={this.onChange}
                      options={locationOptions}
                      containerClass='input-group-btn'
                    />
                  )
                }
              </Value>
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default LocationContainer;
