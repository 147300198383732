import React from 'react';
import ec from 'ec-react15-lib';

const { renderChildren, Logger, getWritableValue, getStyling, Checkbox, triggerAction } = ec;

const getItemOptions = (selected, option) => {
  let selectedArr = selected || [];
  if (selectedArr.indexOf(option) === -1) {
    selectedArr.push(option);
  } else {
    selectedArr = selected.filter(item => item !== option);
  }
  return selectedArr;
};

const ItemOptions = ({ section, index, props, context, pos, childIndex }) => {
  Logger.of('render.ItemOptions').info('section', section, 'index', index, 'props', props, 'pos=', pos);
  const sp = { props, context, pos, childIndex };
  const optional = [];
  const mandatory = ['options', 'selected', 'hash', 'container', 'onChange'];
  const { styles, classes } = getStyling({
    ...sp, mandatory, optional, styling: ['Block', 'Text', 'Visibility']
  });
  if (styles === false) return false;

  const hash = getWritableValue(props.hash, context, '');
  const options = getWritableValue(props.options, context, '');
  const selected = getWritableValue(props.selected, context, []);

  const onChangeValue = (option) => {
    const newSelectedArr = getItemOptions(selected, option);
    triggerAction(props.onChange, { ...context, value: [hash, newSelectedArr] });
  };
  return (<div className={classes.join(' ')} style={{ ...styles, display: 'flex', flexDirection: 'column' }} >
    {
      options && options.map(option => (
        <div key={`${hash}.${option}`} style={{ display: 'flex' }}>
          <Checkbox
            onChange={() => onChangeValue(option)}
            value={selected.indexOf(option) > -1}
          />
          {
            renderChildren({
              props: {},
              items: props.container,
              context: { ...context, option }
            })
          }
        </div>)
      )
    }
  </div>);
};

export default ItemOptions;
