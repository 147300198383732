import React from 'react';
import ec from 'ec-react15-lib';
import styled from 'styled-components';

import * as cardHelpers from '../../../../services/CreditCardHelpers';

const { TextInput, Dropdown } = ec;
const { Expiration } = cardHelpers;

const BlockLabel = styled.div`
  display: flex;
  flex: 1 0 160px;
  justify-content: flex-end;
  @media (max-width: 467px) {
    justify-content: flex-start;
    flex: 1 1 160px;
  }
`;

const BlockValue = styled.div`
  display: flex;
  flex: 1 0 220px;
  flex-direction: ${props => props.column ? 'column' : 'row'};
  @media (max-width: 467px) {
    flex: 1 1 220px;
  }
  #tsep-cardNum, #tsep-cvv2, #tsep-datepicker {
    border: none;
    outline: none;
  }
  .id-line-cvv {
      width: 100%;
  }
`;

const stylesList = {
  form: {
    color: '#fff',
    width: '100%',
    padding: '20px',
    maxWidth: '760px',
    borderRadius: '4px',
    background: 'rgb(64, 64, 64)'
  },
  form__row: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form__row_left: {
    justifyContent: 'flex-start'
  },
  form__row_right: {
    justifyContent: 'flex-end'
  },
  form__label: {
    fontSize: '1rem',
    paddingRight: '20px'
  },
  form__input: {
    height: '38px',
    fontSize: '1rem'
  },
  form__input_short: {
    width: '100px'
  },
  center: {
    textAlign: 'center'
  },
  form__input_mr15: {
    marginRight: '15px'
  },
  hidden: {
    visibility: 'hidden',
    height: 0,
    padding: 0
  }
};

const FormCreditCard = ({ children, cardNumber, expMonth, expYear, cardHolder, cardType, cvv, cardValidation,
                          onChangeNumber, onChangeCard, onClickAcceptCards, encryptedManifest = '' }) => (
  <div style={stylesList.form}>
    <div style={stylesList.form__row} className='form-group' >
      <BlockLabel>
        <span style={stylesList.form__label}>Credit Card Number:</span>
      </BlockLabel>
      <BlockValue column={true}>
        {
          encryptedManifest ? (
            <div id='tsep-cardNumDiv'/>
          ) : (
            <TextInput
              type='search'
              autoComplete='off'
              id='cardNumber'
              maxLength={19}
              name='cardNumber'
              value={cardNumber}
              onChange={onChangeNumber}
              placeholder='.... .... .... ....'
              style={{ ...stylesList.form__input, ...stylesList.center }}
            />
          )
        }
      </BlockValue>
    </div>
    <div style={stylesList.form__row} className='form-group' >
      <BlockLabel>
        <span style={stylesList.form__label}>Card Holder:</span>
      </BlockLabel>
      <BlockValue column={true}>
        <TextInput
          id='cardHolder'
          name='cardHolder'
          type='search'
          autoComplete='off'
          value={cardHolder}
          placeholder='Card Holder'
          style={stylesList.form__input}
          onChange={value => onChangeCard('cardHolder', value)}
        />
      </BlockValue>
    </div>
    <div style={stylesList.form__row} className='form-group' >
      <BlockLabel>
        <span style={stylesList.form__label}>CVV2:</span>
      </BlockLabel>
      <BlockValue>
        {
          encryptedManifest ? (
            <div id='tsep-cvv2Div' />
          ) : (
            <TextInput
              value={cvv}
              maxLength={4}
              placeholder='CVV'
              name='cvv'
              id='cvv'
              onChange={value => onChangeCard('cvv', value)}
              style={{ ...stylesList.form__input, ...stylesList.form__input_short }}
            />
          )
        }
        { children }
      </BlockValue>
    </div>
    <div style={stylesList.form__row} className={cardType ? 'form-group' : ''} >
      <BlockLabel>
        <span style={stylesList.form__label}>Expires:</span>
      </BlockLabel>
      <BlockValue>
        {
          encryptedManifest ? (
            <div id='tsep-datepickerDiv'/>
          ) : null}
        {!encryptedManifest ? (
          <div style={{ ...stylesList.form__row, ...stylesList.form__row_left }}>
            <Dropdown
              id='month'
              name='month'
              value={expMonth}
              options={Expiration.MONTHS}
              style={{ ...stylesList.form__input, ...stylesList.form__input_short, ...stylesList.form__input_mr15 }}
              onChange={value => onChangeCard('expMonth', value)}
            />
            <Dropdown
              id='year'
              name='year'
              value={expYear}
              options={Expiration.YEARS}
              style={{ ...stylesList.form__input, ...stylesList.form__input_short }}
              onChange={value => onChangeCard('expYear', value)}
            />
          </div>
        ) : null
        }
      </BlockValue>
    </div>
  </div>
);

export default FormCreditCard;
