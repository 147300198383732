import React, {Component} from 'react';
import styled from 'styled-components';
import ec from 'ec-react15-lib';

import {getMoneyString} from '../../../../services/PaymentTypeHelpers';

const {Debounced} = ec;

const Title = styled.div`
  border-radius: 10px 10px 0 0;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  padding: 5px;
  float: left;
  width: 100%;
  padding-right: 10px;
  margin-left: 5px;
  @media (max-width: 600px) {
    margin-top: 3px;
    font-size: 9px;  
  }  
`;

const Price = styled.div`
  border-radius: 10px;
  text-align: right;
  font-weight: bold;
  margin-right: 10px; 
  padding-top: 5px;
  padding-bottom: 5px;
  position: abosolute;
  bottom: 5px;
  @media (max-width: 600px) {
   font-size: 10px;   
  }
`;

const Image = styled.div`
  background: ${props => !props.image && !props.color
  ? `url('https://s3.amazonaws.com/indywebhost/media/2022/0220405060617/noImage.jpeg')`
  : `url('${props.image}') ${props.color || '#fff'}`};

  background-repeat: no-repeat;
  border-radius: 10px;
  background-position: center;
  background-size: contain;
  width: 100%;
  min-height: 250px;
  max-height: 250px;
  border: 1px solid #e1e1e1;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 600px) {
    width:125px;
    min-height: 125px;
    max-height:125px; 
  }
`;


const Block = styled.div`
  margin: 20px;
  cursor: pointer;
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

// Fix for jumping arrows
.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 210px;
}

.carousel {
  position: relative;
  margin: 20px;
  button {
    outline: none;
  }
}

.carousel-inner {
  &:hover {
    .carousel_control {
      background-color: rgba(0,0,0,0.1);
    }
  }
}

.carousel-inner {
  width: 250px; 
  cursor: pointer;
  border-radius: 20px;
  color: black;
  transition: left 2s ease-in;
  &:hover {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    background: black;
    color:white;
    transition: left 2s ease-in;
  }
  @media (max-width: 600px) {
    width:125px;
    min-height:180px;
  }
}

// Carousel slides
.carousel__slide {
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  display: none;
  max-width: 900px;
  list-style-type: none;
  text-align: center;
  
  &--active {
    display: block;
  }
  
}

// Carousel indicators
.carousel__indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  li {
    &:nth-of-type(n + 2) {
      margin-left: 9px;
    }
  }
}

.carousel__indicator {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #111;
  cursor: pointer;
  opacity: .15;
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);

  &:hover {
    opacity: .5;
  }

  &--active {
    &,
    &:hover {
      opacity: .75;
    }
  }
}

.carousel_control {
    background-color: transparent;
    position: absolute;
    z-index: 2;
    top: 0;
    height: 283px;
    display: block;
    border: 0;
    padding: 0 5px;
    overflow: hidden;
    transition: background-color 300ms ease;
    cursor: pointer;
    
    span {
      display: inline-block;
      width: 2vw;
      height: 2vw;
      position: relative;
      box-sizing: border-box;
      opacity: 0.65;
      border-bottom: 3px solid white;
      border-left: 3px solid white;
      transition: opacity 300ms ease;
    }
    
    &:hover {
      span {
        opacity: 0.95;
      }
    }
  
    &__prev {
      left: 0;
      border-radius: 10px 0 0 10px;
      padding-left: 12px;
        span {
          transform: rotate(45deg);
        }
    }
    
    &__next {
      right: 0;
      padding-right: 12px;
      border-radius: 0 10px 10px 0;
      border-radius: 0 10px 10px 0;
      span {
         transform: rotate(-135deg);
      }
    }
  }
}
`;

class CarouselIndicator extends Component {
  render() {
    return (
      <li>
        <button
          className={
            this.props.index === this.props.activeIndex
              ? "carousel__indicator carousel__indicator--active"
              : "carousel__indicator"
          }
          onClick={this.props.onClick}
        />
      </li>
    );
  }
}

class CarouselLeftArrow extends Component {
  render() {
    return (
      <button
        className='carousel_control carousel_control__prev'
        onClick={this.props.onClick}
        style={this.props.style}
      >
        <span/>
      </button>
    );
  }
}

class CarouselRightArrow extends Component {
  render() {
    return (
      <button
        className='carousel_control carousel_control__next'
        onClick={this.props.onClick}
        style={this.props.style}
      >
        <span/>
      </button>
    );
  }
}

class CarouselSlide extends Component {

  render() {
    const {object: {color, name, price} = {}, onClickItem = () => {}, styles = {}} = this.props;
    const imgOriginal = this.props.slide
      ? (this.props.slide.original || this.props.slide)
      : 'https://s3.amazonaws.com/indywebhost/media/2022/0220405060617/noImage.jpeg';

    const imgRoot = process.env.REACT_APP_IMG_ROOT || '';
    const url = imgOriginal.replace('https://s3.amazonaws.com/', `${imgRoot}/img/x250/`);
    return (
      <li
        className={
          this.props.index === this.props.activeIndex
            ? 'carousel__slide carousel__slide--active'
            : 'carousel__slide'
        }
        onClick={onClickItem}
      >
        <Image image={url} color={color} style={styles} />
        <div>
          {name ? (
            <Title>
              {name.toUpperCase()}
            </Title>
          ) : null}
          {price ? (
            <Price>
              {getMoneyString(price)}
            </Price>
          ) : null}
        </div>
      </li>
    );
  }
}

// Carousel wrapper component
class Carousel extends Component {

  state = {
    activeIndex: 0
  };

  onImgChanges = () => {
    Debounced.start('onImgChanges', () => {
      const count = this.state.activeIndex;
      this.setState({
        activeIndex: count + 1 >= this.props.slides.length ? 0 : count + 1
      });
    }, 1000)
  };

  goToSlide = (index) => {
    this.setState({
      activeIndex: index
    });
  };

  goToPrevSlide = (e) => {
    e.preventDefault();

    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length;

    if (index < 1) {
      this.setState({
        activeIndex: slidesLength - 1
      });
    } else {
      this.setState({
        activeIndex: index - 1
      })
    }
  }

  goToNextSlide = (e) => {
    e.preventDefault();

    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length - 1;

    if (index === slidesLength) {
      this.setState({
        activeIndex: 0
      });
    } else {
      this.setState({
        activeIndex: index + 1
      })
    }
  };

  render() {
    return (
      <Block style={this.props.styleBlock}>
        <div className='carousel' style={this.props.styleBlock}>
          <div className='carousel-inner' style={this.props.carouselInnerStyle || {}}>
            {
              this.props.slides.length && this.props.slides.length > 1
                ? (<CarouselLeftArrow style={this.props.carouselArrowStyle} onClick={e => this.goToPrevSlide(e)} />)
                : null
            }
            <ul className='carousel__slides' onMouseOver={this.onImgChanges}>
              {this.props.slides.map((slide, index) =>
                <CarouselSlide
                  key={index}
                  index={index}
                  slide={slide}
                  styles={this.props.style}
                  object={this.props.object}
                  slides={this.props.slides}
                  activeIndex={this.state.activeIndex}
                  onClickItem={this.props.onClickItem}
                />
              )}
            </ul>
            {
              this.props.slides.length && this.props.slides.length > 1
                ? (<CarouselRightArrow style={this.props.carouselArrowStyle}  onClick={e => this.goToNextSlide(e)} />)
                : null
            }
          </div>
          <ul className='carousel__indicators'>
            {this.props.slides.length && this.props.slides.length > 1
              ? this.props.slides.map((slide, index) =>
                <CarouselIndicator
                  key={index}
                  index={index}
                  activeIndex={this.state.activeIndex}
                  isActive={this.state.activeIndex === index}
                  onClick={e => this.goToSlide(index)}
                />
              )
              : null}
          </ul>
        </div>
      </Block>
    );
  }
}

export default Carousel;
