import { getMaxRefund, getTransactionProperties } from '../TransactionProperties';
import { generateTableElement, getTextElement, messureText } from './PdfElements';
import { getStyles, refundAliases } from '../PaymentTypeHelpers';

export const getElement = (payment, isTplLarge, styles) => {
  const { transactions = false, type } = payment;
  if (type && refundAliases.includes(type.toLowerCase())) return null;
  if (transactions && transactions.length) {
    const arrTypeProps = transactions.map(getTransactionProperties(transactions));
    const containVoid = arrTypeProps && arrTypeProps.some(prop => prop.some(t => t.value === 'Void'));

    const maxRefund = getMaxRefund(payment);
    const canceled = containVoid || maxRefund === '0.00';

    const stylesCanceled = { decoration: 'lineThrough', ...styles };
    const stylesLabels = getStyles(styles, isTplLarge).transactionPaymentType.label;
    const stylesValues = getStyles(styles, isTplLarge).transactionPaymentType.value;

    const params = {
      headers: {},
      values: {},
      ...getStyles(styles, isTplLarge).transactionPaymentType.table
    };

    const listTransaction = arrTypeProps.reduce((prev, curr) => {
      return curr.reduce((commonArr, transaction) => {
        const { title = '', value } = transaction;
        const isThrough = canceled && value === 'Sale';
        const style = isThrough ? stylesCanceled : styles;
        if (value && value !== 'undefined undefined') {
          commonArr.push({ title, value, style });
        }
        return commonArr;
      }, prev);
    }, []);

    const paramsTable = listTransaction.reduce((res, curr, index) => {
      const { title, value, style } = curr;
      const labelStyle = { ...stylesLabels, style };
      const { fontSize, maxWidth } = labelStyle;
      const t = getTextElement(title, labelStyle);
      const labelWidth = messureText(t, fontSize, maxWidth);
      const valueWidth = maxWidth - labelWidth;
      const v = getTextElement(value, { ...stylesValues, style, ...{ maxWidth: valueWidth } });

      res.headers = { ...res.headers, [index]: t };
      res.values = { ...res.values, [index]: v };
      return res;
    }, params);

    return generateTableElement(paramsTable);
  }
  return '';
};
