import React, {Component} from 'react';
import ec from 'ec-react15-lib';

import ModalPanel from '../controls/ModalPanel';
import Carousel from '../controls/Carousel/index';

const { getValue, Logger, getStyling, getWritableValue } = ec;

class ImageDelimit extends Component {

  state = {
    isOpenIndex: -1
  };

  render () {
    const { section, index, props, context, pos, childIndex } = this.props;
    Logger.of('render.html.Image').info('section', section, 'index', index, 'props', props, 'pos', pos);
    const sp = { props, context, pos, childIndex };
    const { styles, classes } = getStyling({
      ...sp, mandatory: ['src'], optional: ['title', 'dimensions', 'srcSet', 'srcAlt'],
      styling: ['Inline', 'Visibility']
    });
    if (styles === false) return false;
    const {isOpenIndex = -1} = this.state;
    const {row: {images = []} = {}} = context;
    const src = getValue(props, 'src', context);
    const srcAlt = getValue(props, 'srcAlt', context);
    const dimensions = getValue(props, 'dimensions', context);
    const pathname = getWritableValue('g:pathname', context, '') || '';

    if (typeof src === 'undefined') {
      Logger.of('render.html.Image').info('Image is missing src property, props=', props, 'context=', context);
      return false;
    }

    const onError = (e) => {
      if (typeof srcAlt !== 'undefined') {
        e.target.src = srcAlt;
      }
    };

    const imgRoot = process.env.REACT_APP_IMG_ROOT || '';
    const list = [src, ...(pathname !== '/cart' ? images.map(o => o.original) : [])];

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          list && list.length ? list.map((o, i) => {
            const srcDelimited = o.replace('https://s3.amazonaws.com/',
             dimensions ? `${imgRoot}/img/${dimensions}/` : `${imgRoot}/f/`);
            return (
              <img
                key={i}
                src={srcDelimited}
                onError={onError}
                sizes={props.sizes}
                srcSet={props.srcset}
                className={classes.join(' ')}
                style={{...styles, margin: '5px', cursor: 'pointer'}}
                alt={getValue(props, 'title', context)}
                onClick={_ => this.setState({ isOpenIndex: i })}
              />
            )
          }) : null
        }
        {
          isOpenIndex > -1 ? (
            <ModalPanel
              style={{ margin: 0, padding: 0 }}
              outerStyle={{ margin: 0, padding: 0, marginTop: '90px' }}
              innerStyle={{ boxShadow: 'none', background: 'transparent', width: 'auto', margin: 0, padding: 0 }}
              onClick={_ => this.setState({ isOpenIndex: -1 })}
            >
              <Carousel
                object={{}}
                slides={list}
                data-aos='zoom-out'
                onClickItem={() => {}}
                carouselArrowStyle={{height: '100%'}}
                style={{maxHeight: '500px', minHeight: '500px'}}
                styleBlock={{margin: 0, padding: 0}}
                carouselInnerStyle={{width: '500px', margin: '0 auto'}}
              />
            </ModalPanel>
          ) : null
        }
      </div>
    )
  }
}

export default ImageDelimit;
