// 300 dpi - for a word processing document
// 72 dpi - basic units of scale between print and monitor
const DPI = 72;

const getUnitKoef = (unit) => {
  let k = 1;
  switch (unit) {
    case 'pt': k = 1; break;
    case 'mm': k = DPI / 25.4; break;
    case 'cm': k = DPI / 2.54; break;
    case 'Inches':
    case 'in': k = DPI; break;
    case 'px': k = 96 / DPI; break; // 96 - pixels measure one inch
    case 'pc': k = 12; break;
    case 'em': k = 12; break;
    case 'ex': k = 6; break;
    default:
      console.log('Invalid unit: ' + unit);
  }
  return k;
};

export const getElement = ({ data, size = '40', unit = 'mm', isTplLarge }) => {
  if (data) {
    const k = getUnitKoef(unit);

    return { qr: data, fit: size * k, alignment: isTplLarge ? 'right' : 'center' };
  }
  return false;
};

