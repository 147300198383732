import React from 'react';
import ec from 'ec-react15-lib';
import styled from 'styled-components';

import {Header} from '../../controls/StyledDiv';

const { TextInput } = ec;

const BlockLabel = styled.div`
  display: flex;
  flex: 1 0 160px;
  justify-content: flex-end;
  @media (max-width: 467px) {
    justify-content: flex-start;
    flex: 1 1 160px;
  }
`;

const BlockValue = styled.div`
  display: flex;
  flex: 1 0 220px;
  flex-direction: ${props => props.column ? 'column' : 'row'};
  @media (max-width: 467px) {
    flex: 1 1 220px;
  }
  #tsep-cardNum, #tsep-cvv2, #tsep-datepicker {
    border: none;
    outline: none;
  }
  .id-line-cvv {
      width: 100%;
  }
`;

const FormACH = ({ children, accountType, routingNumber, accountNumber, firstName, lastName, onChange }) => (
  <div style={stylesList.form}>
    <Header>
      ACH Payment
    </Header>
    <div style={stylesList.form__row} className='form-group' >
      <BlockLabel>
        <span style={stylesList.form__label}>Account #:</span>
      </BlockLabel>
      <BlockValue column={true}>
        <TextInput
          id='accountNumber'
          maxLength={19}
          name='accountNumber'
          value={accountNumber}
          placeholder='Account #'
          style={stylesList.form__input}
          onChange={value => onChange('accountNumber', value)}
        />
      </BlockValue>
    </div>
    <div style={stylesList.form__row} className='form-group' >
      <BlockLabel>
        <span style={stylesList.form__label}>Routing #:</span>
      </BlockLabel>
      <BlockValue column={true}>
        <TextInput
          name='routingNumber'
          value={routingNumber}
          placeholder='Routing #'
          style={stylesList.form__input}
          onChange={value => onChange('routingNumber', value)}
        />
      </BlockValue>
    </div>
    <div style={stylesList.form__row} className='form-group' >
      <BlockLabel>
        <span style={stylesList.form__label}>First Name:</span>
      </BlockLabel>
      <BlockValue column={true}>
        <TextInput
          id='firstName'
          maxLength={19}
          name='firstName'
          value={accountNumber}
          placeholder='First Name'
          style={stylesList.form__input}
          onChange={value => onChange('firstName', value)}
        />
      </BlockValue>
    </div>
    <div style={stylesList.form__row} className='form-group' >
      <BlockLabel>
        <span style={stylesList.form__label}>Last Name:</span>
      </BlockLabel>
      <BlockValue column={true}>
        <TextInput
          id='lastName'
          maxLength={19}
          name='lastName'
          value={accountNumber}
          placeholder='Last Name'
          style={stylesList.form__input}
          onChange={value => onChange('lastName', value)}
        />
      </BlockValue>
    </div>
  </div>
);

const stylesList = {
  form: {
    color: '#fff',
    width: '100%',
    padding: '20px',
    margin: '0 auto',
    maxWidth: '760px',
    borderRadius: '4px',
    background: 'rgb(64, 64, 64)'
  },
  form__row: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form__row_left: {
    justifyContent: 'flex-start'
  },
  form__row_right: {
    justifyContent: 'flex-end'
  },
  form__label: {
    fontSize: '1rem',
    paddingRight: '20px'
  },
  form__input: {
    height: '38px',
    fontSize: '1rem'
  },
  form__input_short: {
    width: '100px'
  },
  center: {
    textAlign: 'center'
  },
  form__input_mr15: {
    marginRight: '15px'
  },
  hidden: {
    visibility: 'hidden',
    height: 0,
    padding: 0
  }
};

export default FormACH;
