import * as proc from '../../config/processors';
import * as variableTypes from '../../services/VariableType';
import {isAlphaNumericOrDash, isDigit, getUniqueArr} from '../GeneralHelpers';
import {getPoslinkParamsByCommType} from '../processorService/PoslinkHelpers';

const { isArray, isEmptyArray, isEmptyObject, isEmptyString, isObject, isString, isValue } = variableTypes;

const missing = {
  station: 'Missing Station',
  paymentEngine: 'Missing Station Payment Engine',
  invalidPaymentEngine: 'Please check Payment Engine'
};


export const checkStation = (station) => {
  if (!isObject(station) || isEmptyObject(station)) return missing.station;
  const { stationPaymentEngine: type } = station || {};
  const processParams = type === 'poslink'
    ? getPoslinkParamsByCommType(station)
    : proc.stationProperties[type];

  if (!isValue(proc.stationProperties[type])) {
    return missing.invalidPaymentEngine;
  }
  if (!isString(type) || isEmptyString(type) || type === 'no_department_payment_engine') {
    return missing.paymentEngine;
  }

  if (isArray(processParams) && isEmptyArray(processParams)) return '';

  const alphaNumericList = processParams.filter(i => isValue(i.isAlphaNumeric) ? i.isAlphaNumeric : true) || [];
  const requiredList = processParams.filter(i => i.required) || [];
  const optionList = processParams.filter(i => i.option) || [];
  const hint = { ...proc.stationHint };
  const { stationName } = station;

  const missingProps = [];

  const invalidName = !isString(stationName) || isEmptyString(stationName);
  if (invalidName) {
    missingProps.push('Station Name');
  }

  const missingRequiredProps = requiredList.reduce((res, curr) => {
    if (!isValue(!station[curr.name]) || !station[curr.name]) {
      res.push(hint[curr.name] || curr.label || curr.name)
    }
    return res;
  }, []);

  const shouldBeAlphaNumeric = alphaNumericList.reduce((res, curr) => {
    if (isValue(!station[curr.name]) && !isAlphaNumericOrDash(station[curr.name])) {
      res.push(hint[curr.name] || curr.label || curr.name)
    }
    return res;
  }, []);

  missingProps.push(missingRequiredProps);

  const hasOptionProps = optionList.some(obj => station[obj.name]);
  if (!hasOptionProps) {
    const missingOptionProps = optionList.reduce((res, curr) => {
      if (!isValue(station[curr.name]) || !station[curr.name]) {
        res.push(hint[curr.name] || curr.label || curr.name)
      }
      return res;
    }, []);
    missingProps.push(missingOptionProps.join(' or '));
  }
  const messages = [].concat(...missingProps, ...shouldBeAlphaNumeric).filter(v => v) || [];
  const uniqueMess = getUniqueArr(messages);

  if (isArray(uniqueMess) && !isEmptyArray(uniqueMess))
    return (`Missing station ${!invalidName ? stationName : ''} values: ${uniqueMess.join(', ')}`)

  const badNames = ["station", "default", "main", "test", "-", '.'];
  const reg = /\w+/g
  if (badNames.indexOf(stationName.toLowerCase()) !== -1 || isDigit(stationName) || !stationName.match(reg)) {
    return "Is it a good name for the station? " +
      "Please identify your EMV device better. Good keywords could be 'Desk' or 'Checkout'";
  }
  if (stationName.match(/&/g) || stationName.match(/\\/g) || stationName.match(/\//g) || stationName.match(/%/g)) {
    return "Please avoid '&', '/', '%'.";
  }
  if (stationName.indexOf(" ") !== -1) {
    return "Please avoid spaces in station name. Try dashes instead";
  }
  return '';
};


export default checkStation;
