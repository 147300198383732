import { generateTableElement, getObjectOfTextElements } from './PdfElements';
import { getStyles } from '../PaymentTypeHelpers';

export const getElement = ({ labels = {}, values = {}, isTplLarge, styles = {} }) => {
  const stylesCommon = getStyles(styles, isTplLarge).header.label;
  const stylesLabels = Object.keys(labels).reduce((prev, curr) => ({ ...prev, [curr]: stylesCommon }), {});
  const stylesValue = getStyles(styles, isTplLarge).header.value;
  const stylesValues = Object.keys(values).reduce((prev, curr) => ({ ...prev, [curr]: stylesValue }), {});

  const objectValues = getObjectOfTextElements(values, stylesValues);
  const objectLabels = getObjectOfTextElements(labels, stylesLabels);

  const params = {
    headers: objectLabels,
    values: objectValues,
    ...getStyles(styles, isTplLarge).header.table
  };

  return generateTableElement(params);
};
